import * as yup from "yup";
import { ValidationError } from "yup";
import { TFunction } from "i18next";
import { AnyObject } from "yup/es/types";
import {ProjectsApiFactory} from "../api/api-types";
import { Assign, ObjectShape, TypeOfShape } from "yup/es/object";
import { RequiredStringSchema } from "yup/es/string";

function getTranslateKey(error: string) {
  let tKey = "";
  for (let i = 0; i < error.length; i++) {
    if (i === 0) {
      tKey += error[i].toLowerCase();
      continue;
    }
    if (error[i] !== error[i].toUpperCase()) {
      tKey += error[i];
      continue;
    }

    tKey += "-";
    tKey += error[i].toLowerCase();
  }
  return tKey;
}

export const createProjectSchema = (t: TFunction) => {
  async function checkIfProjectExists({ validatedProject }: { validatedProject: Project; }): Promise<true | yup.ValidationError> {
    let errors: string[] = [];
    if (validatedProject.title && validatedProject.companyId) {
      errors = await ProjectsApiFactory()
        .checkIfProjectExists(validatedProject.title, validatedProject.companyId, validatedProject.id as string)
        .then(res => res.data);
    }

    if (errors.length > 0) {
      const validationErrors: ValidationError[] = [];
      errors.forEach(error => {
        validationErrors.push(new ValidationError(t(`project-${getTranslateKey(error)}-exists`),
          "",
          error.charAt(0).toLowerCase() + error.slice(1)));
      });
      return new ValidationError(validationErrors);
    }
    return true;
  }

  return yup.object()
    .shape({
      id: yup.string(),
      title: yup.string().required(t("required")).nullable(),
      companyId: yup.string().required(t("required")).nullable(),
    })
    .test(async (project) => {
      return await checkIfProjectExists({ validatedProject: project });
    });
};

export interface NewProjectValidation {
  title: string;
  companyId: string;
}

export const newUserValidationErrorMessages: NewProjectValidation = {
  title: "",
  companyId: "",
};

export interface ProjectValidation {
  id?: string;
  title?: string;
  companyId?: string;
}

export const createProjectValidationErrorMessages: ProjectValidation = {
  id: "",
  title: "",
  companyId: "",
};

type Project = TypeOfShape<Assign<ObjectShape, {
  id: RequiredStringSchema<string | null | undefined, AnyObject>,
  title: RequiredStringSchema<string | null | undefined, AnyObject>,
  companyId: RequiredStringSchema<string | null | undefined, AnyObject>
}>>
