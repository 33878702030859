import * as yup from "yup";
import { ValidationError } from "yup";
import { TFunction } from "i18next";
import { AnyObject } from "yup/es/types";
import { UsersApiFactory } from "../api/api-types";
import { Assign, ObjectShape, TypeOfShape } from "yup/es/object";
import { RequiredStringSchema } from "yup/es/string";

const controlNumbers = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
const correctPeselLength = 11;
const constValue = 10;

export const createUserSchema = (t: TFunction) => {
  return yup.object().shape({
    firstName: yup.string().required(t("required")),
    lastName: yup.string().required(t("required")),
    email: yup.string().email(t("invalid-email")).required(t("required")),
    password: yup.string().required(t("required")).min(5, `${t("min")} 6`),
    confirmedPassword: yup.string().oneOf([yup.ref("password"), null], t("passwords-are-different"))
  });
};

function checkPesel(pesel?: string) {
  if (!pesel || pesel.length != correctPeselLength) {
    return false;
  }
  const peselAsNumber = parseInt(pesel);

  if (isNaN(peselAsNumber)) {
    return false;
  }

  const controlNumber = peselAsNumber % 10;

  let peselControlSum = 0;

  for (let index = 0; index < pesel.length - 1; index++) {
    const sum = checkSum(parseInt(pesel[index]) * controlNumbers[index]);
    peselControlSum += sum;
  }

  if (checkSum(peselControlSum) === 0) {
    return controlNumber === 0 - checkSum(peselControlSum);
  }

  return controlNumber === constValue - checkSum(peselControlSum);
}

const checkSum = (value: number) => {
  if (value > 9) {
    return value % 10;
  }

  return value;
};

function getTranslateKey(error: string) {
  let tKey = "";
  for (let i = 0; i < error.length; i++) {
    if (i === 0) {
      tKey += error[i].toLowerCase();
      continue;
    }
    if (error[i] !== error[i].toUpperCase()) {
      tKey += error[i];
      continue;
    }

    tKey += "-";
    tKey += error[i].toLowerCase();
  }
  return tKey;
}

export const createUserByAdminSchema = (t: TFunction) => {
  async function checkIfUserExists({ validatedUser }: {
    validatedUser: User;
  }): Promise<true | yup.ValidationError> {
    let errors: string[] = [];
    if (validatedUser.email) {
      errors = await UsersApiFactory()
        .checkIfUserExists(validatedUser.email,
          validatedUser.id ?? undefined,
          validatedUser.socialSecurityNumber ?? undefined,
          validatedUser.passport ?? undefined)
        .then(res => res.data);
    }

    if (errors.length > 0) {
      const validationErrors: ValidationError[] = [];
      errors.forEach(error => {
        validationErrors.push(new ValidationError(t(`user-${getTranslateKey(error)}-exists`),
          "",
          error.charAt(0).toLowerCase() + error.slice(1)));
      });
      return new ValidationError(validationErrors);
    }
    return true;
  }

  return yup.object()
    .shape({
      id: yup.string(),
      firstName: yup.string().required(t("required")).nullable(),
      lastName: yup.string().required(t("required")).nullable(),
      email: yup.string().email(t("invalid-email")).required(t("required")).nullable(),
      company: yup.string().required(t("required")).nullable(),
      socialSecurityNumber: yup.string().when("passport", {
        is: (passport: string) => !passport || passport.length === 0,
        then: yup.string()
          .required(t("once-of-two-fields-required")).nullable()
      }).test((pesel, context) => {
        if (!pesel) {
          return true;
        }
        if (!checkPesel(pesel)) {
          return context.createError({ message: t("invalid-pesel") });
        }
        return true;
      }).nullable(),
      passport: yup.string().nullable().when("socialSecurityNumber", {
        is: (socialSecurityNumber: string) => !socialSecurityNumber || socialSecurityNumber.length === 0,
        then: yup.string()
          .required(t("once-of-two-fields-required")).nullable()
      })
    }, [["socialSecurityNumber", "passport"]])
    .test(async (user) => {
      return await checkIfUserExists({ validatedUser: user });
    });
};

export interface NewUserValidation {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmedPassword: string;
}

export const newUserValidationErrorMessages: NewUserValidation = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmedPassword: ""
};

export interface UserValidation {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  socialSecurityNumber?: string;
  passport?: string;
}

export const createUserByAdminValidationErrorMessages: UserValidation = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  socialSecurityNumber: "",
  passport: "",
};

type User = TypeOfShape<Assign<ObjectShape, {
  id: RequiredStringSchema<string | null | undefined, AnyObject>,
  firstName: RequiredStringSchema<string | null | undefined, AnyObject>,
  lastName: RequiredStringSchema<string | null | undefined, AnyObject>,
  email: RequiredStringSchema<string | null | undefined, AnyObject>,
  company: RequiredStringSchema<string | null | undefined, AnyObject>,
  socialSecurityNumber: yup.StringSchema<string | null | undefined, AnyObject>,
  passport: yup.StringSchema<string | null | undefined, AnyObject>
}>>
