import React from "react";
import style from "./loadingSpinner.module.scss";

function LoadingSpinner() {
  return (
    <div className={style.container}>
      <div className={style.indicator}>
        <div className={style.dot} />
      </div>
    </div>
  );
}

export default LoadingSpinner;
