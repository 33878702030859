import React, { useEffect, useState } from "react";
import style from "./editTimeEntry.module.scss";
import useGetTimeEntryByDay from "../../../app/hooks/TimeTracker/queries/useGetTimeEntryByDay";
import { toast } from "react-hot-toast";
import useCreateTimeEntry from "../../../app/hooks/TimeTracker/commands/useCreateTimeEntry";
import useUpdateTimeEntry from "../../../app/hooks/TimeTracker/commands/useUpdateTimeEntry";
import { AbsenceDto, TimeEntryDto } from "../../../app/api/api-types";
import useGetAbsenceTypes from "../../../app/hooks/TimeTracker/queries/useGetAbsenceTypes";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { formatTimeToDurations } from "../../../app/utilities/timeUtilites";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import ThirdButton from "../../../reusable-components/third-button/ThirdButton";
import Switch from "../../../reusable-components/switch/Switch";
import TwoLinesTitle from "../../../reusable-components/two-lines-title/TwoLinesTitle";
import Input from "../../../reusable-components/input/Input";
import TimeInput from "../../../reusable-components/time-input/TimeInput";
import { TimeDuration } from "../../../app/models/TimeTracker";
import { createIOptionArray } from "../../../app/utilities/IOptionArrayUtilities";
import Dropdown, { IOption } from "../../../reusable-components/dropdown/Dropdown";
import { maxDailyWorkTimeHours } from "../../../constants/workTime";

interface IProps {
  employeeId: string;
  employeeName: string;
  day: string;
  closePopup: () => void;
  refetchReport: () => void;
}

interface NewTimeEntry {
  absenceId?: number | null;
  startTime: string;
  endTime: string;
  isLockedByAdmin: boolean;
}

function EditTimeEntry({ employeeId, employeeName, day, closePopup, refetchReport }: IProps) {
  const [timeEntry, setTimeEntry] = useState<TimeEntryDto | NewTimeEntry>({
    absenceId: undefined,
    startTime: day,
    endTime: day,
    isLockedByAdmin: false
  });
  const [absence, setAbsence] = useState<string>("-");
  const [absenceDropdownOptions, setAbsenceDropdownOptions] = useState<IOption[]>([]);
  const [defaultAbsence, setDefaultAbsence] = useState<IOption>();
  const { mutate: createTimeEntry } = useCreateTimeEntry();
  const { mutate: updateTimeEntry } = useUpdateTimeEntry();
  const { data: editedTimeEntry } = useGetTimeEntryByDay(employeeId,
    day);
  const { data: absences } = useGetAbsenceTypes();
  const { t } = useTranslation("common");
  const { t: tTracker } = useTranslation("time-tracker");

  useEffect(() => {
    if (editedTimeEntry) {
      setTimeEntry(editedTimeEntry);
    }

    if (absences) {
      getAbsencesDropdownData(absences, editedTimeEntry);
    }
  }, [editedTimeEntry, absences]);

  const getAbsencesDropdownData = (absences: AbsenceDto[], timeEntry?: TimeEntryDto) => {
    const newAbsences = absences.map(absence => {
      return { id: absence.id, absenceType: t(`${absence.absenceType}-short`) };
    });

    const dropdownData = createIOptionArray(newAbsences, "id", "absenceType");
    dropdownData.unshift({ value: "", label: "-" });
    setAbsenceDropdownOptions(dropdownData);
    const absence = newAbsences?.find(absence => absence.id === timeEntry?.absenceId);
    if (absence) {
      setDefaultAbsence({ value: absence.id.toString(), label: absence.absenceType });
    } else {
      setDefaultAbsence(dropdownData[0]);
    }
  };

  const saveWorkTime = () => {
    const startTime = dayjs(timeEntry.startTime).format("YYYY MM DD HH:mm");
    const endTime = dayjs(timeEntry.endTime).format("YYYY MM DD HH:mm");
    if (!("id" in timeEntry)) {
      createTimeEntry({
        userId: employeeId,
        startTime: startTime,
        endTime: endTime,
        absenceId: timeEntry?.absenceId,
        isLockedByAdmin: timeEntry.isLockedByAdmin
      }, {
        onSuccess: () => {
          refetchReport();
          closePopup();
        }
      });
    } else {
      updateTimeEntry({
        userId: employeeId,
        timeEntryId: timeEntry.id,
        startTime: startTime,
        endTime: endTime,
        absenceId: timeEntry.absenceId,
        isLockedByAdmin: timeEntry.isLockedByAdmin
      }, {
        onSuccess: () => {
          refetchReport();
          toast.success(tTracker("updated-time-entry"));
          closePopup();
        }
      });
    }

  };

  function getAdminName() {
    if ("modifyingPerson" in timeEntry && timeEntry.modifyingPerson) {
      return timeEntry.modifyingPerson.email.toLowerCase();
    }
    return "";
  }

  function updateWorkTime({ hours = 0, minutes = 0, seconds = 0 }: TimeDuration) {
    const newEndTime = dayjs(timeEntry.startTime)
      .add(hours, "hours")
      .add(minutes, "minutes")
      .add(seconds, "seconds");
    if (dayjs.duration(newEndTime.diff(dayjs(timeEntry.startTime)))
      .asHours() <= maxDailyWorkTimeHours) {
      setTimeEntry(prev => {
        return { ...prev, absenceId: undefined, endTime: newEndTime.format() };
      });
      setAbsence("-");
      setDefaultAbsence(absenceDropdownOptions[0]);
      return formatTimeToDurations(newEndTime
        .diff(dayjs(timeEntry.startTime)));
    }
    return formatTimeToDurations(dayjs(timeEntry.endTime)
      .diff(dayjs(timeEntry.startTime)));
  }

  function changeAbsence(absence: IOption) {
    const absenceId = parseInt(absence.value);
    if (!isNaN(absenceId)) {
      setTimeEntry(prev => {
        return { ...prev, endTime: prev.startTime, absenceId: absenceId };
      });
    } else {
      setTimeEntry(prev => {
        return { ...prev, endTime: prev.startTime, absenceId: undefined };
      });
    }
    setAbsence(`${absence.label}`);
    setDefaultAbsence(absence);
  }

  return (
    <div className={style.container}>
      <p className={style.title}>{tTracker("edit-employee-entry")}</p>
      <TwoLinesTitle title={tTracker("employee-data")} />
      <Input inputClassName={style.input} content={employeeName} onChange={() => {
        return;
      }} disabled={true} label={tTracker("first-name-and-last-name")} />
      <TwoLinesTitle title={tTracker("chosen-day")} />
      <Input inputClassName={style.input}
             content={dayjs(timeEntry?.startTime).format("DD MMMM YYYY")}
             onChange={() => {
               return;
             }}
             label={tTracker("date")}
             disabled={true}>
      </Input>
      <TwoLinesTitle title={tTracker("edit-entry")} />
      <div className={style.time_container}>
        <div className={style.wrapper}>
          <p className={style.description}>{tTracker("work-time")}</p>
          <div className={style.content}>
            <TimeInput inputClassName={style.input}
                       startTime={dayjs(timeEntry.startTime)}
                       updateTimeDuration={updateWorkTime}
                       timeDuration={formatTimeToDurations(dayjs(timeEntry.endTime)
                         .diff(dayjs(timeEntry.startTime)))} />
          </div>
        </div>
        <div className={style.wrapper}>
          <p className={style.description}>{tTracker("absence-type")}</p>
          <div className={style.content}>
            <Dropdown onClick={changeAbsence}
                      dropdownClassName={style.dropdown}
                      valueStyle={style.dropdown_value}
                      value={absence}
                      tFunc={tTracker}
                      editMode={true}
                      options={absenceDropdownOptions}
                      defaultValue={defaultAbsence} />
          </div>
        </div>
        <div className={style.wrapper}>
          <p className={style.description}>{tTracker("editing-by-an-employee")}</p>
          <div className={style.lock_time_entry}>
            <FontAwesomeIcon icon={faLockOpen}
                             className={!timeEntry.isLockedByAdmin ? style.active : ""} />
            <Switch className={style.switch} state={timeEntry.isLockedByAdmin}
                    toggle={() => setTimeEntry(prev => {
                      return { ...prev, isLockedByAdmin: !prev.isLockedByAdmin };
                    })} />
            <FontAwesomeIcon icon={faLock}
                             className={timeEntry.isLockedByAdmin ? style.active : ""} />
          </div>
        </div>
      </div>
      <TwoLinesTitle title={tTracker("information")} />
      <Input inputClassName={style.input} content={getAdminName()} onChange={() => {
        return;
      }} disabled={true} label={tTracker("last-actualisation")} />
      <div className={style.button_container}>
        <ThirdButton onClick={saveWorkTime} name={t("save")} />
      </div>
    </div>
  );
}

export default EditTimeEntry;
