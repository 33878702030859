/* eslint-disable  @typescript-eslint/no-explicit-any */
// any is necessary to make those functions more universal

import { IOption } from "../../reusable-components/dropdown/Dropdown";

export function createIOptionArray<T extends { [key: string]: any }>(
  data: T[] | undefined,
  valueKey: keyof T,
  labelKey: keyof T,
  labelStyleKeys?: Array<keyof T>
) {
  if (!data) {
    return [];
  }

  return data.map<IOption>((element) => {
    const option: IOption = {
      value: element[valueKey],
      label: element[labelKey],
    };

    if (labelStyleKeys) {
      const labelStyle: any = {};

      labelStyleKeys.forEach((prop) => {
        labelStyle[prop] = element[prop];
      });
      option.labelStyle = labelStyle;
    }

    return option;
  });
}

export function createIOptionArrayUsingDirectAndNestedValues<
  T extends { [key: string]: U | any },
  U extends { [key: string]: any }
>(
  data: T[] | undefined,
  directValueKey: keyof T,
  directLabelKey: keyof T,
  directKeyOfNestedArray: keyof T,
  nestedValueKey: keyof U,
  nestedLabelKey: keyof U
) {
  if (!data) {
    return [];
  }

  return data.reduce<IOption[]>(
    (a, b) =>
      Array.isArray(b[directKeyOfNestedArray]) &&
      b[directKeyOfNestedArray].length > 0
        ? a.concat(
            createIOptionArray(
              b[directKeyOfNestedArray],
              nestedValueKey,
              nestedLabelKey
            )
          )
        : a.concat({ value: b[directValueKey], label: b[directLabelKey] }),
    []
  );
}
