import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../constants/queryKeys";
import {
  GetTimePeriodSummaryQuery,
  ReportsApiFactory,
} from "../../../api/api-types";

function fetchData(timePeriodSummary: GetTimePeriodSummaryQuery) {
  return ReportsApiFactory()
    .getTimePeriodSummary(timePeriodSummary)
    .then((res) => res.data);
}

export function useGetUserTimePeriodSummary(
  timePeriodSummary: GetTimePeriodSummaryQuery,
  hasPermission: boolean
) {
  const enabled = !Object.keys(timePeriodSummary).some(
    (key) => !timePeriodSummary[key as keyof GetTimePeriodSummaryQuery]
  );
  const query = useQuery({
    queryKey: [queryKeys.reports.timePeriodSummary, timePeriodSummary],
    queryFn: () => fetchData(timePeriodSummary),
    enabled: enabled && hasPermission,
    refetchOnWindowFocus: false,
  });

  return query;
}

export default useGetUserTimePeriodSummary;
