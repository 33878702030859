import { useQuery } from "@tanstack/react-query";
import { TimeTrackerApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

const fetchFunction = () => {
  return TimeTrackerApiFactory()
    .getAbsences()
    .then((res) => res.data);
};

export default function useGetAbsenceTypes() {
  return useQuery({
    queryKey: [queryKeys.timeTracker.absences],
    queryFn: () => fetchFunction(),
    refetchOnWindowFocus: false,
  });
}
