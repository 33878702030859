import ThreeDotsMenu from "../../../../reusable-components/three-dots-menu/ThreeDotsMenu";
import { PanelOption } from "../../../../reusable-components/three-dots-panel/ThreeDotsPanel";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onUnlinkFromProjectClick: () => void;
  disabled?: boolean;
}

const ProjectEmployeesTableRowThreeDotsMenu = ({ onUnlinkFromProjectClick, disabled }: IProps) => {
  const { t: tCommon } = useTranslation("project");

  const unlinkFromProjectOption: PanelOption = {
    text: tCommon("unlink-employee"),
    onClick: () => onUnlinkFromProjectClick(),
    Icon: <FontAwesomeIcon icon={faTrashCan} />,
  };

  const panelOptions = [unlinkFromProjectOption];

  return (
    <ThreeDotsMenu panelOptions={panelOptions}
                   direction={"bottom_left"}
                   disabled={disabled}
    />
  );
};

export default ProjectEmployeesTableRowThreeDotsMenu;
