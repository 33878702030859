import { useQuery } from "@tanstack/react-query";
import {
  GetUsersAssignedToDefaultProjectInCompanyQuery,
  ProjectsApiFactory,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

const fetchFunction = (
  data: GetUsersAssignedToDefaultProjectInCompanyQuery
) => {
  return ProjectsApiFactory()
    .getUsersAssignedToDefaultProjectInCompany(
      data.companyId,
      data.searchValue ?? undefined
    )
    .then((res) => res.data);
};

export default function useGetUsersAssignedtoDefaultProjectInCompany(
  data: GetUsersAssignedToDefaultProjectInCompanyQuery
) {
  return useQuery({
    queryKey: [queryKeys.projects.usersAssignedToDefaultProjectInCompany, data],
    queryFn: () => fetchFunction(data),
    refetchOnWindowFocus: false,
  });
}
