import { useQuery } from "@tanstack/react-query";
import { CompaniesApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

const fetchFunction = () => {
  return CompaniesApiFactory()
    .getCompanies()
    .then((res) => res.data);
};

export default function useGetCompanies(isDisabled?: boolean) {
  return useQuery({
    queryKey: [queryKeys.companies.companies],
    queryFn: fetchFunction,
    refetchOnWindowFocus: false,
    enabled: !isDisabled,
  });
}
