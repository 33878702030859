import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ProjectsApiFactory,
  UnassignUsersFromProjectCommand,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useUnassignUsersFromProject() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UnassignUsersFromProjectCommand) =>
      ProjectsApiFactory()
        .unassignFromProject(data.projectId, data)
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.paginatedUsers],
      });
    },
  });
}
