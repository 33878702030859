import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCircleExclamation,
  faPlane,
  faUserDoctor
} from "@fortawesome/free-solid-svg-icons";

export interface Absences {
  "unexcused-absence": JSX.Element,
  "sick-leave": JSX.Element,
  "planned-absence": JSX.Element,
  "national-holiday": JSX.Element,
}

const absenceTypes = {
  "unexcused-absence": <FontAwesomeIcon icon={faCircleExclamation} className={"fa-solid"} />,
  "sick-leave": <FontAwesomeIcon icon={faUserDoctor} className={"fa-solid"} />,
  "planned-absence": <FontAwesomeIcon icon={faPlane} className={"fa-solid"} />,
  "national-holiday": <FontAwesomeIcon icon={faCalendarCheck} className={"fa-solid"} />,
};

export const getAbsenceIcon = (absence: keyof typeof absenceTypes) => {
  return absenceTypes[absence];
};
