import React from "react";
import style from "./setting.module.scss";
import CircleButton from "../../../reusable-components/circle-button/CircleButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  description: string,
  value: string,
  subtractTime: () => void,
  addTime: () => void,
  disabledSubtractButton: boolean,
}

function Setting({ description, value, subtractTime, addTime, disabledSubtractButton }: IProps) {
  return (
    <div className={style.container}>
      <p className={style.description}>{description}</p>
      <div className={style.buttons_container}>
        <CircleButton className={style.button}
                      disabled={disabledSubtractButton}
                      onClick={subtractTime}
                      icon={<FontAwesomeIcon icon={faMinus} className={style.icon} />} />
        <p className={style.time_value}>{value}</p>
        <CircleButton className={style.button}
                      onClick={addTime}
                      icon={<FontAwesomeIcon icon={faPlus} className={style.icon} />} />

      </div>
    </div>
  );
}

export default Setting;
