import React, { useState } from "react";
import * as AiIcons from "react-icons/ai";
import CircleButton from "../../../reusable-components/circle-button/CircleButton";
import dayjs from "dayjs";
import style from "./dateComponent.module.scss";
import "../../../style/datePickerCalendarStyleOverride.scss";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import DatePickerInput from "../../../reusable-components/date-picker-input/DatePickerInput";
import AddTimeComponentTitle from "../add-time-component-title/AddTimeComponentTitle";
import { useSettingsContext } from "../../../app/context/SettingsContext";
import Popup from "../../../reusable-components/popup/Popup";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  workDay?: dayjs.Dayjs;
  setWorkDay: (day: dayjs.Dayjs) => void;
}

function DateComponent({ workDay, setWorkDay }: IProps) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { isTimeEntryLocked, timeEntryLockTime } = useSettingsContext();
  const { t } = useTranslation("time-tracker");

  const nextDay = () => {
    setWorkDay(dayjs(workDay).add(1, "days").startOf("day"));
  };

  const prevDay = () => {

    workDay && isTimeEntryLocked(workDay.subtract(1, "days"));
    setWorkDay(dayjs(workDay).subtract(1, "days").startOf("day"));
  };

  const formatDate = (day: dayjs.Dayjs) => {
    return day.format("DD.MM");
  };

  return (
    <div className={style.container}>
      <AddTimeComponentTitle title={t("day")} />
      <div className={style.date_container}>
        <CircleButton disabled={workDay && isTimeEntryLocked(workDay.subtract(1, "days"))}
                      onClick={prevDay}
                      icon={<FontAwesomeIcon icon={faChevronLeft} className={style.icon} />} />
        {workDay && <p className={style.date}>{formatDate(workDay)}</p>}
        <CircleButton onClick={nextDay}
                      icon={<FontAwesomeIcon icon={faChevronRight} className={style.icon} />} />
      </div>
      <DatePickerInput setShowPopup={() => setIsCalendarOpen(true)} text={t("chose-another-day")}
                       icon={<AiIcons.AiFillCalendar />} />
      <Popup showPopup={isCalendarOpen}
             closePopup={() => setIsCalendarOpen(false)}
             containerClassName={style.popup}>
        <div className={style.calendar_container}>
          <DatePicker
            locale={i18next.resolvedLanguage}
            minDate={dayjs().startOf("day").subtract(timeEntryLockTime).toDate()}
            selected={workDay?.toDate()}
            showYearDropdown={false}
            showMonthDropdown={false}
            wrapperClassName={style.date_wrapper}
            nextMonthButtonLabel={<div className={style.calendar_button}>
              <FontAwesomeIcon icon={faChevronRight} className={style.icon} />
            </div>}
            previousMonthButtonLabel={<div className={style.calendar_button}>
              <FontAwesomeIcon icon={faChevronLeft} className={style.icon} />
            </div>}
            calendarClassName={style.calendar}
            popperClassName={style.popper}
            onCalendarClose={() => setIsCalendarOpen(false)}
            onChange={(day) => {
              setWorkDay(dayjs(day));
              setIsCalendarOpen(false);
            }}
            open={isCalendarOpen} />
        </div>
      </Popup>
    </div>
  );
}

export default DateComponent;
