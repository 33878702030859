import React, { useEffect, useState } from "react";
import style from "./projectEmployees.module.scss";
import {
  reportPaginationData,
  usersPaginationData,
} from "../../../constants/paginationsData";
import { UsersData } from "../../../app/models/User";
import useGetPaginatedUsers, {
  fetchFunction,
} from "../../../app/hooks/Users/queries/useGetPaginatedUsers";
import TablePagination from "../../../reusable-components/table-pagination/TablePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ProjectDto, UserDetailsDto } from "../../../app/api/api-types";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../constants/queryKeys";
import ProjectEmployeesTable from "./project-employees-table/ProjectEmployeesTable";
import ProjectEmployeesHeader from "./ProjectEmployeesHeader";
import useProject from "../../../app/hooks/Projects/queries/useProject";
import { useLocation, useParams } from "react-router-dom";
import Popup from "../../../reusable-components/popup/Popup";
import ThirdButton from "../../../reusable-components/third-button/ThirdButton";
import useUnassignUsersFromProject from "../../../app/hooks/Projects/commands/useUnassignUsersFromProject";
import AssignUsersToProject from "../assign-users-to-project/AssignUsersToProject";

function ProjectEmployees() {
  const [usersFetchData, setUsersFetchData] = useState<UsersData>({
    pageNumber: 1,
    pageSize: usersPaginationData.pageSize,
    searchValue: undefined,
    companyId: undefined,
    projectId: undefined,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [showAddUsersToProjectPopup, setShowAddUsersToProjectPopup] =
    useState(false);
  const [userToEdit, setUserToEdit] = useState<UserDetailsDto>();
  const { id } = useParams();
  const { state: companyId } = useLocation();
  const { t } = useTranslation("project");
  const { data: project = {} as ProjectDto } = useProject(id ?? "");
  const { data: paginatedUsers, isPlaceholderData } = useGetPaginatedUsers(
    usersFetchData,
    !!usersFetchData.projectId
  );
  const { mutate: unassignUserFromProject } = useUnassignUsersFromProject();
  const queryClient = useQueryClient();

  useEffect(() => {
    getNextPage();
  }, [paginatedUsers, isPlaceholderData, usersFetchData.pageNumber, queryClient]);

  useEffect(() => {
    setUsersFetchData((prev) => {
      return { ...prev, projectId: project.id };
    });
  }, [project.id]);

  function getNextPage() {
    if (paginatedUsers && usersFetchData.projectId) {
      if (
        !isPlaceholderData &&
        usersFetchData.pageNumber < paginatedUsers.totalPages
      ) {
        const nextPageFetchData = {
          ...usersFetchData,
          pageNumber: usersFetchData.pageNumber + 1,
        };
        queryClient.prefetchQuery({
          queryKey: [queryKeys.users.paginatedUsers, nextPageFetchData],
          queryFn: () => fetchFunction(nextPageFetchData),
        });
      }
    }
  }

  const getUsers = (data: UsersData) => {
    setUsersFetchData(data);
  };

  const goToPage = (updater: number | ((pageIndex: number) => number)) => {
    if (typeof updater === "number") {
      setUsersFetchData((prev) => {
        return { ...prev, pageNumber: updater };
      });
      document.getElementById("content")?.scrollTo(0, 0);
      document.getElementById("layout")?.scrollTo(0, 0);
    }
  };

  function addUser() {
    setShowAddUsersToProjectPopup(true);
  }

  function handleEditUser(user: UserDetailsDto) {
    setUserToEdit(user);
    setShowPopup(true);
  }

  function closePopup() {
    setUserToEdit(undefined);
    setShowPopup(false);
  }

  const handleRemoveUserFromProject = () => {
    userToEdit &&
      unassignUserFromProject({
        projectId: project.id,
        userIds: [userToEdit.id],
      });
    closePopup();
  };

  return (
    <div className={style.container}>
      <BreadcrumbsItem to={"/projects/:id"}>
        {t("list-of-employees")}
      </BreadcrumbsItem>
      <ProjectEmployeesHeader
        dataToFetch={usersFetchData}
        buttonData={{
          icon: <FontAwesomeIcon icon={faAdd} />,
          name: t("add-employees"),
          onClick: () => addUser(),
        }}
        getUsers={getUsers}
        project={project}
      />
      {paginatedUsers && (
        <>
          <ProjectEmployeesTable
            users={paginatedUsers?.users}
            setUserToEdit={handleEditUser}
            isDefaultProject={project.isDefault}
          />
          <TablePagination
            goToPage={goToPage}
            canPreviousPage={usersFetchData.pageNumber > 1}
            canNextPage={usersFetchData.pageNumber < paginatedUsers.totalPages}
            previousPage={() => goToPage(usersFetchData.pageNumber - 1)}
            nextPage={() => goToPage(usersFetchData.pageNumber + 1)}
            pageCount={paginatedUsers.totalPages}
            rowsAmount={paginatedUsers.totalItemsCount}
            pageSize={reportPaginationData.pageSize}
            pageIndex={usersFetchData.pageNumber}
          />
        </>
      )}

      {userToEdit && (
        <Popup showPopup={showPopup} closePopup={closePopup}>
          <div className={style.popup_container}>
            <p className={style.title}>{t("remove-user-from-project")}</p>
            <p>
              {t("unlink-user-from-project-warning-message")}
              <strong>
                {` ${userToEdit.firstName} ${userToEdit.lastName}?`}
              </strong>
            </p>
            <div className={style.buttons_container}>
              <ThirdButton
                style={style.cancel_button}
                name={t("cancel")}
                onClick={closePopup}
              />
              <ThirdButton
                name={t("remove")}
                onClick={handleRemoveUserFromProject}
              />
            </div>
          </div>
        </Popup>
      )}
      {showAddUsersToProjectPopup && project.id && (
        <Popup
          enableSpinner={false}
          containerClassName={style.popup}
          showPopup={showAddUsersToProjectPopup}
          closePopup={() => setShowAddUsersToProjectPopup(false)}
        >
          <AssignUsersToProject
            project={project}
            companyId={companyId ?? ""}
            closePopup={() => setShowAddUsersToProjectPopup(false)}
          />
        </Popup>
      )}
    </div>
  );
}

export default ProjectEmployees;
