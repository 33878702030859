import style from "./tablePagination.module.scss";
import { MdChevronLeft, MdChevronRight, MdFirstPage, MdLastPage, } from "react-icons/md";
import { useTranslation } from "react-i18next";

interface IProp {
  goToPage: (updater: number | ((pageIndex: number) => number)) => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  rowsAmount: number;
}

const getFirstElementNumberOnPage = (pageIndex: number, pageSize: number) => {
  return pageIndex * pageSize + 1 - pageSize;
};

const getLastOrderNumberOnPage = (
  pageIndex: number,
  pageSize: number,
  totalAmountOfRows: number
) => {
  const lastOrderNumberForFullPage = pageIndex * pageSize;

  if (lastOrderNumberForFullPage > totalAmountOfRows) {
    return totalAmountOfRows;
  }
  return lastOrderNumberForFullPage;
};

const TablePagination = ({
  goToPage,
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageSize,
  pageIndex,
  rowsAmount,
}: IProp) => {
  const { t } = useTranslation("common");

  return (
    <div className={style.pagination_wrapper}>
      <div className={style.pagination_container}>
        <MdFirstPage
          onClick={canPreviousPage ? () => goToPage(1) : undefined}
          className={`${style.pagination_icon} ${
            !canPreviousPage ? style.disabled : ""
          }`}
          size={"30px"}
        />
        <MdChevronLeft
          onClick={canPreviousPage ? () => previousPage() : undefined}
          className={`${style.pagination_icon} ${
            !canPreviousPage ? style.disabled : ""
          }`}
          size={"30px"}
        />
        <div className={style.text_container}>
          {t("pagination", {
            firstItemOnPage: getFirstElementNumberOnPage(pageIndex, pageSize),
            lastItemOnPage: getLastOrderNumberOnPage(
              pageIndex,
              pageSize,
              rowsAmount
            ),
            total: rowsAmount,
          })}
        </div>
        <MdChevronRight
          onClick={canNextPage ? () => nextPage() : undefined}
          className={`${style.pagination_icon} ${
            !canNextPage ? style.disabled : ""
          }`}
          size={"30px"}
        />
        <MdLastPage
          onClick={canNextPage ? () => goToPage(pageCount - 1) : undefined}
          className={`${style.pagination_icon} ${
            !canNextPage ? style.disabled : ""
          }`}
          size={"30px"}
        />
      </div>
    </div>
  );
};

export default TablePagination;
