import style from "./dashboard.module.scss";
import { UserContext } from "../../app/context/UserContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as MdIcons from "react-icons/md";
import { useTranslation } from "react-i18next";
import { DayDetails, GetTimePeriodSummaryQuery } from "../../app/api/api-types";
import useGetUserTimePeriodSummary from "../../app/hooks/TimeTracker/queries/useGetUserTimePeriodSummary";
import dayjs from "dayjs";
import Dropdown from "../../reusable-components/dropdown/Dropdown";
import { createIOptionArray } from "../../app/utilities/IOptionArrayUtilities";
import { Options } from "../../reusable-components/group-report/ReportOptions";
import GroupReportHeader from "../../reusable-components/group-report/GroupReportHeader";
import WeeklyReport from "../../reusable-components/group-report/WeeklyReport";
import { formatTimeToDurations } from "../../app/utilities/timeUtilites";
import Button from "../../reusable-components/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import PositionFixedContainer from "../../reusable-components/position-fixe-container/PositionFixedContainer";
import LoadingSpinner from "reusable-components/loading-spinner/LoadingSpinner";

interface ReportOption {
  key: string;
  title: string;
}

interface DayGroupedByWeek {
  week: number;
  days: dayjs.Dayjs[];
}

function Dashboard() {
  const { state } = useLocation();
  const { user } = useContext(UserContext);
  const [option, setOption] = useState("");
  const [reportOptions, setReportOptions] = useState<ReportOption[]>([]);
  const [days, setDays] = useState<DayGroupedByWeek[]>([]);
  const dataToFetch = useRef<GetTimePeriodSummaryQuery>({
    userId: user.id,
    startTime: dayjs().startOf("week").format("YYYY-MM-DD HH:mm:ss"),
    endTime: dayjs().endOf("week").format("YYYY-MM-DD HH:mm:ss"),
  } as GetTimePeriodSummaryQuery);
  const { t, i18n } = useTranslation("time-tracker");
  const { t: tRep } = useTranslation("reports");
  const { data: userTimeReportData, isLoading } = useGetUserTimePeriodSummary(
    dataToFetch.current,
    user.hasPermissionTo("time-tracker")
  );
  const navigate = useNavigate();

  useEffect(() => {
    const optionsArray = getDropdownOptions();
    setReportOptions(optionsArray);
    setOption(optionsArray[0].title);
    const startTime = dayjs().startOf("week");
    const endTime = dayjs().endOf("week");

    dataToFetch.current = {
      userId: user.id,
      startTime: startTime.format("YYYY-MM-DD HH:mm:ss"),
      endTime: endTime.format("YYYY-MM-DD HH:mm:ss"),
    };

    if (state?.option) {
      changeReport(state.option);
    } else {
      setOption(optionsArray[0].title);
      setDays(getDaysGroupedByWeek(startTime, endTime));
    }
  }, [user.id]);

  useEffect(() => {
    setReportOptions(getDropdownOptions());
    setDays(
      getDaysGroupedByWeek(
        dayjs(dataToFetch.current.startTime),
        dayjs(dataToFetch.current.endTime)
      )
    );
  }, [i18n.language, dayjs.locale()]);

  function getDropdownOptions() {
    const optionsArray = Options.map((option) => {
      return {
        key: option,
        title: tRep(option),
      };
    });
    return optionsArray;
  }

  const changeReport = (reportType: string) => {
    switch (reportType) {
      case "this-month": {
        const startTime = dayjs().startOf("month");
        const endTime = dayjs().endOf("month");
        dataToFetch.current.startTime = startTime.format("YYYY-MM-DD HH:mm:ss");
        dataToFetch.current.endTime = endTime.format("YYYY-MM-DD HH:mm:ss");
        setOption(reportType);
        setDays(getDaysGroupedByWeek(startTime, endTime));
        break;
      }

      case "last-month": {
        const startTime = dayjs().subtract(1, "month").startOf("month");
        const endTime = dayjs().subtract(1, "month").endOf("month");

        dataToFetch.current.startTime = startTime.format("YYYY-MM-DD HH:mm:ss");
        dataToFetch.current.endTime = endTime.format("YYYY-MM-DD HH:mm:ss");
        setOption(reportType);
        setDays(getDaysGroupedByWeek(startTime, endTime));
        break;
      }

      case "last-week": {
        const startTime = dayjs().startOf("week").subtract(1, "weeks");
        const endTime = dayjs(startTime).endOf("week");
        dataToFetch.current.startTime = startTime.format(
          "YYYY-MM-DD" + " HH:mm:ss"
        );

        dataToFetch.current.endTime = endTime.format(
          "YYYY-MM-DD" + " HH:mm:ss"
        );
        setOption(reportType);
        setDays(getDaysGroupedByWeek(startTime, endTime));
        break;
      }

      case "this-week":
      default: {
        const startTime = dayjs().startOf("week");
        const endTime = dayjs().endOf("week");
        dataToFetch.current.startTime = startTime.format("YYYY-MM-DD HH:mm:ss");
        dataToFetch.current.endTime = endTime.format("YYYY-MM-DD HH:mm:ss");
        setOption(reportType);
        setDays(getDaysGroupedByWeek(startTime, endTime));
        return;
      }
    }
  };

  const getTitle = (day: dayjs.Dayjs) => {
    return {
      title: dayjs(day).format("MMMM"),
      totalTitle: tRep("hours-amount"),
    };
  };

  const getTotalWorkTime = (userTimeReportData: DayDetails[]) => {
    return userTimeReportData.reduce(
      (prev, actual) => prev + actual.trackedTime,
      0
    );
  };

  const getDaysGroupedByWeek = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    const days: { week: number; day: dayjs.Dayjs }[] = [];
    for (let i = 0; i <= end.diff(start, "days"); i++) {
      const day = start.add(i, "days");
      const week = day.week();

      days.push({ week: week, day: day });
    }
    const daysByWeek = days.reduce((accu, actual) => {
      const newWeek = accu.find((dayByWeek) => dayByWeek.week === actual.week);
      if (!newWeek) {
        accu.push({ week: actual.week, days: [actual.day] });
      } else {
        const index = accu.findIndex(
          (dayByWeek) => dayByWeek.week === actual.week
        );
        accu[index].days.push(actual.day);
      }
      return accu;
    }, [] as DayGroupedByWeek[]);
    return daysByWeek;
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={style.wrapper}>
      <div className={style.header_wrapper}>
        {user.hasPermissionTo("time-tracker") && (
          <div className={style.dropdown_container}>
            <Dropdown
              editMode={true}
              value={option}
              onClick={(value) => changeReport(value.value)}
              options={createIOptionArray(reportOptions, "key", "title")}
              wrapperClassName={style.dropdown}
            />
          </div>
        )}
      </div>
      {user.hasPermissionTo("time-tracker") && (
        <div className={style.container}>
          {days.length > 1 && (
            <GroupReportHeader
              monthly={true}
              title={getTitle(days[0].days[0])}
              total={formatTimeToDurations(
                userTimeReportData ? getTotalWorkTime(userTimeReportData) : 0
              )}
            />
          )}
          {days.map((data, index) => (
            <WeeklyReport
              key={index}
              dashboardTimeRange={option}
              days={data.days}
              entries={userTimeReportData || []}
            />
          ))}
        </div>
      )}
      <PositionFixedContainer>
        <Button
          name={t("add-work-time")}
          onClick={() =>
            navigate("/add-work-time", { state: { option: option } })
          }
          icon={<MdIcons.MdAdd size={"30px"} />}
        />
      </PositionFixedContainer>
    </div>
  );
}

export default Dashboard;
