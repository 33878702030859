import React, { ReactNode } from "react";
import style from "./resetPasswordPageContainer.module.scss";
import Logo from "../logo/Logo";
import Button from "../button/Button";
import * as MdIcons from "react-icons/md";
import { useIsMutating } from "@tanstack/react-query";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

interface IProps {
  children: ReactNode;
  onButton1Click: () => void;
  onButton2Click: () => void;
  showButton2: boolean;
  button1Text: string;
  button2Text: string;
  headerText: string;
  isButton1Disabled: boolean;
  isButton2Disabled: boolean;
}

function ResetPasswordPageContainer({
  children,
  onButton1Click,
  onButton2Click,
  button1Text,
  button2Text,
  isButton1Disabled,
  isButton2Disabled,
  headerText,
  showButton2,
}: IProps) {
  const isFetching = useIsMutating();
  return (
    <div className={style.page_container}>
      {!!isFetching && <LoadingSpinner />}
      <div
        className={`${style.page_content} ${isFetching ? style.hidden : ""}`}
      >
        <div className={style.logo_container}>
          <Logo logoClassName={style.logo} />
        </div>
        <div className={style.form_container}>
          <div className={style.title}>
            {headerText}
            <div className={style.line}></div>
          </div>
          <div className={style.inputs_box}>{children}</div>
        </div>
        <div className={style.buttons}>
          <Button
            name={button1Text}
            style={style.button}
            onClick={onButton1Click}
            disabled={isButton1Disabled}
            icon={<MdIcons.MdClear />}
          />
          {showButton2 && (
            <Button
              name={button2Text}
              style={style.button}
              onClick={onButton2Click}
              disabled={isButton2Disabled}
              icon={<MdIcons.MdDone />}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPageContainer;
