import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../constants/queryKeys";
import { CompaniesApiFactory } from "../../../api/api-types";

const fetchFunction = (companyId: string) => {
  return CompaniesApiFactory()
    .getCompanyProjects(companyId)
    .then((res) => res.data);
};

export default function useGetProjectsAssignedToCompany(companyId = "") {
  return useQuery({
    queryKey: [queryKeys.companies.projectsAssignedToCompany, companyId],
    queryFn: () => fetchFunction(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
  });
}
