import React from "react";
import style from "./circleButton.module.scss";

interface IProps {
  className?: string;
  onClick: () => void;
  icon: JSX.Element;
  disabled?: boolean;
}

function CircleButton({ className, onClick, icon, disabled }: IProps) {
  return (

    <button onClick={onClick}
            className={`${style.button} ${className ? className : ""}`}
            disabled={disabled}>
      {icon}
    </button>

  );
}

export default CircleButton;
