import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import style from "./layoutHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChevronDown,
  faCircleInfo,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { UserContext } from "../../app/context/UserContext";
import { useTranslation } from "react-i18next";
import Switch from "../../reusable-components/switch/Switch";

interface IProps {
  breadcrumbs: ReactNode;
  setShowInstructions: () => void;
  switchState: boolean;
  toggleSwitch: () => void;
}

function LayoutHeader({ breadcrumbs, setShowInstructions, switchState, toggleSwitch }: IProps) {
  const [showMenu, setShowMenu] = useState(false);
  const { user, logout, hasUserEmployeeRole } = useContext(UserContext);
  const { t } = useTranslation("common");
  const menuContainer = useRef<HTMLUListElement>(null);
  const menuButton = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => document.removeEventListener("click", closeMenu);
  }, []);

  function closeMenu(e: MouseEvent) {
    if (e.target instanceof Node && menuButton.current?.contains(e.target)) {
      return;
    }
    if (e.target instanceof Node && !menuContainer.current?.contains(e.target)) {
      setShowMenu(false);
    }
  }

  function onLogout() {
    setShowMenu(false);
    logout();
  }

  return (
    <div className={style.container}>
      <div className={style.content_container}>
        {breadcrumbs}
        <div className={style.wrapper}>
          <div className={style.switch_container}>
            {hasUserEmployeeRole() && <>
              <p>
                {t("pl")}
              </p>
              <Switch state={switchState} toggle={toggleSwitch} />
              <p> {t("ua")}</p></>}
          </div>
          <div ref={menuButton} onClick={() => setShowMenu(prev => !prev)}
               className={`${style.log_out_container} ${showMenu ? style.show_menu : ""}`}>
            <div className={style.icon_container}>
              <FontAwesomeIcon icon={faUser} />
            </div>
            <FontAwesomeIcon className={style.expand_icon} icon={faChevronDown} size={"xs"} />
          </div>
          <ul ref={menuContainer}
              className={`${style.navigation} ${showMenu ? style.show_menu : ""}`}>
            <li className={style.email}>
              <p> {user.email}</p>
              <div className={style.line} />
            </li>
            {hasUserEmployeeRole() &&
              <li className={style.instructions}
                  onClick={() => {
                    setShowMenu(false);
                    setShowInstructions();
                  }}>
                <div>
                  <FontAwesomeIcon icon={faCircleInfo}
                                   className={style.log_out} /></div>
                <p>{t("instruction")}</p>
              </li>}
            < li className={`${style.navigation_item} ${style.log_out}`} onClick={onLogout}>
              <Link to={"/"}>
                <div><FontAwesomeIcon icon={faArrowRightFromBracket}
                                      className={style.log_out} /></div>
                <p>{t("sign-out")}</p></Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={style.line} />
    </div>
  );
}

export default LayoutHeader;
