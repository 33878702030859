import React from "react";
import style from "./usersFilter.module.scss";
import Dropdown, { IOption } from "../dropdown/Dropdown";
import Input from "../input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface IProps {
  name: string,
  filterByName: (name: string) => void,
  filterByCompany?: (company: IOption) => void,
  filterByProject: (project: IOption) => void,
  filterByStatus: (status: IOption) => void
  company?: string,
  companies?: IOption[],
  project: string,
  projects: IOption[],
  inputPlaceholderText?: string,
  status: string,
  statuses: IOption[],
  displayStatusFilter: boolean,
  displayProjectsFilter: boolean,
  disableCompaniesFilter?: boolean
}

function UsersFilter({
  filterByName,
  filterByCompany,
  filterByProject,
  filterByStatus,
  name,
  company,
  companies,
  project,
  projects,
  inputPlaceholderText,
  status,
  statuses,
  displayStatusFilter,
  displayProjectsFilter,
  disableCompaniesFilter = false

}: IProps) {
  const { t } = useTranslation("users");
  return <>
    <Input inputClassName={style.input}
           containerClassName={style.input_container}
           content={name}
           placeholder={inputPlaceholderText}
           onChange={(e) => filterByName(e.target.value)}>
      <FontAwesomeIcon icon={faSearch} />
    </Input>
    {displayStatusFilter && <div className={style.dropdown_container}>
      <Dropdown onClick={filterByStatus}
                dropdownClassName={style.dropdown}
                valueStyle={style.dropdown_value}
                value={status}
                editMode={true}
                options={statuses}
                tFunc={t}
      /></div>}
    {companies && !disableCompaniesFilter &&
      <div className={style.dropdown_container}>
        <Dropdown onClick={filterByCompany}
                  dropdownClassName={style.dropdown}
                  valueStyle={style.dropdown_value}
                  value={company}
                  editMode={true}
                  options={companies}
        />
      </div>}
    {displayProjectsFilter && <div className={style.dropdown_container}>
      <Dropdown onClick={filterByProject}
                dropdownClassName={style.dropdown}
                defaultText={t("all-projects")}
                valueStyle={style.dropdown_value}
                value={project}
                editMode={true}
                options={projects}
                tFunc={t}
                disabled={projects.length <= 0}
      />
    </div>}
  </>;
}

export default UsersFilter;
