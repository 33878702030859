import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateUserCommand, UsersApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (user: UpdateUserCommand) => UsersApiFactory().updateUser(user.id, user),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.paginatedUsers],
      });
    },
  });
}
