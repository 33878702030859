import React, { useEffect, useState } from "react";
import style from "./users.module.scss";
import {
  reportPaginationData,
  usersPaginationData,
} from "../../constants/paginationsData";
import FiltersHeader from "../reports/FiltersHeader";
import { UsersData } from "../../app/models/User";
import useGetPaginatedUsers, {
  fetchFunction,
} from "../../app/hooks/Users/queries/useGetPaginatedUsers";
import UsersTable from "./users-table/UsersTable";
import TablePagination from "../../reusable-components/table-pagination/TablePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Popup from "../../reusable-components/popup/Popup";
import { UserDetailsDto } from "../../app/api/api-types";
import User from "./add-or-edit-user/User";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";

function Users() {
  const [usersFetchData, setUsersFetchData] = useState<UsersData>({
    pageNumber: 1,
    pageSize: usersPaginationData.pageSize,
    searchValue: undefined,
    companyId: undefined,
    status: undefined,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserDetailsDto>();
  const { t } = useTranslation("users");
  const { t: tCom } = useTranslation("common");
  const { data: paginatedUsers, isPlaceholderData } =
    useGetPaginatedUsers(usersFetchData);
  const queryClient = useQueryClient();

  useEffect(() => {
    getNextPage();
  }, [paginatedUsers, isPlaceholderData, usersFetchData.pageNumber, queryClient]);

  function getNextPage() {
    if (paginatedUsers) {
      if (
        !isPlaceholderData &&
        usersFetchData.pageNumber < paginatedUsers.totalPages
      ) {
        const nextPageFetchData = {
          ...usersFetchData,
          pageNumber: usersFetchData.pageNumber + 1,
        };
        queryClient.prefetchQuery({
          queryKey: [queryKeys.users.paginatedUsers, nextPageFetchData],
          queryFn: () => fetchFunction(nextPageFetchData),
        });
      }
    }
  }

  const getUsers = (data: UsersData) => {
    setUsersFetchData(data);
  };

  const goToPage = (updater: number | ((pageIndex: number) => number)) => {
    if (typeof updater === "number") {
      setUsersFetchData((prev) => {
        return { ...prev, pageNumber: updater };
      });
      document.getElementById("content")?.scrollTo(0, 0);
      document.getElementById("layout")?.scrollTo(0, 0);
    }
  };

  function addUser() {
    setUserToEdit(undefined);
    setShowPopup(true);
  }

  function handleEditUser(user: UserDetailsDto) {
    setUserToEdit(user);
    setShowPopup(true);
  }

  function closePopup() {
    setUserToEdit(undefined);
    setShowPopup(false);
  }

  return (
    <div className={style.container}>
      <BreadcrumbsItem to={"/users"}>{tCom("users")}</BreadcrumbsItem>
      <FiltersHeader
        getData={getUsers}
        dataToFetch={usersFetchData}
        buttonData={{
          icon: <FontAwesomeIcon icon={faAdd} />,
          name: t("add-employee"),
          onClick: () => addUser(),
        }}
        inputPlaceholderText={t("search-user")}
      />
      {paginatedUsers && (
        <>
          <UsersTable
            users={paginatedUsers?.users}
            setUserToEdit={handleEditUser}
          />
          <TablePagination
            goToPage={goToPage}
            canPreviousPage={usersFetchData.pageNumber > 1}
            canNextPage={usersFetchData.pageNumber < paginatedUsers.totalPages}
            previousPage={() => goToPage(usersFetchData.pageNumber - 1)}
            nextPage={() => goToPage(usersFetchData.pageNumber + 1)}
            pageCount={paginatedUsers.totalPages}
            rowsAmount={paginatedUsers.totalItemsCount}
            pageSize={reportPaginationData.pageSize}
            pageIndex={usersFetchData.pageNumber}
          />
        </>
      )}
      <Popup
        showPopup={showPopup}
        closePopup={() => closePopup()}
        containerClassName={style.popup}
      >
        <User userToEdit={userToEdit} closePopup={() => closePopup()} />
      </Popup>
    </div>
  );
}

export default Users;
