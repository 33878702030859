import style from "./dropdown.module.scss";
import * as GoIcons from "react-icons/go";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { TFunction } from "i18next";
import { guidEmpty } from "../../constants/emptyValues";
import { useTranslation } from "react-i18next";

interface IProps {
  editMode: boolean;
  disabled?: boolean;
  options: IOption[];
  dropdownUniqueKey?: string;
  value?: string;
  defaultValue?: IOption;
  additionalFormat?: (opt: IOption) => { text: string; styles: CSSProperties };
  modifyLabel?: (label: string) => string;
  onClick?: (opt: IOption) => void;
  tFunc?: TFunction;
  valueStyle?: string;
  selectedReadValueStyle?: string;
  filterable?: boolean;
  defaultText?: string;
  errorMessage?: string;
  clearErrorMessage?: () => void;
  classname?: string;
  wrapperClassName?: string;
  dropdownClassName?: string;
  dropdownOptionsClassName?: string;
}

export interface IOption {
  value: string;
  label: string;
  labelStyle?: CSSProperties;
}

const checkIfIsSelected = (value: string | undefined) => {
  if (value && value !== guidEmpty) {
    return true;
  }
  return false;
};

const Dropdown = ({
  tFunc,
  editMode,
  disabled,
  options,
  dropdownUniqueKey,
  additionalFormat,
  modifyLabel,
  onClick,
  value,
  defaultValue,
  valueStyle,
  selectedReadValueStyle,
  filterable,
  defaultText,
  errorMessage,
  clearErrorMessage,
  classname,
  wrapperClassName,
  dropdownClassName,
  dropdownOptionsClassName
}: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [current, setCurrent] = useState<IOption>(options[0]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [elementToFocus, setElementToFocus] = useState<HTMLElement | null>(
    null
  );
  const { t, i18n } = useTranslation("common");

  const containerRef = useRef<HTMLDivElement>(null);

  const dropdownOptionsDivs = document.getElementsByClassName(
    dropdownUniqueKey ? dropdownUniqueKey : "option"
  ) as HTMLCollectionOf<HTMLElement>;

  useEffect(() => {
    if (options.length == 0) {
      setCurrent(options[0]);
      return;
    }
    const currentOption = options.find((x) => x.value === value);
    if (currentOption) {
      setCurrent(currentOption);
      setSelectedIndex(options.indexOf(currentOption));
      setFilterValue(currentOption.label);
    } else {
      setCurrent(options[0]);
      setSelectedIndex(0);
      setFilterValue(options[0].label);
    }
  }, [options, i18n.language]);

  useEffect(() => {
    if (elementToFocus) {
      elementToFocus.focus();
      elementToFocus.scrollIntoView({ block: "center" });
    }
  }, [elementToFocus]);

  useEffect(() => {
    if (defaultValue) {
      const currentOption = options.find((x) => x.value === defaultValue.value);
      setCurrent(defaultValue);
      currentOption && setSelectedIndex(options.indexOf(currentOption));
    }
  }, [defaultValue]);

  const format = (opt: IOption, index: number) => {
    let addStyle: CSSProperties = {};
    let additionalText = "";

    if (!opt) {
      return <div />;
    }

    if (additionalFormat) {
      const { text, styles } = additionalFormat(opt);
      addStyle = styles;
      additionalText = text;
    }

    const label = modifyLabel ? modifyLabel(opt.label) : opt.label;

    return (
      <div
        className={`${(selectedIndex === index && checkIfIsSelected(value)) ? style.selected : ""} ${
          selectedReadValueStyle && !editMode ? selectedReadValueStyle : ""
        } `}
      >
        <span style={opt.labelStyle}>
          {tFunc ? tFunc(label) : label}
        </span>
        <span style={addStyle}>{additionalText}</span>
      </div>
    );
  };

  function handleItemClick(index: number) {
    setSelectedIndex(index);
    setCurrent(options[index]);
    if (onClick) {
      onClick(options[index]);
    }
  }

  const filter = (options: IOption[]) => {
    let filtered = options;

    if (filterable) {
      filtered = options.filter((x) => x.label.startsWith(filterValue));
    }

    return filtered.map((o, i) => (
      <div
        key={i}
        className={`${style.item} ${dropdownUniqueKey} ${
          valueStyle ? valueStyle : ""
        }`}
        onClick={() => handleItemClick(i)}
        tabIndex={open ? 0 : undefined}
        onKeyDown={(event) => handleKeyboardOnDropdown(event, i)}
      >
        {format(o, i)}
      </div>
    ));
  };

  function onFilterInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterValue(e.target.value);

    if (onClick) {
      onClick({ label: e.target.value, value: e.target.value });
    }
  }

  function onDropdownClick() {
    setOpen((prev) => !prev && editMode);
    clearErrorMessage && clearErrorMessage();
    if (containerRef.current) {
      setElementToFocus(containerRef.current);
    }
  }

  function closeDropdown() {
    if (containerRef.current) {
      setElementToFocus(containerRef.current);
    }
    setOpen(false);
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      onDropdownClick();
      const firstElement = dropdownOptionsDivs.item(0);
      setElementToFocus(firstElement);
    } else if (e.key === "Escape") {
      closeDropdown();
    }
  };

  const handleKeyboardOnDropdown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number
  ) => {
    switch (e.key) {
      case "Enter":
      case " ":
        handleItemClick(index);
        onDropdownClick();
        break;
      case "ArrowDown":
        e.preventDefault();
        if (dropdownOptionsDivs.length > index + 1) {
          const nextElement = dropdownOptionsDivs.item(index + 1);
          setElementToFocus(nextElement);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (index > 0) {
          const prevElement = dropdownOptionsDivs.item(index - 1);
          setElementToFocus(prevElement);
        }
        break;
      case "Escape":
        closeDropdown();
        break;
      case "Tab":
        e.preventDefault();
        break;
    }
  };

  return (
    <>
      {open && <div className={style.overlay} onClick={closeDropdown} />}
      <div
        className={`${style.container} ${dropdownClassName ? dropdownClassName : ""} ${editMode ? style.edit : ""} ${
          disabled ? style.disabled : ""
        }`}
        onClick={onDropdownClick}
      >
        {editMode ? (
          <div
            className={`${style.wrapper} ${open ? style.expand : ""} ${editMode ? style.edit : ""} ${
              disabled ? style.disabled : ""} ${editMode && errorMessage ? style.error : ""} ${
              wrapperClassName ? wrapperClassName : ""}`}
            ref={containerRef}
            tabIndex={0}
            onKeyDown={!open ? handleKeyPress : undefined}
          >
            <div
              className={`${style.value} ${valueStyle ? valueStyle : ""} ${
                disabled ? style.disabled : ""
              }`}
            >
              {!filterable ? (
                <div>
                  {checkIfIsSelected(value)
                    ? format(current, selectedIndex)
                    : <span>{defaultText ? defaultText : t("choose-option")}</span>}</div>
              ) : (
                <input
                  readOnly={!editMode}
                  value={filterValue}
                  autoCorrect={"false"}
                  spellCheck={false}
                  onChange={onFilterInputChange}
                />
              )}
              <GoIcons.GoChevronDown
                size={"22px"}
                className={`${style.expand_icon} ${open ? style.expand : ""} ${
                  editMode ? style.edit : ""
                } ${disabled ? style.disabled : ""}`}
              />
            </div>
            <div className={`${style.dropdown} ${open ? style.expand : ""} ${(open && dropdownOptionsClassName) ? dropdownOptionsClassName : ""}`}>
              {filter(options)}
            </div>
            {errorMessage && (
              <label className={style.error_label}>{errorMessage}</label>
            )}
          </div>
        ) : (
          <div className={classname ? classname : ""}>
            {format(current, selectedIndex)}
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown;
