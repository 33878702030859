import React, { useState } from "react";
import style from "./addTimeComponentTitle.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import Popup from "../../../reusable-components/popup/Popup";
import AbsencesInfo from "../absence-type/AbsencesInfo";

interface IProps {
  title: string;
  showInfo?: boolean;
  titleClassName?: string;
}

function AddTimeComponentTitle({ title, showInfo, titleClassName }: IProps) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.title_container}>
        <p className={`${style.title} ${titleClassName ? titleClassName : ""}`}>{title}</p>
        {showInfo &&
          <FontAwesomeIcon onClick={() => setShowPopup(true)}
                           icon={faCircleQuestion}
                           className={`${style.info_icon} fa-solid`} />}
      </div>
      <div className={style.line} />
      <Popup showPopup={showPopup}
             closePopup={() => setShowPopup(false)}
             containerClassName={style.popup}>
        <AbsencesInfo />
      </Popup>
    </div>
  );
}

export default AddTimeComponentTitle;
