import React, { useEffect, useState } from "react";
import style from "./projects.module.scss";
import { projectsPaginationData } from "../../constants/paginationsData";
import { ProjectsData } from "../../app/models/User";
import TablePagination from "../../reusable-components/table-pagination/TablePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Popup from "../../reusable-components/popup/Popup";
import { ProjectDetailsDto } from "../../app/api/api-types";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";
import useGetPaginatedProjects, {
  fetchFunction,
} from "../../app/hooks/Projects/queries/useGetPaginatedProjects";
import ProjectsTable from "./projects-table/ProjectsTable";
import Project from "./add-or-edit-project/Project";
import AssignEmployeeMessage from "./assign-employees-msg-popup/AssignEmployeeMessage";
import { useNavigate } from "react-router-dom";
import AssignUsersToProject from "./assign-users-to-project/AssignUsersToProject";
import FiltersHeader from "../reports/FiltersHeader";

function Projects() {
  const [projectsFetchData, setProjectsFetchData] = useState<ProjectsData>({
    pageNumber: 1,
    pageSize: projectsPaginationData.pageSize,
    searchValue: undefined,
    companyId: undefined,
  });
  const [showAddEditPopup, setShowAddEditPopup] = useState(false);
  const [showAssignEmployeeMessagePopup, setShowAssignEmployeeMessagePopup] =
    useState(false);
  const [projectToEdit, setProjectToEdit] = useState<ProjectDetailsDto>();
  const [addedProject, setAddedProject] = useState<ProjectDetailsDto>();
  const [assignEmployeesToProjectData, setAssignEmployeesToProjectData] =
    useState<ProjectDetailsDto>();
  const { t } = useTranslation("project");
  const { t: tCom } = useTranslation("common");
  const { data: paginatedProjects, isPlaceholderData } =
    useGetPaginatedProjects(projectsFetchData);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  useEffect(() => {
    getNextPage();
  }, [
    paginatedProjects,
    isPlaceholderData,
    projectsFetchData.pageNumber,
    queryClient,
  ]);

  function getNextPage() {
    if (paginatedProjects) {
      if (
        !isPlaceholderData &&
        projectsFetchData.pageNumber < paginatedProjects.totalPages
      ) {
        const nextPageFetchData = {
          ...projectsFetchData,
          pageNumber: projectsFetchData.pageNumber + 1,
        };
        queryClient.prefetchQuery({
          queryKey: [queryKeys.projects.paginatedProjects, nextPageFetchData],
          queryFn: () => fetchFunction(nextPageFetchData),
        });
      }
    }
  }

  const getProjects = (data: ProjectsData) => {
    setProjectsFetchData(data);
  };

  const goToPage = (updater: number | ((pageIndex: number) => number)) => {
    if (typeof updater === "number") {
      setProjectsFetchData((prev) => {
        return { ...prev, pageNumber: updater };
      });
      document.getElementById("content")?.scrollTo(0, 0);
      document.getElementById("layout")?.scrollTo(0, 0);
    }
  };

  function addProject() {
    setProjectToEdit(undefined);
    setShowAddEditPopup(true);
  }

  function handleEditProject(project: ProjectDetailsDto) {
    setProjectToEdit(project);
    setShowAddEditPopup(true);
  }

  function handleGoToEmployeeList(project: ProjectDetailsDto) {
    navigate("/projects/" + project.id, { state: project.companyId });
  }

  function handleAssignEmployeesToProject(project: ProjectDetailsDto) {
    setAssignEmployeesToProjectData(project);
  }

  function closePopup(
    showMessageAboutAssignEmployees: boolean,
    project?: ProjectDetailsDto
  ) {
    setProjectToEdit(undefined);
    setShowAddEditPopup(false);

    setAddedProject(project);
    setShowAssignEmployeeMessagePopup(showMessageAboutAssignEmployees);
  }

  return (
    <div className={style.container}>
      <BreadcrumbsItem to={"/projects"}>{tCom("projects")}</BreadcrumbsItem>
      <FiltersHeader
        getData={getProjects}
        dataToFetch={projectsFetchData}
        buttonData={{
          icon: <FontAwesomeIcon icon={faAdd} />,
          name: t("add-project"),
          onClick: () => addProject(),
        }}
        inputPlaceholderText={t("search-project")}
      />
      {paginatedProjects && (
        <>
          <ProjectsTable
            projects={paginatedProjects?.projects}
            setProjectToEdit={handleEditProject}
            goToEmployeeList={handleGoToEmployeeList}
            setProjectToAssignEmployees={handleAssignEmployeesToProject}
          />
          <TablePagination
            goToPage={goToPage}
            canPreviousPage={projectsFetchData.pageNumber > 1}
            canNextPage={
              projectsFetchData.pageNumber < paginatedProjects.totalPages
            }
            previousPage={() => goToPage(projectsFetchData.pageNumber - 1)}
            nextPage={() => goToPage(projectsFetchData.pageNumber + 1)}
            pageCount={paginatedProjects.totalPages}
            rowsAmount={paginatedProjects.totalItemsCount}
            pageSize={projectsPaginationData.pageSize}
            pageIndex={projectsFetchData.pageNumber}
          />
        </>
      )}
      <Popup
        showPopup={showAddEditPopup}
        closePopup={() => closePopup(false)}
        containerClassName={style.popup}
      >
        <Project projectToEdit={projectToEdit} closePopup={closePopup} />
      </Popup>
      <Popup
        showPopup={showAssignEmployeeMessagePopup}
        closePopup={() => setShowAssignEmployeeMessagePopup(false)}
      >
        <AssignEmployeeMessage
          projectDetails={addedProject}
          closePopup={() => setShowAssignEmployeeMessagePopup(false)}
          onAssignUsersClick={(projectId, companyId) => {
            navigate("/projects/" + projectId, { state: companyId });
          }}
        />
      </Popup>
      {assignEmployeesToProjectData && (
        <Popup
          enableSpinner={false}
          containerClassName={style.popup}
          showPopup={!!assignEmployeesToProjectData}
          closePopup={() => setAssignEmployeesToProjectData(undefined)}
        >
          <AssignUsersToProject
            project={assignEmployeesToProjectData}
            companyId={assignEmployeesToProjectData.companyId}
            closePopup={() => setAssignEmployeesToProjectData(undefined)}
          />
        </Popup>
      )}
    </div>
  );
}

export default Projects;
