import React from "react";
import style from "./assignEmployeeMessage.module.scss";
import { ProjectDetailsDto } from "../../../app/api/api-types";
import { useTranslation } from "react-i18next";
import ThirdButton from "../../../reusable-components/third-button/ThirdButton";

interface IProps {
  closePopup: () => void;
  onAssignUsersClick: (projectId: string, companyId: string) => void;
  projectDetails?: ProjectDetailsDto;
}

function AssignEmployeeMessage({ closePopup, onAssignUsersClick, projectDetails }: IProps) {
  const { t } = useTranslation("project");

  return (
    <div className={style.container}>
      <p className={style.title}>{t("project-added-popup-title") + " " + projectDetails?.title}</p>
      <div className={style.message}>
        {t("project-added-popup-message")}
      </div>
      <div className={style.button_container}>
        <ThirdButton style={style.cancel_button} name={t("no")} onClick={() => closePopup()} />
        <ThirdButton style={style.accept_button}
                     name={t("yes")}
                     onClick={() => projectDetails && onAssignUsersClick(projectDetails.id,
                       projectDetails.companyId)} />
      </div>
    </div>
  );
}

export default AssignEmployeeMessage;
