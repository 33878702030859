import { TFunction } from "i18next";
import * as yup from "yup";
import { ValidationError } from "yup";
import { CompaniesApiFactory } from "../api/api-types";
import { Assign, ObjectShape, TypeOfShape } from "yup/es/object";
import { RequiredStringSchema } from "yup/es/string";

function getTranslateKey(error: string) {
  let tKey = "";
  for (let i = 0; i < error.length; i++) {
    if (i === 0) {
      tKey += error[i].toLowerCase();
      continue;
    }
    if (error[i] !== error[i].toUpperCase()) {
      tKey += error[i];
      continue;
    }

    tKey += "-";
    tKey += error[i].toLowerCase();
  }
  return tKey;
}

export const createCompanySchema = (t: TFunction) => {
  async function checkIfCompanyExists({ validatedCompany }: {
    validatedCompany: Company;
  }): Promise<true | yup.ValidationError> {
    let errors: string[] = [];
    if (validatedCompany.name && validatedCompany.shortName) {
      errors = await CompaniesApiFactory()
        .checkIfCompanyExists(validatedCompany.name,
          validatedCompany.shortName,
          validatedCompany.id)
        .then(res => res.data);
    }

    if (errors.length > 0) {
      const validationErrors: ValidationError[] = [];
      errors.forEach(error => {
        validationErrors.push(new ValidationError(t(`company-${getTranslateKey(error)}-exists`),
          "",
          error.charAt(0).toLowerCase() + error.slice(1)));
      });
      return new ValidationError(validationErrors);
    }
    return true;
  }

  return yup.object()
    .shape({
      id: yup.string(),
      name: yup.string().required(t("required")),
      shortName: yup.string().required(t("required")),
    })
    .test(async (company) => {
      return await checkIfCompanyExists({ validatedCompany: company });
    });
};

export interface CompanyValidation {
  id?: string;
  name: string;
  shortName: string;
}

export const createCompanyValidationErrorMessages: CompanyValidation = {
  id: "",
  name: "",
  shortName: "",
};

type Company = TypeOfShape<Assign<ObjectShape, {
  id: RequiredStringSchema<string | undefined>,
  name: RequiredStringSchema<string | undefined>,
  shortName: RequiredStringSchema<string | undefined>
}>>
