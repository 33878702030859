import { Navigate, useLocation } from "react-router-dom";
import authService from "../../app/services/authService";
import { ReactNode } from "react";

interface IProps {
  children: ReactNode;
  redirectTo: string;
}

const PrivateRoute = ({ children, redirectTo }: IProps) => {
  const location = useLocation();
  return authService.isTokenValid() ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectTo} state={location} />
  );
};

export default PrivateRoute;
