import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateUserByAdminCommand,
  UsersApiFactory,
} from "../../../api/api-types";
import { toast } from "react-hot-toast";
import { t } from "i18next";
import { queryKeys } from "../../../../constants/queryKeys";

export default function UseCreateUserByAdmin() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateUserByAdminCommand) =>
      UsersApiFactory()
        .createUserByAdmin(data)
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.paginatedUsers],
      });
    },
    onError: () => {
      toast.error(t("users:create-user-error"));
    },
  });
}
