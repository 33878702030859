import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UsersApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => UsersApiFactory().deleteUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.paginatedUsers],
      });
    },
  });
}
