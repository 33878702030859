import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../constants/queryKeys";
import { UsersApiFactory } from "../../../api/api-types";
import { stringEmpty } from "../../../../constants/emptyValues";

const fetchFunction = (userId: string) => {
  return UsersApiFactory()
    .getUserSettings(userId)
    .then((res) => res.data);
};

export default function useGetUserSettings(userId: string) {
  return useQuery({
    queryKey: [queryKeys.users.employeeSettings],
    queryFn: () => fetchFunction(userId),
    enabled: userId !== stringEmpty,
    refetchOnWindowFocus: false,
  });
}