import { Navigate, Outlet } from "react-router-dom";

interface IProp {
  permission: boolean;
}

const PermissionRoute = ({ permission }: IProp) => {
  return permission ? <Outlet /> : <Navigate to={"/"}></Navigate>;
};
export default PermissionRoute;
