import style from "./threeDotsPanel.module.scss";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";

export interface PanelOption {
  Icon: JSX.Element;
  text: string;
  onClick: () => void;
}

interface IProps {
  panelOptions: PanelOption[];
  setShowPanel: Dispatch<SetStateAction<boolean>>;
  classNameWithPosition: string;
  onClickOutside: () => void;
  mouseClick: boolean;
  setMouseClick: Dispatch<SetStateAction<boolean>>;
}

const ThreeDotsPanel = ({
  panelOptions,
  setShowPanel,
  classNameWithPosition,
  onClickOutside,
  mouseClick,
  setMouseClick,
}: IProps) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const markOption = useRef(0);

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (!mouseClick) {
      const selectedOption = panelRef.current?.children[
        markOption.current
        ] as HTMLElement;
      selectedOption.focus();
    }
    setMouseClick(false);
  }, []);

  const navigateMenu = (
    e: React.KeyboardEvent<HTMLDivElement>,
    optionIndex: number
  ) => {
    switch (e.key) {
      case "Enter":
        panelOptions[optionIndex].onClick();
        panelRef.current?.parentElement?.focus();
        break;
      case "ArrowDown": {
        e.preventDefault();
        markOption.current < panelOptions.length - 1 && markOption.current++;
        const selectedOption = panelRef.current?.children[
          markOption.current
          ] as HTMLElement;
        selectedOption.focus();
        break;
      }

      case "ArrowUp": {
        e.preventDefault();
        markOption.current > 0 && markOption.current--;
        const selectedOption = panelRef.current?.children[
          markOption.current
          ] as HTMLElement;
        selectedOption.focus();
        break;
      }

      case "Tab":
        e.preventDefault();
        return;
    }
  };

  const options = panelOptions.map((option, index) => (
    <div
      tabIndex={0}
      key={index}
      className={style.item}
      onClick={() => {
        option.onClick();
        setShowPanel(false);
      }}
      onKeyDown={(e) => navigateMenu(e, index)}
    >
      <div className={style.icon}>{option.Icon}</div>
      <div>{option.text}</div>
    </div>
  ));

  return (
    <div ref={panelRef} className={`${style.tools} ${classNameWithPosition}`}>
      {options}
    </div>
  );
};
export default ThreeDotsPanel;
