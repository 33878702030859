import style from "./secondaryButton.module.scss";
import { ReactNode } from "react";

interface IProps {
  name?: string;
  style?: string;
  onClick?: () => void;
  icon?: ReactNode;
  active?: boolean;
  disabled?: boolean;
}

const SecondaryButton = (prop: IProps) => {
  return (
    <button
      className={`${style.secondary_button} ${prop.style} ${prop.active ? style.active : ""}`}
      onClick={prop.onClick}
      disabled={prop.disabled}
    >
      {prop.icon}
      {prop.name}
    </button>
  );
};

export default SecondaryButton;
