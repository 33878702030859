import React, { useEffect, useRef, useState } from "react";
import style from "./reports.module.scss";
import FiltersHeader from "./FiltersHeader";
import {
  DayDetails,
  PaginatedReportByEmployeeDto,
  ReportByEmployeeDto,
} from "../../app/api/api-types";
import { DataPerDay, Group, ReportData } from "../../app/models/Reports";
import { fetchFunction as fetchReport } from "../../app/hooks/Reports/queries/useGetReport";
import GroupReport from "../../reusable-components/group-report/GroupReport";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { formatTimeToDurations } from "../../app/utilities/timeUtilites";
import TablePagination from "../../reusable-components/table-pagination/TablePagination";
import { reportPaginationData } from "../../constants/paginationsData";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetReportCSVFile from "../../app/hooks/Reports/queries/useGetReportCSVFile";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Popup from "../../reusable-components/popup/Popup";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";
import EditTimeEntry from "./edit-time-entry/EditTimeEntry";

interface TimeEntryFetchData {
  employeeId: string;
  employeeName: string;
  day: string;
}

function Reports() {
  const [report, setReport] = useState<PaginatedReportByEmployeeDto>();
  const [pageIndex, setPageIndex] = useState(1);
  const reportFetchData = useRef<ReportData>({
    startTime: dayjs().subtract(1, "months").startOf("month"),
    endTime: dayjs().subtract(1, "months").endOf("month"),
    pageNumber: 1,
    pageSize: reportPaginationData.pageSize,
    searchValue: undefined,
    companyId: undefined,
    status: undefined,
  });
  const [timeEntryFetchData, setTimeEntryFetchData] =
    useState<TimeEntryFetchData>();
  const { mutate: getReportCSV } = useGetReportCSVFile(reportFetchData.current);
  const queryClient = useQueryClient();
  const { t } = useTranslation("reports");
  const { t: tTracker } = useTranslation("time-tracker");
  const { t: tCom } = useTranslation("common");
  const { t: tUser } = useTranslation("users");

  useEffect(() => {
    getReport(reportFetchData.current);
  }, []);

  const getReport = async (data: ReportData) => {
    reportFetchData.current = data;
    if (dayjs(data.endTime).diff(dayjs(data.startTime)) > 0) {
      const fetchedReport = await fetchReport(data);
      setReport(fetchedReport);
      setPageIndex(data.pageNumber);
    }
  };

  function getDataPerDay(dayDetails: DayDetails[] | undefined | null) {
    if (
      !dayDetails ||
      getReportPeriodAsDays(
        reportFetchData.current.startTime,
        reportFetchData.current.endTime
      ) > reportFetchData.current.startTime.daysInMonth()
    ) {
      return [];
    }
    const days = [] as dayjs.Dayjs[];
    for (
      let i = 0;
      i <
      getReportPeriodAsDays(
        reportFetchData.current.startTime,
        reportFetchData.current.endTime
      );
      i++
    ) {
      days.push(
        dayjs(reportFetchData.current.startTime.startOf("day")).add(i, "days")
      );
    }
    const dayData = days.map((day) => {
      const daydata = dayDetails.find(
        (dayDetail) =>
          dayjs(dayDetail.date).format("DD MM YYYY") ===
          dayjs(day).format("DD MM YYYY")
      );
      if (!daydata) {
        return {
          day: day.format(),
          workTime: "-",
          isHoliday: day.day() === 0 || day.day() === 6,
        } as DataPerDay;
      } else {
        return {
          day: day.format("YYYY MM DD"),
          workTime: daydata.absence
            ? tTracker(`${daydata.absence}-short`)
            : formatTimeToDurations(daydata.trackedTime),
          isAbsence: !!daydata.absence,
          isHoliday:
            dayjs(daydata.date).day() === 0 || dayjs(daydata.date).day() === 6,
          isModified: !!daydata.modifiedByAdminId,
        };
      }
    });
    return dayData;
  }

  const getReportPeriodAsDays = (
    startTime: dayjs.Dayjs,
    endTime: dayjs.Dayjs
  ) => {
    return Math.ceil(dayjs.duration(endTime.diff(startTime)).asDays());
  };

  function getGroupName() {
    switch (true) {
      case getReportPeriodAsDays(
        reportFetchData.current.startTime,
        reportFetchData.current.endTime
      ) === reportFetchData.current.startTime.daysInMonth() &&
        dayjs().month() - 1 === reportFetchData.current.startTime.month(): {
        return t("last-month");
      }

      case getReportPeriodAsDays(
        reportFetchData.current.startTime,
        reportFetchData.current.endTime
      ) === reportFetchData.current.startTime.daysInMonth() &&
        dayjs().month() === reportFetchData.current.startTime.month(): {
        return t("this-month");
      }

      default: {
        {
          return `${dayjs(reportFetchData.current.startTime).format(
            "DD.MM.YYYY"
          )}- ${dayjs(reportFetchData.current.endTime).format("DD.MM.YYYY")}`;
        }
      }
    }
  }

  function getData(report: ReportByEmployeeDto[] | undefined) {
    if (report) {
      return report.reduce((rep, currentRep) => {
        const dataPerDay = getDataPerDay(currentRep.dayDetails);
        const userReport: Group = {
          groupName: "",
          employeeId: currentRep.employeeId,
          name: currentRep.employeeName,
          data: dataPerDay,
          totalInGroup: formatTimeToDurations(currentRep.total),
          projectName: currentRep.projectName ?? "",
        };
        const duplicatedUser = rep.findIndex(
          (userRep) => userRep.employeeId === currentRep.employeeId
        );
        if (duplicatedUser !== -1) {
          rep[duplicatedUser].name =
            report[duplicatedUser].employeeName +
            " " +
            rep[duplicatedUser].projectName;
          userReport.name = userReport.name + " " + currentRep.projectName;
        }
        rep.push(userReport);
        return rep;
      }, [] as Group[]);
    }
    return [] as Group[];
  }

  function getDays() {
    if (
      getReportPeriodAsDays(
        reportFetchData.current.startTime,
        reportFetchData.current.endTime
      ) > reportFetchData.current.startTime.daysInMonth()
    ) {
      return;
    }
    const days: string[] = [];
    for (
      let i = 0;
      i <
      getReportPeriodAsDays(
        reportFetchData.current.startTime,
        reportFetchData.current.endTime
      );
      i++
    ) {
      days.push(
        dayjs(reportFetchData.current.startTime).add(i, "days").format("DD")
      );
    }
    return days;
  }

  const goToPage = (updater: number | ((pageIndex: number) => number)) => {
    if (typeof updater === "number") {
      setPageIndex(updater);
      reportFetchData.current = {
        ...reportFetchData.current,
        pageNumber: updater,
      };
      const data = { ...reportFetchData.current, pageNumber: updater };
      getReport(data);
      document.getElementById("content")?.scrollTo(0, 0);
      document.getElementById("layout")?.scrollTo(0, 0);
    }
  };

  const handleEditDayData = (
    employeeId: string,
    employeeName: string,
    day: string
  ) => {
    setTimeEntryFetchData({
      employeeId: employeeId,
      employeeName: employeeName,
      day: day,
    });
  };

  const clearFetchData = () => {
    queryClient.removeQueries({
      queryKey: [
        queryKeys.timeTracker.timeEntryByDay,
        timeEntryFetchData?.employeeId,
        timeEntryFetchData?.day
      ],
    });
    setTimeEntryFetchData(undefined);
  };

  return (
    <div className={style.container}>
      <BreadcrumbsItem to={"/reports"}>{tCom("reports")}</BreadcrumbsItem>
      <FiltersHeader
        getData={(data) => getReport(data as ReportData)}
        dataToFetch={reportFetchData.current}
        buttonData={{
          icon: <FontAwesomeIcon icon={faFileArrowDown} />,
          name: t("generate-report"),
          onClick: () => getReportCSV(reportFetchData.current),
        }}
        inputPlaceholderText={tUser("search-user")}
      />
      <GroupReport
        data={getData(report?.reportByEmployee)}
        groupingName={getGroupName()}
        columns={getDays()}
        totalColumnName={t("sum")}
        editTimeEntry={handleEditDayData}
      />

      {report && (
        <TablePagination
          goToPage={goToPage}
          canPreviousPage={pageIndex > 1}
          canNextPage={pageIndex < report.totalPages}
          previousPage={() => goToPage(pageIndex - 1)}
          nextPage={() => goToPage(pageIndex + 1)}
          pageCount={report.totalPages}
          rowsAmount={report.totalItemsCount}
          pageSize={reportPaginationData.pageSize}
          pageIndex={pageIndex}
        />
      )}
      <Popup
        showPopup={!!timeEntryFetchData}
        closePopup={() => clearFetchData()}
      >
        {timeEntryFetchData ? (
          <EditTimeEntry
            employeeId={timeEntryFetchData.employeeId}
            employeeName={timeEntryFetchData.employeeName}
            day={timeEntryFetchData.day}
            closePopup={clearFetchData}
            refetchReport={() => getReport(reportFetchData.current)}
          />
        ) : (
          <></>
        )}
      </Popup>
    </div>
  );
}

export default Reports;
