import React from "react";
import style from "./projectsTable.module.scss";
import { ProjectDetailsDto } from "../../../app/api/api-types";
import { useTranslation } from "react-i18next";
import ProjectsTableRowThreeDotsMenu from "./ProjectsTableRowThreeDotsMenu";

interface IProps {
  projects: ProjectDetailsDto[];
  setProjectToEdit: (project: ProjectDetailsDto) => void;
  goToEmployeeList: (project: ProjectDetailsDto) => void;
  setProjectToAssignEmployees: (project: ProjectDetailsDto) => void;
}

function ProjectsTable({
  projects,
  setProjectToEdit,
  goToEmployeeList,
  setProjectToAssignEmployees
}: IProps) {
  const { t } = useTranslation("project");

  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <div className={style.table_cell}>{t("project")}</div>
        <div className={style.table_cell}>{t("company")}</div>
        <div className={style.table_cell}>{t(
          "number-of-employees")}</div>
        <div className={style.table_cell}></div>
      </div>
      {projects.map((project) => (
        <div key={project.id} className={`${style.project_container}`}>
          <div className={style.table_cell}>{project.title}</div>
          <div className={style.table_cell}>{project.companyName}</div>
          <div className={style.table_cell}>{project.numberOfRelatedUsers}</div>
          <div className={style.table_cell}>
            <ProjectsTableRowThreeDotsMenu
              onEditClick={() => {
                setProjectToEdit(project);
              }}
              onAssignEmployeesClick={() => {
                setProjectToAssignEmployees(project);
              }}
              onGoToEmployeeListClick={() => {
                goToEmployeeList(project);
              }}
            />
          </div>
        </div>
      ))}
    </div>

  );
}

export default ProjectsTable;
