export const reportPaginationData = {
  pageSize: 17,
};
export const usersPaginationData = {
  pageSize: 17,
};
export const companiesPaginationData = {
  pageSize: 17,
};

export const projectsPaginationData = {
  pageSize: 17,
};

export const assignUsersToProjectsPaginationData = {
  pageSize: 13,
};
