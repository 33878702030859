import React, { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import * as MdIcons from "react-icons/md";
import { useTranslation } from "react-i18next";
import TimeComponent from "./time-component/TimeComponent";
import DateComponent from "./date-component/DateComponent";
import Button from "../../reusable-components/button/Button";
import style from "./addWorkTime.module.scss";
import AbsenceType from "./absence-type/AbsenceType";
import useCreateTimeEntry from "../../app/hooks/TimeTracker/commands/useCreateTimeEntry";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../app/context/UserContext";
import PositionFixedContainer from "../../reusable-components/position-fixe-container/PositionFixedContainer";
import useUpdateTimeEntry from "../../app/hooks/TimeTracker/commands/useUpdateTimeEntry";
import { fetchFunction as useGetTimeEntry } from "../../app/hooks/TimeTracker/queries/useGetTimeEntryByDay";
import { toast } from "react-hot-toast";
import { useIsMutating } from "@tanstack/react-query";
import LoadingPage from "../../reusable-components/loading-page/LoadingPage";
import { useDebouncedCallback } from "use-debounce";
import axios from "axios";

function AddWorkTime() {
  const { state } = useLocation();
  const [workDay, setWorkDay] = useState<dayjs.Dayjs>();
  const [endTime, setEndTime] = useState<dayjs.Dayjs>();
  const [absenceId, setAbsenceId] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();
  const [timeEntryId, setTimeEntryId] = useState<string>();
  const [isLoadingTimeEntry, setIsLoadingTimeEntry] = useState(!!state?.day);
  const [isLockedByAdmin, setIsLockedByAdmin] = useState(false);
  const isLoading = useIsMutating();
  const navigate = useNavigate();
  const abortController = useRef(new AbortController());
  const { t } = useTranslation("common");
  const { t: tTracker } = useTranslation("time-tracker");
  const { user } = useContext(UserContext);
  const { mutate: createTimeEntry } = useCreateTimeEntry();
  const { mutate: updateTimeEntry } = useUpdateTimeEntry();
  const setDebouncedIsLoading = useDebouncedCallback(
    (isLoading: boolean) => setIsLoadingTimeEntry(isLoading),
    300
  );

  useEffect(() => {
    checkExistedEntry(state?.day ?? dayjs());
  }, []);
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setContainerHeight(container.current?.offsetHeight);
  }, [container.current?.offsetHeight]);

  const checkExistedEntry = async (date?: string | dayjs.Dayjs) => {
    const workDay = dayjs(date).startOf("day");
    abortController.current = new AbortController();
    setDebouncedIsLoading(true);
    const timeEntry = await useGetTimeEntry(
      user.id,
      workDay.format("YYYY MM DD"),
      abortController.current.signal
    ).catch((e) => {
      if (axios.isCancel(e)) {
        return e.message;
      }
    });
    if (typeof timeEntry === "string") {
      return;
    }
    setDebouncedIsLoading.cancel();
    if (timeEntry) {
      setTimeEntryId(timeEntry.id);
      setWorkDay(dayjs.utc(timeEntry.startTime));
      setEndTime(dayjs.utc(timeEntry.endTime));
      timeEntry.absenceId && setAbsenceId(timeEntry.absenceId);
      setIsLockedByAdmin(timeEntry.isLockedByAdmin);
    } else {
      clearValues(date);
    }
    setIsLoadingTimeEntry(false);
  };

  function clearValues(date: string | dayjs.Dayjs | undefined) {
    setTimeEntryId(undefined);
    setAbsenceId(undefined);
    setWorkDay(dayjs(date).startOf("day"));
    setEndTime(dayjs(date).startOf("day"));
    setIsLockedByAdmin(false);
  }

  const cancel = () => {
    navigate("/", { state: { option: state?.option } });
  };

  const saveWorkTime = () => {
    if (workDay && endTime && !isLockedByAdmin) {
      if (!timeEntryId) {
        createTimeEntry(
          {
            userId: user.id,
            startTime: workDay.format("YYYY MM DD HH:mm"),
            endTime: endTime.format("YYYY MM DD HH:mm"),
            absenceId: absenceId,
          },
          {
            onSuccess: () => {
              navigate("/", { state: { option: state?.option } });
            },
          }
        );
      } else {
        updateTimeEntry(
          {
            userId: user.id,
            timeEntryId: timeEntryId,
            startTime: workDay.format("YYYY MM DD HH:mm"),
            endTime: endTime.format("YYYY MM DD HH:mm"),
            absenceId: absenceId,
          },
          {
            onSuccess: () => {
              toast.success(tTracker("updated-time-entry"));
              navigate("/", { state: { option: state?.option } });
            },
          }
        );
      }
    }
  };

  const setAbsenceType = (absenceId: number) => {
    setEndTime(workDay?.startOf("day"));
    setAbsenceId(absenceId);
  };

  const changeDay = async (day: dayjs.Dayjs) => {
    setDebouncedIsLoading.cancel();
    await abortController.current.abort();
    checkExistedEntry(day);
  };

  return (
    <>
      <div className={style.container}>
        <DateComponent workDay={workDay} setWorkDay={changeDay} />
        <TimeComponent
          workDay={workDay}
          endTime={endTime ?? dayjs().startOf("day")}
          setEndTime={(time) => {
            setEndTime(time);
            setAbsenceId(undefined);
          }}
          isLockedByAdmin={isLockedByAdmin}
        />
        <AbsenceType
          setAbsence={setAbsenceType}
          chosenAbsenceId={absenceId}
          isLockedByAdmin={isLockedByAdmin}
        />
        <div style={{ height: containerHeight }} />
        <PositionFixedContainer>
          <div className={style.buttons_container}>
            <Button
              style={style.button_cancel}
              icon={<MdIcons.MdClose size={"30px"} />}
              name={t("cancel")}
              disabled={!!isLoading}
              onClick={cancel}
            />
            <Button
              style={`${style.button_text} ${
                isLockedByAdmin ? style.disabled : ""
              }`}
              icon={<MdIcons.MdDone />}
              name={t("save")}
              disabled={!!isLoading}
              onClick={() =>
                isLockedByAdmin
                  ? toast.error(tTracker("time-entry-locked-by-admin"))
                  : saveWorkTime()
              }
            />
          </div>
        </PositionFixedContainer>
      </div>
      <LoadingPage isActive={isLoadingTimeEntry} />
    </>
  );
}

export default AddWorkTime;
