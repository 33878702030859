import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxArchive,
  faBriefcase,
  faClipboard,
  faGear,
  faUser
} from "@fortawesome/free-solid-svg-icons";

export const NavOptions = [
  {
    label: "users",
    path: "/users",
    icon: <FontAwesomeIcon icon={faUser} />
  },
  {
    label: "reports",
    path: "/reports",
    icon: <FontAwesomeIcon icon={faClipboard} />
  },
  {
    label: "projects",
    path: "/projects",
    icon: <FontAwesomeIcon icon={faBriefcase} />
  },
  {
    label: "companies",
    path: "/companies",
    icon: <FontAwesomeIcon icon={faBoxArchive} />
  },
  {
    label: "settings",
    path: "/settings",
    icon: <FontAwesomeIcon icon={faGear} />
  },
];
