import React from "react";
import style from "./companiesTable.module.scss";
import { CompanyDetailsDto } from "../../../app/api/api-types";
import { useTranslation } from "react-i18next";
import CompaniesTableRowThreeDotsMenu from "./CompaniesTableRowThreeDotsMenu";

interface IProps {
  companies: CompanyDetailsDto[];
  editCompany: (company: CompanyDetailsDto) => void;
}

function CompaniesTable({ companies, editCompany }: IProps) {
  const { t } = useTranslation("companies");
  return (
    <div className={style.container}>
      <div className={style.header_container}>
        <div className={style.table_cell}>{t("company")}</div>
        <div className={style.table_cell}>{t("company-short-name")}</div>
        <div className={style.table_cell}>{t("projects-count")}</div>
        <div className={style.table_cell}>{t("employees-count")}</div>
        <div className={style.table_cell}></div>
      </div>
      {companies.map((company) =>
        <div key={company.id} className={style.company_container}>
          <div className={style.table_cell}>{company.name}</div>
          <div className={style.table_cell}>{company.shortName}</div>
          <div className={style.table_cell}>{company.projectsCount}</div>
          <div className={style.table_cell}>{company.usersCount}</div>
          <div className={style.table_cell}><CompaniesTableRowThreeDotsMenu
            onEditClick={() => {
              editCompany(company);
            }}
          /></div>
        </div>)}
    </div>
  );
}

export default CompaniesTable;
