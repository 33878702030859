import React, { ReactChild, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import style from "./positionFixedContainer.module.scss";

interface IProps {
  children: ReactChild;
}

function PositionFixedContainer({ children }: IProps) {
  const [containerHeight, setContainerHeight] = useState(0);
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container.current?.offsetHeight) {
      setContainerHeight(container.current.offsetHeight);
    }
  }, [container.current?.offsetHeight]);

  const portal = document.getElementById("portal-container");
  if (!portal) {
    return <></>;
  }

  return (
    createPortal(
      <>
        <div className={style.container} style={{ height: `${containerHeight}px` }}>
          <div ref={container}
               className={style.children_container}
               style={{ bottom: "0px" }}>
            {children}
          </div>
        </div>
      </>, portal)
  );
}

export default PositionFixedContainer;
