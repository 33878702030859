import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ProjectsApiFactory,
  UpdateProjectCommand,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useUpdateProject() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (projectToUpdate: UpdateProjectCommand) =>
      ProjectsApiFactory()
        .updateProject(projectToUpdate.id, projectToUpdate)
        .then((res) => res.data),
    onSuccess: () => {
  queryClient.invalidateQueries({ queryKey: [queryKeys.projects.paginatedProjects] });
  // todo add correct query key
},
  });
}
