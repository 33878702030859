const timeTracker = {
  lockTimeEntrySetting: "lock-time-entries-before",
  defaultWorkTime: "default-work-time",
  timeEntryChangeValue: "time-change-value"
};

const timeTrackerChangeValues = {
  timeEntryLockTimeChangeHours: 24,
  defaultWorkTimeChangeHours: 1,
  timeChangeMinutes: 10,
};

const timeTrackerSettingsMinValues = {
  timeEntryLockTimeHours: 24,
  timeEntryChangeValueMinutes: 10,
  timeEntryDefaultWorkTimeHours: 1,
};

export const settings = {
  timeTracker: timeTracker,
  timeTrackerChangeValues: timeTrackerChangeValues,
  timeTrackerSettingsMinValues: timeTrackerSettingsMinValues
};
