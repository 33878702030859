import React from "react";
import style from "./datePickerInput.module.scss";

interface IProps {
  setShowPopup: () => void;
  text?: string;
  icon?: JSX.Element;
  disabled?: boolean;
}

function DatePickerInput({ text, icon, setShowPopup, disabled }: IProps) {
  return (
    <div onClick={() => !disabled && setShowPopup()}
         className={`${style.container} ${disabled ? style.disabled : ""}`}>
      <p>{text}</p>
      {icon}
    </div>
  );
}

export default DatePickerInput;
