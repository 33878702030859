import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CompaniesApiFactory,
  CreateCompanyCommand,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useCreateCompany() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateCompanyCommand) =>
      CompaniesApiFactory()
        .createCompany(data)
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.companies.paginatedCompanies],
      });
    },
  });
}
