import ThreeDotsMenu from "../../../reusable-components/three-dots-menu/ThreeDotsMenu";
import { PanelOption } from "../../../reusable-components/three-dots-panel/ThreeDotsPanel";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faUser } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onEditClick: () => void;

  onGoToEmployeeListClick: () => void;

  onAssignEmployeesClick: () => void;
}

const ProjectsTableRowThreeDotsMenu =
  ({
    onEditClick,
    onGoToEmployeeListClick,
    onAssignEmployeesClick,
  }: IProps) => {
    const { t: tCommon } = useTranslation("project");

    const editOption: PanelOption = {
      text: tCommon("edit"),
      onClick: () => onEditClick(),
      Icon: <FontAwesomeIcon icon={faPen} />,
    };

    const employeeListOption: PanelOption = {
      text: tCommon("list-of-employees"),
      onClick: () => onGoToEmployeeListClick(),
      Icon: <FontAwesomeIcon icon={faUser} />,
    };

    const assignEmployeeOption: PanelOption = {
      text: tCommon("add-employees"),
      onClick: () => onAssignEmployeesClick(),
      Icon: <FontAwesomeIcon icon={faPlus} />,
    };

    const panelOptions = [editOption, employeeListOption, assignEmployeeOption];

    return (
      <ThreeDotsMenu panelOptions={panelOptions}
                     direction={"bottom_left"}
      />
    );
  };

export default ProjectsTableRowThreeDotsMenu;
