import React from "react";
import style from "./twoLinesTitle.module.scss";

interface IProps {
  title: string;
}

function TwoLinesTitle({ title }: IProps) {
  return <div className={style.container}>
    <div className={style.line} />
    <p className={style.fill_data}>{title}</p>
    <div className={style.line} />
  </div>;
}

export default TwoLinesTitle;
