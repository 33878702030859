import { UserContext } from "../../app/context/UserContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./navbar.module.scss";
import * as FaIcons from "react-icons/fa";
import { NavOptions } from "./NavOptions";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faCircleInfo,
  faPowerOff
} from "@fortawesome/free-solid-svg-icons";

interface IProps {
  isExpanded: boolean;
  setShowInstructions: () => void;
}

const Navbar = ({ isExpanded, setShowInstructions }: IProps) => {
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);
  const [t] = useTranslation("common");
  const navigation = useRef<HTMLUListElement>(null);
  const bars = useRef<HTMLDivElement>(null);
  const { logout, user, hasUserEmployeeRole } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    let filter: HTMLDivElement | undefined;
    if (!document.getElementById(style.filter)) {
      filter = document.createElement("div");
      filter.id = style.filter;
    }
    if (showUserMenu) {
      addFilterToLayout(filter);
    } else {
      removeFilter(document.getElementById(style.filter));
    }
    return () => removeFilter(document.getElementById(style.filter));
  }, [showUserMenu]);

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => document.removeEventListener("click", closeMenu);
  }, []);

  function closeMenu(e: MouseEvent) {
    if (e.target instanceof Node && bars.current?.contains(e.target)) {
      return;
    }
    if (e.target instanceof Node && !navigation.current?.contains(e.target)) {
      setShowUserMenu(false);
    }
  }

  function onLogout() {
    logout();
  }

  function removeFilter(filter?: HTMLElement | null) {
    if (filter) {
      filter.remove();
    }
  }

  const addFilterToLayout = (filter?: HTMLElement | null) => {
    const layoutContainer = document.getElementById("layout-content");
    if (layoutContainer && filter) {
      layoutContainer.before(filter);
    }
  };

  return (
    <>
      <div className={style.container}>
        <div ref={bars}>
          <FaIcons.FaBars className={style.bars}
                          size={"24px"}
                          onClick={() => setShowUserMenu(prev => !prev)} />
        </div>
        <ul ref={navigation}
            className={`${style.navigation} ${showUserMenu ? style.active : ""} ${isExpanded ? style.expand : ""}`}>
          {hasUserEmployeeRole() && <li className={style.email}>
            <p> {user.email}</p>
            <div className={style.line} />
          </li>}
          {NavOptions.filter(option => user.hasPermissionTo(option.label)).map((option) =>
            <li className={style.navigation_item} key={option.path}
                onClick={() => setShowUserMenu(prev => !prev)}>
              <NavLink to={option.path}
                       className={`${(location.pathname === option.path || location.pathname === "/" && option.path === "/users" && user.hasPermissionTo(
                         "users")) ? style.active : ""}`}>
                <div>{option.icon}</div>
                <p>{t(option.label)}</p></NavLink>
            </li>)}
          {hasUserEmployeeRole() &&
            <li className={style.instructions}
                onClick={setShowInstructions}>
              <div>
                <FontAwesomeIcon icon={faCircleInfo}
                                 className={style.log_out} /></div>
              <p>{t("instruction")}</p>
            </li>}
          < li className={`${style.navigation_item} ${style.log_out}`} onClick={onLogout}>
            <NavLink to={"/"}>
              <div>{hasUserEmployeeRole()
                ? <FontAwesomeIcon icon={faArrowRightFromBracket}
                                   className={style.log_out} />
                : <FontAwesomeIcon icon={faPowerOff}
                                   className={style.log_out} />} </div>
              <p className={hasUserEmployeeRole() ? style.employee_logout : ""}>{t(
                "sign-out")}</p></NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
