import { createContext, ReactNode, useContext } from "react";
import { TimeTrackerSettingsDto } from "../api/api-types";
import useGetTimeTrackerSettings from "../hooks/Settings/queries/useGetTimeTrackerSettings";
import dayjs from "dayjs";
import useSetSetting from "../hooks/Settings/commands/useSetSetting";
import { settings } from "../../constants/Settings";

interface Settings {
  timeEntrySettings: TimeTrackerSettingsDto;
  timeChangeValue: number;
  defaultWorkTimes: number[];
  timeEntryLockTime: number;
  isLoading: boolean;
  isTimeEntryLocked: (timeEntryDate: string | dayjs.Dayjs) => boolean;
  addTime: (settingTitle: keyof TimeTrackerSettingsDto, value: number, index?: number) => void;
  subtractTime: (settingTitle: keyof TimeTrackerSettingsDto, value: number, index?: number) => void;
}

interface IProps {
  children: ReactNode;
}

interface ActionType {
  add: string,
  subtract: string,
}

export const SettingsContext = createContext({} as Settings);

export const SettingsContextProvider = ({ children }: IProps) => {
    const {
      data: timeTrackerSettings,
      isLoading
    } = useGetTimeTrackerSettings(
      "timeTrackerSettings");
    const { mutate: setSetting } = useSetSetting();

    const isTimeEntryLocked = (timeEntryDate: string | dayjs.Dayjs) => {
      if (!timeTrackerSettings?.lockTimeEntrySetting?.timeEntryLockTime) {
        return false;
      }

      return dayjs().startOf("days")
        .diff(timeEntryDate) > timeTrackerSettings?.lockTimeEntrySetting.timeEntryLockTime;
    };
    const convertMillisecondsToMinutes = (time?: number) => {
      return time ? dayjs.duration(time, "milliseconds").asMinutes() : 0;
    };

    const convertArrayOfMillisecondsToArrayOfHours = (time?: number[]) => {
      return time ? time.map(time => dayjs.duration(time, "milliseconds").asHours()) : [0];
    };

    const getValue = (settingTitle: keyof TimeTrackerSettingsDto, value: number,
      actionType: keyof ActionType, index?: number) => {
      if (timeTrackerSettings) {
        switch (settingTitle) {
          case "lockTimeEntrySetting": {
            if (timeTrackerSettings.lockTimeEntrySetting?.timeEntryLockTime !== undefined) {
              if (actionType === "add") {
                return dayjs.duration(timeTrackerSettings?.lockTimeEntrySetting?.timeEntryLockTime,
                  "milliseconds").add(value, "hours").asMilliseconds();
              } else if (actionType === "subtract") {
                return dayjs.duration(timeTrackerSettings?.lockTimeEntrySetting?.timeEntryLockTime,
                  "milliseconds").subtract(value, "hours").asMilliseconds();
              }
            }
            return;
          }

          case "timeEntryChangeValue": {
            if (timeTrackerSettings.timeEntryChangeValue?.timeChangeValue !== undefined) {
              if (actionType === "add") {
                return dayjs.duration(timeTrackerSettings?.timeEntryChangeValue.timeChangeValue,
                  "milliseconds").add(value, "minutes").asMilliseconds();
              } else if (actionType === "subtract") {
                return dayjs.duration(timeTrackerSettings?.timeEntryChangeValue.timeChangeValue,
                  "milliseconds").subtract(value, "minutes").asMilliseconds();
              }
            }
            return;
          }
          case "defaultWorkTime": {
            if (timeTrackerSettings.defaultWorkTime?.defaultWorkTimes !== undefined && index !== undefined) {
              if (actionType === "add") {
                const values = timeTrackerSettings.defaultWorkTime?.defaultWorkTimes;
                values[index] = dayjs.duration(timeTrackerSettings?.defaultWorkTime.defaultWorkTimes[index],
                  "milliseconds").add(value, "hours").asMilliseconds();
                return JSON.stringify(values);
              } else if (actionType === "subtract") {
                const values = timeTrackerSettings.defaultWorkTime?.defaultWorkTimes;
                values[index] = dayjs.duration(timeTrackerSettings?.defaultWorkTime.defaultWorkTimes[index],
                  "milliseconds").subtract(value, "hours").asMilliseconds();
                return JSON.stringify(values);
              }
            }
            return;
          }

          default:
            return;
        }
      }
    };
    const addTime = (settingTitle: keyof TimeTrackerSettingsDto, value: number, index?: number) => {
      const newValue = getValue(settingTitle, value, "add", index);
      if (newValue) {
        setSetting({
          settingTitleKey: settings.timeTracker[settingTitle],
          value: newValue.toString()
        });
      }
    };
    const subtractTime = (settingTitle: keyof TimeTrackerSettingsDto, value: number,
      index?: number) => {
      const newValue = getValue(settingTitle, value, "subtract", index);
      if (newValue) {
        setSetting({
          settingTitleKey: settings.timeTracker[settingTitle],
          value: newValue.toString()
        });
      }
    };

    return (<SettingsContext.Provider value={{
      timeChangeValue: convertMillisecondsToMinutes(timeTrackerSettings?.timeEntryChangeValue?.timeChangeValue),
      defaultWorkTimes: convertArrayOfMillisecondsToArrayOfHours(timeTrackerSettings?.defaultWorkTime?.defaultWorkTimes),
      timeEntrySettings: timeTrackerSettings ?? {} as TimeTrackerSettingsDto,
      isLoading,
      isTimeEntryLocked,
      timeEntryLockTime: timeTrackerSettings?.lockTimeEntrySetting?.timeEntryLockTime ?? 0,
      addTime: addTime,
      subtractTime: subtractTime
    }}>
      {children}
    </SettingsContext.Provider>);
  }
;

export const useSettingsContext = (): Settings => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error(
      "SettingsContext compound components cannot be rendered outside the SettingsContext component"
    );
  }
  return context;
};
