import ThreeDotsMenu from "../../../reusable-components/three-dots-menu/ThreeDotsMenu";
import { Dispatch, SetStateAction } from "react";
import { PanelOption } from "../../../reusable-components/three-dots-panel/ThreeDotsPanel";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onEditClick: () => void;
  setShowWarning: Dispatch<SetStateAction<boolean>>;
}

const UsersTableRowThreeDotsMenu =
  ({
    onEditClick,
    setShowWarning,
  }: IProps) => {
    const { t: tCommon } = useTranslation("common");

    const editOption: PanelOption = {
      text: tCommon("edit"),
      onClick: () => onEditClick(),
      Icon: <FontAwesomeIcon icon={faPen} />,
    };

    const deleteOption: PanelOption = {
      text: tCommon("delete"),
      onClick: () => setShowWarning(true),
      Icon: <FontAwesomeIcon icon={faTrashCan} />,
    };

    const panelOptions = [editOption, deleteOption];

    return (
      <ThreeDotsMenu panelOptions={panelOptions}
                     direction={"bottom_left"} />
    );
  };

export default UsersTableRowThreeDotsMenu;
