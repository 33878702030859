import { STORAGE_KEY } from "../../constants/browserStorageConsts";

const getAccessToken = () => {
  return (
    localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)
    || sessionStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)
  );
};

const getRefreshToken = () => {
  return (
    localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN)
    || sessionStorage.getItem(STORAGE_KEY.REFRESH_TOKEN)
  );
};

const removeAuthTokens = () => {
  const tokens = [STORAGE_KEY.ACCESS_TOKEN, STORAGE_KEY.REFRESH_TOKEN];
  tokens.forEach((tokenKey) => {
    localStorage.removeItem(tokenKey);
    sessionStorage.removeItem(tokenKey);
  });
};

const configureAfterLogin = (
  accessToken: string,
  refreshToken: string,
  shouldRememberUser: boolean,
) => {
  localStorage.setItem(STORAGE_KEY.KEEP_USER, String(shouldRememberUser));

  setAuthTokens(accessToken, refreshToken);
};

const setAuthTokens = (accessToken: string, refreshToken: string) => {
  const shouldRememberUser = getShouldRememberUser();

  browserStorageService.removeAuthTokens();

  if (shouldRememberUser) {
    localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, refreshToken);
    localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
  } else {
    sessionStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, refreshToken);
    sessionStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
  }
};

const getShouldRememberUser = () => {
  const shouldRememberUserRaw = localStorage.getItem(STORAGE_KEY.KEEP_USER);
  return shouldRememberUserRaw === "true";
};
const setShouldRememberUser = (keepUserLogin: boolean) => {
  localStorage.setItem(STORAGE_KEY.KEEP_USER, String(keepUserLogin));
};

const getIsRefreshingToken = () => {
  const isRefreshingRaw = sessionStorage.getItem(STORAGE_KEY.IS_REFRESHING);
  return isRefreshingRaw === "true";
};

const clearStorages = () => {
  sessionStorage.clear();
};

const browserStorageService = {
  getAccessToken,
  getRefreshToken,
  removeAuthTokens,
  setAuthTokens,
  clearStorages,
  getShouldRememberUser,
  getIsRefreshingToken,
  configureAfterLogin,
  setShouldRememberUser,
};

export default browserStorageService;
