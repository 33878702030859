import Navbar from "../nav/Navbar";
import style from "./layout.module.scss";
import { ReactNode, useContext, useState } from "react";
import Logo from "../../reusable-components/logo/Logo";
import { useTranslation } from "react-i18next";
import Switch from "../../reusable-components/switch/Switch";
import languages from "../../constants/languages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import LayoutHeader from "./LayoutHeader";
import { UserContext } from "../../app/context/UserContext";
import Consents from "../login/Consents";
import Popup from "../../reusable-components/popup/Popup";

interface IProps {
  children: React.ReactNode;
  breadcrumbs: ReactNode;
}

export default ({ children, breadcrumbs }: IProps) => {
  const { t } = useTranslation("common");
  const { t: tUser } = useTranslation("users");
  const { user, changeLanguage, hasUserEmployeeRole } = useContext(UserContext);
  const [showExpandedSideBar, setShowExpandedSideBar] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const setLanguage = () => {
    if (user.locale === languages.pl) {
      changeLanguage(languages.uk);
    } else {
      changeLanguage(languages.pl);
    }
  };

  function getSwitchState() {
    return user.locale === languages.uk;
  }

  return (
    <>
      {!user.hasGdprConsent && hasUserEmployeeRole() ? (
        <Consents />
      ) : (
        <div id={"layout"} className={style.layout}>
          <div className={style.empty_container} style={{ minHeight: "43px" }} />
          <div className={`${style.container} ${showExpandedSideBar ? style.expanded : ""} ${hasUserEmployeeRole() ? style.employee_navigation : ""}`}>
            <Logo logoClassName={style.logo} />
            <div className={style.wrapper}>
              <div className={style.switch_container}>
                {hasUserEmployeeRole() && <>
                  <p>
                    {t("pl")}
                  </p>
                  <Switch state={getSwitchState()} toggle={() => setLanguage()} />
                  <p> {t("ua")}</p></>}
              </div>
              <div className={style.navigation_container}>
                <Navbar isExpanded={showExpandedSideBar}
                        setShowInstructions={() => setShowInstructions(true)} />
              </div>
              <FontAwesomeIcon className={style.expand_arrow}
                               onClick={() => setShowExpandedSideBar(prev => !prev)}
                               icon={faAnglesRight} />
            </div>
          </div>
          <div id="layout-content"
               className={style.content}>
            <LayoutHeader breadcrumbs={breadcrumbs}
                          setShowInstructions={() => setShowInstructions(true)}
                          toggleSwitch={() => setLanguage()}
                          switchState={getSwitchState()} />
            <div id="content" className={style.content_container}>
              {children}
            </div>
            <div id={"portal-container"} className={style.portal_container}></div>
          </div>
          <Popup showPopup={showInstructions}
                 closePopup={() => setShowInstructions(false)}
                 containerClassName={style.popup}>
            <div className={style.instruction_container}>
              <p>{tUser("instruction")}</p>
              <div className={style.line} />
              <ol className={style.instructions_list}
                  dangerouslySetInnerHTML={{ __html: tUser("instruction-body") }} />
            </div>
          </Popup>
        </div>)}
    </>);
};
