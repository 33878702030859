import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SetSettingCommand, SettingsApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useSetSetting() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SetSettingCommand) =>
      SettingsApiFactory().setSetting(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.settings.timeTrackerSettings],
      });
    },
  });
}
