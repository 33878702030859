import React, { useState } from "react";
import style from "./projectEmployeesHeader.module.scss";
import Button from "../../../reusable-components/button/Button";
import { useTranslation } from "react-i18next";
import "../../../style/datePickerCalendarStyleOverride.scss";
import { UsersData } from "../../../app/models/User";
import { ProjectDto } from "../../../app/api/api-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Input from "../../../reusable-components/input/Input";

interface HeaderButtonData {
  icon: JSX.Element,
  name: string,
  onClick: () => void
}

interface IProps {
  getUsers: (data: UsersData) => void;
  dataToFetch: UsersData;
  buttonData: HeaderButtonData;
  project: ProjectDto;
}

function ProjectEmployeesHeader({ getUsers, dataToFetch, buttonData, project }: IProps) {
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation("project");

  function filterByName(value: string) {
    setSearchValue(value);
    const data = { ...dataToFetch, pageNumber: 1, searchValue: value };
    getUsers(data);
  }

  return (
    <div className={style.container}>
      <div className={style.header_line}>
        <div className={style.project_details}>
          <span>{project.companyName}</span>
          <span className={style.project_name}>{project.title}</span>
        </div>
        <div className={style.button_container}>
          <Button style={style.button} icon={buttonData.icon}
                  name={buttonData.name}
                  onClick={buttonData.onClick} />
        </div>
      </div>
      <div className={style.filters}>
        <Input inputClassName={style.input}
               containerClassName={style.input_container}
               content={searchValue}
               placeholder={t("search-employee")}
               onChange={(e) => filterByName(e.target.value)}>
          <FontAwesomeIcon icon={faSearch} />
        </Input>
      </div>
    </div>
  );
}

export default ProjectEmployeesHeader;
