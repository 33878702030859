import { useMutation } from "@tanstack/react-query";
import { ReportsApiFactory } from "../../../api/api-types";
import { ReportData } from "../../../models/Reports";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const fetchFunction = (data: ReportData): Promise<File> => {
  return ReportsApiFactory()
    .getCSVReport(
      data.startTime.format("YYYY MM DD HH:mm"),
      data.endTime.format("YYYY MM DD HH:mm"),
      data.searchValue,
      data.companyId,
      data.projectId,
      data.status
    )
    .then((res) => res.data as File);
};

// eslint-disable-next-line
function getFile(file: File, data: ReportData) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `report_${data.startTime.format("DD/MM/YYYY")}-${data.endTime.format(
      "DD/MM/YYYY"
    )}.csv`
  );
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export default function useGetReportCSVFile(data: ReportData) {
  const { t } = useTranslation("reports");

  return useMutation<File, Error, ReportData>({
    mutationFn: fetchFunction,
    onSuccess: (res) => {
      getFile(res, data);
    },
    onError: () => {
      toast.error(t("report-generate-error"));
    },
  });
}
