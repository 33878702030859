import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SupportApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useCreateUsersFromBatchFile() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (file: File) =>
      SupportApiFactory()
        .createUsersFromBatchFile(file)
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.paginatedUsers],
      });
    },
  });
}
