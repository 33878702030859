import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const languageDetector = new LanguageDetector(null, {
  order: ["customDetector", "querystring", "navigator"],
  lookupQuerystring: "lng",
});

languageDetector.addDetector({
  name: "customDetector",
  lookup: function() {
    const lng = localStorage.getItem("i18nextLng") ?? window.navigator.language.split("-")[0];
    const detectedLng = lng;
    return detectedLng;
  },
  cacheUserLanguage: (lng) => {
    localStorage.setItem("i18nextLng", lng);
  },
});

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      "common",
      "companies",
      "dropdown",
      "login",
      "password-reset",
      "project",
      "reports",
      "settings",
      "time-formats",
      "time-tracker",
      "users",
      "validation",
    ],
    fallbackLng: ["pl", "uk", "en"],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?v=${process.env.REACT_APP_VERSION}`
    },
  });

export default i18n;
