import { useQuery } from "@tanstack/react-query";
import { UsersData } from "../../../models/User";
import { queryKeys } from "../../../../constants/queryKeys";
import { CompaniesApiFactory } from "../../../api/api-types";

export const fetchFunction = (data: UsersData) => {
  return CompaniesApiFactory()
    .getPaginatedCompanies(data.pageNumber, data.pageSize, data.searchValue)
    .then((res) => res.data);
};

export default function useGetPaginatedCompanies(data: UsersData) {
  const isEnabled = !!data.pageSize && !!data.pageNumber;
  return useQuery({
    queryKey: [queryKeys.companies.paginatedCompanies, data],
    queryFn: () => fetchFunction(data),
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });
}