import style from "./input.module.scss";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as BsIcons from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  children?: React.ReactNode;
  index?: number;
  type?: string;
  name?: string;
  content?: string | null;
  label?: string;
  placeholder?: string;
  containerClassName?: string;
  textColor?: string;
  inputClassName?: string;
  textClassName?: string;
  errorMessage?: string | null;
  borderless?: boolean;
  editMode?: boolean;
  disabled?: boolean;
  isHovering?: boolean;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (value: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  focusOnInput?: boolean;
}

const Input = ({
  children,
  index,
  type,
  name,
  content = "",
  label,
  placeholder,
  containerClassName,
  inputClassName,
  textColor,
  textClassName,
  errorMessage,
  borderless,
  editMode = true,
  disabled,
  isHovering,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onKeyPress,
  focusOnInput,
}: IProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (focusOnInput && input?.current) {
      input.current.focus();
    }
  }, [input, focusOnInput]);

  return (
    editMode ? (
      <div
        className={` ${style.input_container} ${
          disabled ? style.disabled : ""
        } ${containerClassName || ""}`}
      >
        {label && <div className={`${style.label}`}>{label}</div>}
        <div className={style.input_alert_container}>
          <input
            ref={input}
            className={`${style.input} ${errorMessage ? style.error : ""} ${
              type === "password" || children ? style.icon_right : ""
            } ${borderless ? style.borderless : ""} ${isHovering ? style.hovering : ""}
                ${inputClassName ? inputClassName : ""}`}
            type={showPassword ? "text" : type}
            name={name}
            value={content ?? undefined}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            data-id={index}
          />
          <p className={style.children_icon}>{children}</p>
          {!children && type !== "password" && errorMessage && (
            <BsIcons.BsExclamationCircleFill
              size={16}
              className={style.icon_error}
            />
          )}
          {!children &&
            type === "password" &&
            (showPassword ? (
              <FontAwesomeIcon icon={faEyeSlash}
                               className={style.icon_eye}
                               onClick={() => setShowPassword(false)}
              />
            ) : (
              <FontAwesomeIcon icon={faEye}
                               className={style.icon_eye}
                               onClick={() => setShowPassword(true)}
              />
            ))}
        </div>
        {errorMessage && (
          <span className={style.error_label}>{errorMessage}</span>
        )}
      </div>
    ) : (
      <span className={textClassName} style={{ color: textColor }}>{content}</span>
    )
  );
};

export default Input;
