import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateProjectCommand,
  ProjectsApiFactory,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";
import { toast } from "react-hot-toast";
import { t } from "i18next";

export default function useCreateProject() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateProjectCommand) => {
      return ProjectsApiFactory()
        .createProject(data)
        .then((res) => res.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.projects.paginatedProjects],
      });
    },
    onError: () => {
      toast.error(t("projects:create-project-error"));
    },
  });
}
