import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AssignUsersToProjectCommand,
  ProjectsApiFactory,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useAssignUsersToProjects() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: AssignUsersToProjectCommand) =>
      ProjectsApiFactory()
        .assignToProject(data.projectId, data)
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.paginatedUsers],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.projects.paginatedProjects],
      });
    },
  });
}
