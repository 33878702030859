import { useQuery } from "@tanstack/react-query";
import authService, { IUserTokens } from "../../../services/authService";
import { queryKeys } from "../../../../constants/queryKeys";

const fetchAccesToken = async (email: string, password: string) => {
  return await authService.acquireAccessToken(email, password);
};
export default function useLogin(email: string, password: string) {
  return useQuery<IUserTokens, Error, IUserTokens, string[]>({
    queryKey: [queryKeys.users.login],
    queryFn: async () => await fetchAccesToken(email, password),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });
}
