import style from "./notificationBox.module.scss";
import * as MdIcons from "react-icons/md";

type NotificationType = "error" | "warning" | "info" | "success";

interface IProps {
  notificationType: NotificationType;
  text: string;
  onCloseIconClick?: () => void;
}

const chooseNotifiacationStyle = (notficationType: NotificationType) => {
  switch (notficationType) {
    case "error":
      return style.error;
    case "warning":
      return style.warning;
    case "success":
      return style.success;
    case "info":
      return style.info;
  }
};

const NotificationBox = ({
  text,
  notificationType,
  onCloseIconClick,
}: IProps) => {
  const setIcon = () => {
    switch (notificationType) {
      case "error":
      case "info":
        return <MdIcons.MdInfo className={style.icon} size={16} />;
      case "warning":
        return <MdIcons.MdWarning className={style.icon} size={16} />;
      case "success":
        return <MdIcons.MdCheckCircle className={style.icon} size={16} />;
    }
  };

  return (
    <div
      className={`${style.notification_container} ${chooseNotifiacationStyle(
        notificationType
      )}`}
    >
      {setIcon()}
      <p className={style.text}>{text}</p>
      {onCloseIconClick && (
        <MdIcons.MdClose
          className={style.close_icon}
          onClick={onCloseIconClick}
        />
      )}
    </div>
  );
};

export default NotificationBox;
