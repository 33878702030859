import React, { ReactNode, useRef } from "react";
import style from "./thirdButton.module.scss";

interface IProps {
  name?: string;
  style?: string;
  onClick?: () => void;
  icon?: ReactNode;
  disabled?: boolean;
  ariaDisabled?: boolean;
}

function ThirdButton(prop: IProps) {
  const button = useRef<HTMLButtonElement>(null);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (prop.ariaDisabled || !prop.onClick) {
      return;
    }
    e.preventDefault();
    prop.onClick();
  };
  return (
    <button
      ref={button}
      className={`${style.button} ${prop.style}`}
      onKeyPress={handleKeyPress}
      onClick={
        prop.ariaDisabled
          ? () => {
            return;
          }
          : prop.onClick
      }
      disabled={prop.disabled}
      aria-disabled={prop.ariaDisabled}
    >
      {prop.icon}
      {prop.name}
    </button>
  );
}

export default ThirdButton;
