import React, { useEffect, useState } from "react";
import style from "./companies.module.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useTranslation } from "react-i18next";
import FiltersHeader from "../reports/FiltersHeader";
import useGetPaginatedCompanies, {
  fetchFunction,
} from "../../app/hooks/Companies/queries/useGetPaginatedCompanies";
import { UsersData } from "../../app/models/User";
import {
  companiesPaginationData,
  reportPaginationData,
} from "../../constants/paginationsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TablePagination from "../../reusable-components/table-pagination/TablePagination";
import CompaniesTable from "./companies-table/CompaniesTable";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../constants/queryKeys";
import { CompanyDetailsDto } from "../../app/api/api-types";
import Popup from "../../reusable-components/popup/Popup";
import Company from "./add-or-edit-company/Company";
import useCreateCompany from "../../app/hooks/Companies/commands/useCreateCompany";
import { toast } from "react-hot-toast";
import useUpdateCompany from "../../app/hooks/Companies/commands/useUpdateCompany";
import { ValidationError } from "yup";
import {
  CompanyValidation,
  createCompanySchema,
  createCompanyValidationErrorMessages,
} from "../../app/validation-schemas/companyValidation";

function Companies() {
  const [dataToFetch, setDataToFetch] = useState<UsersData>({
    pageNumber: 1,
    pageSize: companiesPaginationData.pageSize,
  });
  const [companyToEdit, setCompanyToEdit] = useState({} as CompanyDetailsDto);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessages, setErrorMessages] = useState(
    createCompanyValidationErrorMessages
  );
  const { t: tValid } = useTranslation("validation");
  const CompanySchema = createCompanySchema(tValid);
  const { t } = useTranslation("companies");
  const { t: tCom } = useTranslation("common");
  const { data: paginatedCompanies, isPlaceholderData } =
    useGetPaginatedCompanies(dataToFetch);
  const { mutate: createCompany } = useCreateCompany();
  const { mutate: updateCompany } = useUpdateCompany();
  const queryClient = useQueryClient();

  useEffect(() => {
    prefetchData();
  }, [paginatedCompanies, isPlaceholderData, dataToFetch, queryClient]);

  const prefetchData = () => {
    if (
      paginatedCompanies &&
      !isPlaceholderData &&
      dataToFetch.pageNumber < paginatedCompanies.totalPages
    ) {
      const nextPageDataToFetch = {
        ...dataToFetch,
        pageNumber: dataToFetch.pageNumber + 1,
      };
      queryClient.prefetchQuery({
        queryKey: [queryKeys.companies.paginatedCompanies],
        queryFn: () => fetchFunction(nextPageDataToFetch),
      });
    }
  };

  function handleAddCompany() {
    setShowPopup(true);
  }

  function getFilteredData(dataToFetch: UsersData) {
    setDataToFetch(dataToFetch);
  }

  const goToPage = (updater: number | ((pageIndex: number) => number)) => {
    if (typeof updater === "number") {
      setDataToFetch((prev) => {
        return { ...prev, pageNumber: updater };
      });
      document.getElementById("content")?.scrollTo(0, 0);
      document.getElementById("layout")?.scrollTo(0, 0);
    }
  };

  function handleClosePopup() {
    setCompanyToEdit({} as CompanyDetailsDto);
    setShowPopup(false);
    setErrorMessages({ shortName: "", name: "" });
  }

  function handleUpdateCompany(key: keyof CompanyDetailsDto, value: string) {
    setCompanyToEdit((prev) => {
      return { ...prev, [key]: value };
    });
  }

  async function saveCompany() {
    try {
      await CompanySchema.validate(companyToEdit, { abortEarly: false });

      if (companyToEdit.id) {
        updateCompany(
          {
            companyId: companyToEdit.id,
            name: companyToEdit.name,
            shortName: companyToEdit.shortName,
          },
          {
            onSuccess: () => {
              toast.success(t("updated-company"));
              handleClosePopup();
            },
          }
        );
        return;
      }
      if (companyToEdit.name && companyToEdit.shortName) {
        createCompany(
          { name: companyToEdit.name, shortName: companyToEdit.shortName },
          {
            onSuccess: () => {
              toast.success(t("created-company"));
              handleClosePopup();
            },
          }
        );
      }
    } catch (errors) {
      if (errors instanceof ValidationError) {
        const newErrorMessagesKeys = Object.keys(
          createCompanyValidationErrorMessages
        );
        const newErrorMessages = {} as CompanyValidation;
        errors.inner.forEach((error) => {
          if (error.path && newErrorMessagesKeys.includes(error.path)) {
            const key =
              error.path as keyof typeof createCompanyValidationErrorMessages;
            newErrorMessages[key] = error.message;
          }
        });
        setErrorMessages(newErrorMessages);
      }
    }
  }

  function handleEditCompany(company: CompanyDetailsDto) {
    setCompanyToEdit(company);
    setShowPopup(true);
  }

  return (
    <div className={style.container}>
      <BreadcrumbsItem to={"/companies"}>{tCom("companies")}</BreadcrumbsItem>
      <FiltersHeader
        getData={getFilteredData}
        dataToFetch={dataToFetch}
        buttonData={{
          icon: <FontAwesomeIcon icon={faPlus} />,
          name: t("add-company"),
          onClick: handleAddCompany,
        }}
        inputPlaceholderText={t("search-company")}
        disableCompaniesFilter={true}
      />
      {paginatedCompanies && (
        <>
          <CompaniesTable
            companies={paginatedCompanies.companies}
            editCompany={handleEditCompany}
          />
          <TablePagination
            goToPage={goToPage}
            canPreviousPage={dataToFetch.pageNumber > 1}
            canNextPage={dataToFetch.pageNumber < paginatedCompanies.totalPages}
            previousPage={() => goToPage(dataToFetch.pageNumber - 1)}
            nextPage={() => goToPage(dataToFetch.pageNumber + 1)}
            pageCount={paginatedCompanies.totalPages}
            rowsAmount={paginatedCompanies.totalItemsCount}
            pageSize={reportPaginationData.pageSize}
            pageIndex={dataToFetch.pageNumber}
          />
        </>
      )}
      <Popup showPopup={showPopup} closePopup={handleClosePopup}>
        <Company
          company={companyToEdit}
          handleUpdateCompany={handleUpdateCompany}
          handleSaveCompany={saveCompany}
          errorMessages={errorMessages}
        />
      </Popup>
    </div>
  );
}

export default Companies;
