import { useQuery } from "@tanstack/react-query";
import { ProjectsApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

const fetchProject = (projectId: string) => {
  return ProjectsApiFactory()
    .getProject(projectId)
    .then((res) => res.data);
};

export default function useProject(projectId: string) {
  return useQuery({
    queryKey: [queryKeys.projects.project, projectId],
    queryFn: () => fetchProject(projectId),
    refetchOnWindowFocus: false,
    enabled: !!projectId,
  });
}
