import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../constants/queryKeys";
import { ProjectsApiFactory } from "../../../api/api-types";
import { ProjectsData } from "../../../models/User";

export const fetchFunction = (data: ProjectsData) => {
  return ProjectsApiFactory()
    .getPaginatedProjects(
      data.pageNumber,
      data.pageSize,
      data.companyId,
      data.searchValue
    )
    .then((res) => res.data);
};

export default function useGetPaginatedProjects(data: ProjectsData) {
  return useQuery({
    queryKey: [queryKeys.projects.paginatedProjects, data],
    queryFn: () => fetchFunction(data),
    refetchOnWindowFocus: false,
  });
}
