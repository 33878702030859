import React, { Fragment, useEffect, useState } from "react";
import style from "./project.module.scss";
import {
  CreateProjectCommand,
  ProjectDetailsDto,
  UpdateProjectCommand
} from "../../../app/api/api-types";
import { useTranslation } from "react-i18next";
import Input from "../../../reusable-components/input/Input";
import ThirdButton from "../../../reusable-components/third-button/ThirdButton";
import { toast } from "react-hot-toast";
import Dropdown, { IOption } from "../../../reusable-components/dropdown/Dropdown";
import useGetCompanies from "../../../app/hooks/Companies/queries/useGetCompanies";
import { createIOptionArray } from "../../../app/utilities/IOptionArrayUtilities";
import { ValidationError } from "yup";
import TwoLinesTitle from "../../../reusable-components/two-lines-title/TwoLinesTitle";
import {
  createProjectSchema,
  createProjectValidationErrorMessages,
  ProjectValidation
} from "../../../app/validation-schemas/projectValidation";
import useCreateProject from "../../../app/hooks/Projects/commands/useCreateProject";
import useUpdateProject from "../../../app/hooks/Projects/commands/useUpdateProject";
import { useNavigate } from "react-router-dom";

interface IProps {
  closePopup: (showMessageAboutAssignEmployees: boolean, project?: ProjectDetailsDto) => void;
  projectToEdit?: ProjectDetailsDto;
}

const emptyProject: ProjectDetailsDto = {
  id: "",
  title: "",
  companyId: ""
};

function Project({ closePopup, projectToEdit }: IProps) {
  const [project, setProject] = useState<ProjectDetailsDto>(projectToEdit || emptyProject);
  const [company, setCompany] = useState("");
  const [defaultCompany, setDefaultCompany] = useState<IOption>();
  const [companiesAsIOptionArray, setCompaniesAsIOptionArray] = useState<IOption[]>([]);
  const { t: tValid } = useTranslation("validation");
  const ProjectSchema = createProjectSchema(tValid);
  const [errorMessages, setErrorMessages] = useState(createProjectValidationErrorMessages);
  const { t } = useTranslation("project");
  const { t: tCom } = useTranslation("common");
  const { data: companies = [] } = useGetCompanies();
  const { mutateAsync: createProject } = useCreateProject();
  const { mutate: updateProject } = useUpdateProject();
  const navigate = useNavigate();

  useEffect(() => {
    if (companies.length > 0) {
      const data = companies.map(company => {
        return {
          id: company.id,
          name: `${company.shortName}-${company.name}`
        };
      });
      setCompaniesAsIOptionArray(createIOptionArray(data, "id", "name"));
      if (!projectToEdit) {
        const defaultCompany = companies.find(company => company.shortName === "WL");
        if (defaultCompany) {
          setProject(prev => {
            return { ...prev, companyId: defaultCompany?.id };
          });
          setDefaultCompany({
            value: defaultCompany.id,
            label: `${defaultCompany?.shortName}-${defaultCompany?.name}`
          });
          setCompany(`${defaultCompany?.shortName}-${defaultCompany?.name}`);
        }
      } else {
        const company = companies.find(company => company.id === projectToEdit.companyId);
        if (company) {
          setDefaultCompany(
            {
              value: company.id,
              label: `${company.shortName}-${company.name}`
            }
          );
          setCompany(
            `${company.shortName}-${company.name}`
          );
          setProject(prev => {
            return { ...prev, companyId: company.id };
          });
        }
      }
    }
  }, [companies]);

  const getProjectFields = () => {
    return Object.keys(project).map((key, index) => {
      const projectKey = key as keyof ProjectDetailsDto;
      if (projectKey === "title") {
        return (<Fragment key={index}>
          <Input inputClassName={style.input}
                 label={t(projectKey)}
                 content={project[projectKey]}
                 errorMessage={errorMessages[projectKey]}
                 onChange={(e) => setProject(prev => {
                   return { ...prev, [projectKey]: e.target.value };
                 })}
                 onFocus={() => setErrorMessages(prev => {
                   return { ...prev, [projectKey]: "" };
                 })} />
        </Fragment>);
      }
    });
  };

  async function addProject() {
    await createProject({ ...project } as CreateProjectCommand, {
      onSuccess: (data) => {
        toast.success(t("project-added"));
        closePopup(true, { ...project, id: data });
      }
    });
  }

  async function saveProject() {
    try {
      await ProjectSchema.validate(project, { abortEarly: false });

      if (!project.id) {
        await addProject();
      } else {
        updateProject({
          id: project.id,
          companyId: project.companyId,
          title: project.title
        } as UpdateProjectCommand, {
          onSuccess: () => {
            toast.success(t("updated-project"));
            closePopup(false);
          }
        });
      }
    } catch (errors) {
      if (errors instanceof ValidationError) {
        const newErrorMessagesKeys = Object.keys(createProjectValidationErrorMessages);
        const newErrorMessages = {} as ProjectValidation;
        errors.inner.forEach((error) => {
          if (error.path && newErrorMessagesKeys.includes(error.path)) {
            const key = error.path as keyof typeof createProjectValidationErrorMessages;
            newErrorMessages[key] = error.message;
          }
        });
        setErrorMessages(newErrorMessages);
      }
    }
  }

  function handleSetCompany(value: IOption) {
    setProject(prev => {
      return { ...prev, companyId: value.value };
    });
    setCompany(value.label);
  }

  function goToEmployeeList(projectId: string) {
    navigate("/projects/" + projectId, { state: project.companyId });
  }

  return (
    <div className={style.container}>
      <p className={style.title}>{project.id ? t("edit-project") : t("add-new-project")}</p>
      <div className={style.form}>
        <TwoLinesTitle title={project.id ? t("fill-data") : t("add-new-project")} />
        {getProjectFields()}
        <div className={style.dropdown_container}>
          <p className={style.label}>{t("company")}</p>
          <Dropdown onClick={(value) => handleSetCompany(value)}
                    valueStyle={style.dropdown_value}
                    wrapperClassName={style.dropdown_wrapper}
                    value={company}
                    defaultValue={defaultCompany}
                    editMode={true}
                    disabled={!!projectToEdit}
                    options={companiesAsIOptionArray}
          />
          {projectToEdit && <>
            <div className={style.employees_button_container}>
              <TwoLinesTitle title={t("employees")} />
              <p className={style.link} onClick={() => goToEmployeeList(project.id)}>{t(
                "see-list-of-employees")}</p>
            </div>
            <div className={style.last_modification_container}>
              <TwoLinesTitle title={t("last-modification-information-line")} />
              <Input inputClassName={style.input}
                     label={t("last-modification-information-label")}
                     content={projectToEdit.modifiedBy}
                     disabled={true}
              />
            </div>
          </>
          }
        </div>
      </div>
      <div className={style.button_container}>
        <ThirdButton name={tCom("save")} onClick={() => saveProject()} style={style.button} />
      </div>
    </div>
  );
}

export default Project;
