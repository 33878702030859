import style from "./threeDotsMenu.module.scss";
import * as BsIcons from "react-icons/bs";
import { useState } from "react";
import ThreeDotsPanel, { PanelOption, } from "../three-dots-panel/ThreeDotsPanel";

type Direction = "bottom_left" | "bottom_right" | "top_left" | "top_right";

interface IProp {
  panelOptions: PanelOption[];
  direction: Direction;
  disabled?: boolean;
}

const ThreeDotsMenu = ({ panelOptions, direction, disabled }: IProp) => {
  const [showThreeDotsPanel, setShowThreeDotsPanel] = useState(false);
  const [mouseClick, setMouseClick] = useState(false);

  return (
    <div
      className={style.three_dots_container}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          setShowThreeDotsPanel(false);
          e.currentTarget.focus();
          return;
        }
        e.key === "Enter" && setShowThreeDotsPanel((prevState) => !prevState);
      }}
    >
      <BsIcons.BsThreeDotsVertical
        className={`${style.dots_icon} ${disabled ? style.disabled : ""}`}
        size={"18px"}
        onClick={() => {
          if (!disabled) {
            setMouseClick(true);
            setShowThreeDotsPanel((prevState) => !prevState);
          }
        }}
      />
      {showThreeDotsPanel && (
        <ThreeDotsPanel
          panelOptions={panelOptions}
          setShowPanel={setShowThreeDotsPanel}
          classNameWithPosition={`${style.panel_position} ${style[direction]}`}
          onClickOutside={() => setShowThreeDotsPanel(false)}
          mouseClick={mouseClick}
          setMouseClick={setMouseClick}
        />
      )}
    </div>
  );
};

export default ThreeDotsMenu;
