import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import * as BsIcons from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../app/context/UserContext";
import Input from "../../reusable-components/input/Input";
import Checkbox from "../../reusable-components/checkbox/Checkbox";
import browserStorage from "../../app/services/browserStorageService";
import NotificationBox from "../../reusable-components/notification-box/NotificationBox";
import style from "./loginForm.module.scss";
import useLogin from "../../app/hooks/Users/commands/useLogin";
import LoginPageContainer from "../../reusable-components/login-page-container/LoginPageContainer";
import authService from "../../app/services/authService";
import { queryKeys } from "../../constants/queryKeys";

const LoginForm = () => {
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showLoginError, setShowLoginError] = useState<boolean>(false);
  const [lostValidToken, setLostValidToken] = useState(false);
  const [shouldRememberUser, setShouldRememberUser] = useState(
    browserStorage.getShouldRememberUser()
  );
  const queryClient = useQueryClient();
  const { login, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation("common");
  const { t: tLog } = useTranslation("login");

  const { isLoading, isError, refetch } = useLogin(
    loginValues.email,
    loginValues.password
  );

  useEffect(() => {
    if (isError) {
      setErrorMessage(tLog("wrong-login-data"));
      setShowLoginError(true);
    }
  }, [isError]);

  useEffect(() => {
    if (document.referrer) {
      setLostValidToken(true);
    }
    if (browserStorage.getAccessToken()) {
      refreshToken();
    }
    return () => queryClient.setQueryData([queryKeys.users.login], undefined);
  }, []);

  const refreshToken = async () => {
    const refreshToken = browserStorage.getRefreshToken();
    if (refreshToken) {
      await authService.getFreshToken(refreshToken).catch(() => {
        logout();
      });
    }
    navigate("/");
  };

  const handleSubmit = async () => {
    if (!isLoading) {
      const userTokens = await refetch().then((res) => res.data);

      if (userTokens?.accessToken && userTokens.refreshToken) {
        login(
          userTokens.accessToken,
          userTokens.refreshToken,
          shouldRememberUser
        );
        const path = location.state?.pathname ? location.state.pathname : "/";
        navigate(path);
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginValues({ ...loginValues, [e.target.name]: e.target.value.trim() });
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const setRememberUser = (keepUserLogin: boolean) => {
    setShouldRememberUser(keepUserLogin);
    browserStorage.setShouldRememberUser(keepUserLogin);
  };

  return (
    <LoginPageContainer
      onButtonClick={() => handleSubmit()}
      buttonText={t("log-in")}
      isButtonDisabled={!loginValues.email || !loginValues.password}
      isLoading={isLoading}
    >
      <div className={style.inputs_container}>
        <div className={style.title}>
          {tLog("login")}
          <div className={style.line}></div>
        </div>
        <div
          className={` ${style.error_messages_wrapper} ${
            showLoginError || lostValidToken ? style.active : ""
          }`}
        >
          {showLoginError && (
            <div className={style.wrong_login_data}>
              <BsIcons.BsExclamationCircleFill
                className={style.exclamation_icon}
              />
              <span>{errorMessage}</span>
              <BsIcons.BsX
                className={style.close_icon}
                onClick={() => setShowLoginError(false)}
              />
            </div>
          )}
          {lostValidToken && !showLoginError && (
            <NotificationBox
              notificationType={"info"}
              text={t("session-expired")}
            />
          )}
        </div>
        <div className={style.inputs_wrapper}>
          <Input
            inputClassName={style.input}
            name={"email"}
            content={loginValues.email}
            label={t("email")}
            onChange={onChange}
            onKeyPress={onEnterPress}
          />
          <Input
            type={"password"}
            name={"password"}
            content={loginValues.password}
            label={t("password")}
            onChange={onChange}
            onKeyPress={onEnterPress}
          />
        </div>
        <Checkbox
          style={style.checkbox_container}
          label={t("keep-logged")}
          handleChecked={setRememberUser}
          checked={browserStorage.getShouldRememberUser()}
        />
        <div className={style.reset_password_container}>
          <p className={style.password_reset}>{t("forgot-password")}</p>
          <Link to={"/password-reset"}>
            <p>{tLog("reset-password-option")}</p>
          </Link>
        </div>
      </div>
    </LoginPageContainer>
  );
};

export default LoginForm;
