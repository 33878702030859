import { Dispatch, SetStateAction } from "react";
import style from "./switch.module.scss";

interface IProp {
  state: boolean,
  toggle: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

function Switch({ state, toggle, className }: IProp) {
  return (
    <div className={`${style.switch} ${state ? style.on : style.off} ${className ? className : ""}`}
         onClick={() => toggle(prev => !prev)}>
      <div className={style.circle} />
    </div>
  );
}

export default Switch;
