import axios from "axios";
import { useMutation } from "@tanstack/react-query";

export default function useSendMailWithLinkToResetPassword() {
  return useMutation({
    mutationFn: (email: string) => {
      return axios
        .put("users/password/generate-reset-token", { email: email })
        .then((d) => d.data);
    }
  });
}
