import { useMutation } from "@tanstack/react-query";
import {
  CheckResetPasswordTokenValidationCommand,
  UsersApiFactory,
} from "../../../api/api-types";

export default function useCheckTokenValidation() {
  return useMutation({
    mutationFn: (data: CheckResetPasswordTokenValidationCommand) =>
      UsersApiFactory()
        .checkTokenValidation(data.userId, data)
        .then((res) => res.data),
  });
}
