import { useQuery } from "@tanstack/react-query";
import { ReportsApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";
import { ReportData } from "../../../models/Reports";

export const fetchFunction = (data: ReportData) => {
  return ReportsApiFactory()
    .getReports(
      data.startTime.format("YYYY MM DD HH:mm"),
      data.endTime.format("YYYY MM DD HH:mm"),
      data.pageNumber,
      data.pageSize,
      data.searchValue,
      data.companyId,
      data.projectId,
      data.status
    )
    .then((res) => res.data);
};

export default function useGetReport(data: ReportData) {
  return useQuery({
    queryKey: [queryKeys.reports.report],
    queryFn: () => fetchFunction(data)
  });
}
