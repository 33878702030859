import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactNode, useContext, useEffect } from "react";
import { UserContext } from "./app/context/UserContext";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./constants/queryKeys";

interface IProps {
  children: ReactNode;
}

const ErrorBoundary = ({ children }: IProps) => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: window._env_.REACT_APP_APP_INSTRUMENTATION_KEY,
        enableAutoRouteTracking: true,
      },
    });

    const errorHandler = (errorEvent: ErrorEvent) => {
      if (!errorEvent || !errorEvent.message) {
        return;
      }
      errorEvent.stopPropagation();
      errorEvent.preventDefault();

      const userWorkTime = JSON.stringify(
        queryClient.getQueriesData({
          queryKey: [queryKeys.reports.timePeriodSummary],
        })
      );
      appInsights.trackException(errorEvent, {
        user: user.email,
        userId: user.id,
        date: dayjs().format(),
        path: location.pathname,
        userAgent: navigator.userAgent,
        userWorkTime: userWorkTime,
      });
    };

    appInsights.loadAppInsights();
    window.addEventListener("error", errorHandler);

    if (
      location.pathname === "/dashboard" &&
      !user.hasPermissionTo("time-tracker")
    ) {
      const errorEvent = new ErrorEvent("PermissionError", {
        message: "User does not have permission to time-tracker",
      });
      errorHandler(errorEvent);
    }

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  return <>{children}</>;
};

export default ErrorBoundary;
