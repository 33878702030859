import dayjs, { Dayjs } from "dayjs";
import { TFunction } from "i18next";

export const getWeekDateFormat = (
  startDate: Dayjs,
  endDate: Dayjs,
  t: TFunction
) => {
  if (startDate.year() !== endDate.year()) {
    return `${startDate.format(t("D-M-Y"))} - ${endDate.format(t("D-M-Y"))}`;
  }
  let date: string;
  if (startDate.month() !== endDate.month()) {
    date = `${startDate.format(t("D-M"))} - ${endDate.format(t("D-M"))}`;
  } else {
    date = `${startDate.format(t("same-month-first-part"))} - ${endDate.format(
      t("same-month-second-part")
    )}`;
  }
  if (dayjs().year() !== startDate.year()) {
    date = `${date} ${startDate.format(t("year-at-end"))}`;
  }
  return date;
};

export const getDayNameFormat = (date: Dayjs, t: TFunction) => {
  const today = dayjs();
  if (today.format("D/M/Y") === date.format("D/M/Y")) {
    return t("today");
  } else if (today.format("D/M/Y") === date.add(1, "d").format("D/M/Y")) {
    return t("yesterday");
  } else {
    return date.format(t("full-day-name"));
  }
};

export const getTimeDuration = (startTime: Date | Dayjs | string,
  endTime: Date | Dayjs | string) => {
  const duration = dayjs.duration(dayjs(endTime).diff(dayjs(startTime)));
  const hours = Math.floor(duration.asHours());
  return `${hours < 10 ? `0${hours}` : hours}:${duration.format("mm")} h`;
};

export const formatTimeToDurations = (time: number) => {
  const hours = Math.floor(dayjs.duration(time).asHours());
  return `${hours < 10 ? `0${hours}` : hours}:${dayjs
    .duration(time)
    .format("mm")}`;
};
