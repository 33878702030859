import { useMutation } from "@tanstack/react-query";
import { UsersApiFactory, ResetPasswordCommand } from "../../../api/api-types";

export default function useResetPassword() {
  return useMutation({
    mutationFn: (data: ResetPasswordCommand) =>
      UsersApiFactory()
        .resetPassword(data.userId, data)
        .then((d) => d.data),
  });
}
