import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../../constants/queryKeys";
import { SettingsApiFactory, SettingsByScopeDto } from "../../../api/api-types";

const fetchFunction = (scope: keyof typeof queryKeys.settings) => {
  return SettingsApiFactory()
    .getSettingsByScope(scope.replace("Settings", ""))
    .then((res) => res.data[scope]);
};

export default function useGetTimeTrackerSettings(
  scope: keyof SettingsByScopeDto
) {
  return useQuery({
    queryKey: [queryKeys.settings[scope]],
    queryFn: () => fetchFunction(scope),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
