import React, { useEffect, useState } from "react";
import style from "./assignUsersToProject.module.scss";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../reusable-components/checkbox/Checkbox";
import {
  GetUsersAssignedToDefaultProjectInCompanyQuery,
  ProjectDetailsDto,
  ProjectDto,
} from "../../../app/api/api-types";
import Input from "../../../reusable-components/input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDebouncedCallback } from "use-debounce";
import ThirdButton from "../../../reusable-components/third-button/ThirdButton";
import useAssignUsersToProjects from "../../../app/hooks/Projects/commands/useAssignUsersToProjects";
import useGetUsersAssignedtoDefaultProjectInCompany from "../../../app/hooks/Users/queries/useGetUsersAssignedtoDefaultProjectInCompany";
import LoadingSpinner from "../../../reusable-components/loading-spinner/LoadingSpinner";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../constants/queryKeys";

interface IProps {
  project: ProjectDto | ProjectDetailsDto;
  companyId: string;
  closePopup: () => void;
}

function AssignUsersToProject({ project, companyId, closePopup }: IProps) {
  const [dataToFetchUsers, setDataToFetchUsers] =
    useState<GetUsersAssignedToDefaultProjectInCompanyQuery>({
      companyId: companyId,
      searchValue: "",
    });
  const [searchValue, setSearchValue] = useState("");
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);
  const [showUsersTable, setShowUsersTable] = useState(false);
  const { t } = useTranslation("users");
  const { t: tProject } = useTranslation("project");
  const { data: users, isLoading } =
    useGetUsersAssignedtoDefaultProjectInCompany(dataToFetchUsers);
  const { mutate: assignUsersToProject, isPending: isAssigning } =
    useAssignUsersToProjects();
  const queryClient = useQueryClient();
  const searchUser = useDebouncedCallback(
    (searchValue: string) =>
      setDataToFetchUsers((prev) => {
        return { ...prev, searchValue: searchValue };
      }),
    300
  );

  useEffect(() => {
    return () => {
      queryClient.resetQueries({
        queryKey: [queryKeys.projects.usersAssignedToDefaultProjectInCompany],
      });
    };
  }, []);

  useEffect(() => {
    if (users) {
      const usersIds = users.reduce((usersIds, user) => {
        if (user.selectedProjectId === project.id) {
          usersIds.push(user.id);
        }
        return usersIds;
      }, [] as string[]);

      const newUsersIds = selectedUsersIds;
      usersIds.forEach((userId) => {
        if (!newUsersIds.some((id) => id === userId)) {
          newUsersIds.push(userId);
        }
      });
      setSelectedUsersIds(newUsersIds);
      setShowUsersTable(true);
    }
  }, [users]);

  function isSelected(userId: string) {
    return selectedUsersIds.some((selectedUserId) => selectedUserId === userId);
  }

  function handleSelectUser(userId: string) {
    if (selectedUsersIds.some((selectedUserId) => selectedUserId === userId)) {
      const usersIds = selectedUsersIds.filter(
        (selectedUserId) => selectedUserId !== userId
      );
      setSelectedUsersIds(usersIds);
    } else {
      setSelectedUsersIds((prev) => {
        return [...prev, userId];
      });
    }
  }

  function handleAssignUsers() {
    assignUsersToProject(
      {
        projectId: project.id,
        companyId: companyId,
        userIds: selectedUsersIds,
      },
      { onSuccess: () => closePopup() }
    );
  }

  function handleSearchUser(value: string) {
    searchUser.cancel();
    setSearchValue(value);
    searchUser(value);
  }

  return (
    <div className={style.container}>
      <p className={style.title}>{tProject("add-employees")}</p>
      <Input
        containerClassName={style.input}
        content={searchValue}
        placeholder={tProject("search-employee")}
        onChange={(e) => handleSearchUser(e.target.value)}
      >
        <FontAwesomeIcon icon={faSearch} />
      </Input>
      <div
        className={`${style.table_container} ${
          isLoading ? style.loading : ""
        }}`}
      >
        <div className={style.header_container}>
          <div className={style.table_cell}>
            {t("chosen")}:{" "}
            <p className={style.selected_users_count}>
              {selectedUsersIds.length}
            </p>
          </div>
          <div className={style.table_cell}>{t("first-name")}</div>
          <div className={style.table_cell}>{t("last-name")}</div>
          <div
            className={`${style.table_cell} ${style.social_security_number}`}
          >
            {t("social-security-number")?.toUpperCase()}
          </div>
          <div className={`${style.table_cell} ${style.passport}`}>
            {t("passport-number")}
          </div>
          <div className={style.table_cell}>{t("e-mail")}</div>
        </div>
        <div className={style.users_container}>
          {users &&
            showUsersTable &&
            users.map((user) => (
              <div key={user.id} className={`${style.user_container}`}>
                <div className={style.table_cell}>
                  <Checkbox
                    checked={isSelected(user.id)}
                    handleChecked={() => handleSelectUser(user.id)}
                  />
                </div>
                <div className={style.table_cell}>{user.firstName}</div>
                <div className={style.table_cell}>{user.lastName}</div>
                <div
                  className={`${style.table_cell} ${style.social_security_number}`}
                >
                  {user.socialSecurityNumber}
                </div>
                <div className={`${style.table_cell} ${style.passport}`}>
                  {user.passport}
                </div>
                <div className={style.table_cell}>{user.email}</div>
              </div>
            ))}
        </div>
        {isLoading && <LoadingSpinner />}
      </div>
      <ThirdButton
        style={style.button}
        name={tProject("save")}
        onClick={handleAssignUsers}
        disabled={isAssigning}
      />
    </div>
  );
}

export default AssignUsersToProject;
