/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactChild } from "react";
import style from "./popup.module.scss";
import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";

interface IProps {
  children: ReactChild;
  showPopup: boolean;
  closePopup: () => void;
  isLoading?: boolean;
  enableSpinner?: boolean;
  containerClassName?: string;
}

function Popup({
  children,
  showPopup,
  closePopup,
  isLoading,
  enableSpinner = true,
  containerClassName,
}: IProps) {
  const isFetching = useIsMutating();
  const isFetchingData = useIsFetching();

  return showPopup ? (
    createPortal(
      <div className={style.container}>
        <div className={style.wrapper}>
          <div
            className={`${style.popup} ${
              containerClassName ? containerClassName : ""
            }`}
          >
            {enableSpinner &&
              (isLoading || !!isFetching || !!isFetchingData) && (
                <LoadingSpinner />
              )}
            <div
              className={`${style.child_container} ${
                enableSpinner && (isLoading || isFetching || !!isFetchingData)
                  ? style.hide_children
                  : ""
              }`}
            >
              <FontAwesomeIcon
                onClick={() => closePopup()}
                icon={faXmark}
                className={style.icon}
              />
              {children}
            </div>
          </div>
        </div>
      </div>,
      document.getElementById("root")!
    )
  ) : (
    <></>
  );
}

export default Popup;
