import { useContext, useEffect } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import {
  Breadcrumbs,
  BreadcrumbsItem,
  BreadcrumbsProvider,
} from "react-breadcrumbs-dynamic";
import * as MdIcons from "react-icons/md";

import LoginForm from "./components/login/LoginForm";
import PasswordResetMailConfirmationForm from "./components/password-reset/PasswordResetMailConfirmationForm";
import PasswordResetNewPasswordForm from "./components/password-reset/PasswordResetNewPasswordForm";
import Layout from "./components/layout/Layout";
import PrivateRoute from "./components/private-route/PrivateRoute";
import { UserContext } from "./app/context/UserContext";
import PermissionRoute from "./components/permission-route/PermissionRoute";
import "./app.scss";
import Dashboard from "./components/dashboard/Dashboard";
import { SettingsContextProvider } from "./app/context/SettingsContext";
import AddWorkTime from "./components/add-work-time/AddWorkTime";
import "dayjs/locale/pl";
import "dayjs/locale/uk";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Reports from "components/reports/Reports";
import Users from "./components/users/Users";
import LoadingSpinner from "./reusable-components/loading-spinner/LoadingSpinner";
import Settings from "./components/settings/Settings";
import Projects from "./components/projects/Projects";
import ProjectEmployees from "./components/projects/employees/ProjectEmployees";
import Companies from "./components/companies/Companies";

export default () => {
  const { user, isLoading, roles } = useContext(UserContext);
  const { i18n } = useTranslation();
  const { t } = useTranslation("common");

  useEffect(() => {
    document.title = window._env_.REACT_APP_TITLE;
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  const getDashboardDedicatedToRole = () => {
    return user.roles.some((role) => role === roles?.admin) ? (
      <Users />
    ) : (
      <Dashboard />
    );
  };
  return (
    <>
      <BreadcrumbsProvider>
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route
            path="/password-reset"
            element={<PasswordResetMailConfirmationForm />}
          />
          <Route
            path="/password-change/:id"
            element={<PasswordResetNewPasswordForm />}
          />
          <Route
            path={"/*"}
            element={
              <PrivateRoute redirectTo={"/login"}>
                <Layout
                  breadcrumbs={
                    <Breadcrumbs
                      item={Link}
                      separator={
                        <b>
                          <MdIcons.MdChevronRight
                            size={"24px"}
                            className={"breadcrumb_separator"}
                          />
                        </b>
                      }
                      finalItem={"a"}
                    />
                  }
                >
                  <SettingsContextProvider>
                    <Routes>
                      <Route path="/" element={getDashboardDedicatedToRole()} />
                      <Route
                        element={
                          <PermissionRoute
                            permission={user.hasPermissionTo("time-tracker")}
                          />
                        }
                      >
                        <Route
                          path="/add-work-time"
                          element={<AddWorkTime />}
                        />
                      </Route>
                      <Route
                        element={
                          <PermissionRoute
                            permission={user.hasPermissionTo("reports")}
                          />
                        }
                      >
                        <Route path={"/reports"} element={<Reports />} />
                      </Route>
                      <Route
                        element={
                          <PermissionRoute
                            permission={user.hasPermissionTo("users")}
                          />
                        }
                      >
                        <Route path={"/users"} element={<Users />} />
                      </Route>
                      <Route
                        element={
                          <PermissionRoute
                            permission={user.hasPermissionTo("projects")}
                          />
                        }
                      >
                        <Route path={"/projects"} element={<Projects />} />
                        <Route
                          path="/projects/:id"
                          element={
                            <>
                              <BreadcrumbsItem to={"/projects"}>
                                {t("projects")}
                              </BreadcrumbsItem>
                              <ProjectEmployees />
                            </>
                          }
                        />
                      </Route>
                      <Route
                        element={
                          <PermissionRoute
                            permission={user.hasPermissionTo("companies")}
                          />
                        }
                      >
                        <Route path={"/companies"} element={<Companies />} />
                      </Route>
                      <Route
                        element={
                          <PermissionRoute
                            permission={user.hasPermissionTo("settings")}
                          />
                        }
                      >
                        <Route path={"/settings"} element={<Settings />} />
                      </Route>
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </SettingsContextProvider>
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </BreadcrumbsProvider>
    </>
  );
};
