import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  GrantGdprConsentCommand,
  UsersApiFactory,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useSetGdprConsent() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: GrantGdprConsentCommand) => UsersApiFactory().setGdprConsent(data).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.employeeSettings],
      });
    },
  });
}
