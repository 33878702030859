import React from "react";
import style from "./company.module.scss";
import { CompanyDetailsDto } from "../../../app/api/api-types";
import Input from "../../../reusable-components/input/Input";
import { useTranslation } from "react-i18next";
import TwoLinesTitle from "../../../reusable-components/two-lines-title/TwoLinesTitle";
import ThirdButton from "../../../reusable-components/third-button/ThirdButton";
import { CompanyValidation } from "../../../app/validation-schemas/companyValidation";

interface IProps {
  company: CompanyDetailsDto;
  handleUpdateCompany: (key: keyof CompanyDetailsDto, value: string) => void;
  handleSaveCompany: () => void;
  errorMessages: CompanyValidation;
}

function Company({ company, handleUpdateCompany, handleSaveCompany, errorMessages }: IProps) {
  const { t } = useTranslation("companies");
  const { t: tCom } = useTranslation("common");
  return (
    <div className={style.container}>
      <p className={style.title}>{company.id ? t("edit-company") : t("add-new-company")}</p>
      <div className={style.form}>
        <TwoLinesTitle title={t("add-company")} />
        <Input label={t("company")}
               inputClassName={style.input}
               content={company.name}
               errorMessage={errorMessages.name}
               onChange={(e) => handleUpdateCompany("name", e.target.value)} />
        <Input label={t("company-short-name")}
               inputClassName={style.input}
               containerClassName={style.input_container}
               content={company.shortName}
               errorMessage={errorMessages.shortName}
               onChange={(e) => handleUpdateCompany("shortName", e.target.value)} />
        <TwoLinesTitle title={t("information")} />
        <Input disabled={true}
               label={t("last-actualisation")}
               inputClassName={style.input}
               content={company.modifiedBy}
               onChange={() => {
                 return;
               }} />
      </div>
      <div className={style.button_container}>
        <ThirdButton style={style.button} name={tCom("save")} onClick={() => handleSaveCompany()} />
      </div>
    </div>
  );
}

export default Company;
