/* eslint-disable camelcase */
import axios from "axios";
import { TokenResponseDto } from "../models/Token";
import { createFormParams } from "../utils";

export class AuthRequests {
  public static async acquireAccessToken(email: string, password: string) {
    return await axios
      .request<TokenResponseDto>({
        method: "post",
        url: "connect/token",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: createFormParams({
          grant_type: "password",
          username: email,
          password: password,
          client_id: window._env_.REACT_APP_CLIENT_ID,
          client_secret: window._env_.REACT_APP_CLIENT_SECRET,
        }),
      })
      .then((res) => res.data);
  }

  public static async refreshTokens(refreshToken: string) {
    return await axios
      .request<TokenResponseDto>({
        method: "post",
        url: "connect/token",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: createFormParams({
          grant_type: "refresh_token",
          refresh_token: refreshToken,
          client_id: window._env_.REACT_APP_CLIENT_ID,
          client_secret: window._env_.REACT_APP_CLIENT_SECRET,
        }),
      })
      .then((d) => d.data);
  }
}
