import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SetLanguageCommand, UsersApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export default function useSetLanguage() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SetLanguageCommand) => UsersApiFactory().setLanguage(data).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.users.employeeSettings],
      });
    },
  });
}
