import React, { useEffect, useState } from "react";
import style from "./filtersHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { IOption } from "../../reusable-components/dropdown/Dropdown";
import useGetCompanies from "../../app/hooks/Companies/queries/useGetCompanies";
import { createIOptionArray } from "../../app/utilities/IOptionArrayUtilities";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import i18next from "i18next";
import { ReportData } from "../../app/models/Reports";
import { useDebouncedCallback } from "use-debounce";
import Button from "../../reusable-components/button/Button";
import { useTranslation } from "react-i18next";
import UsersFilter from "../../reusable-components/users-filter/UsersFilter";
import { ProjectsData, UsersData } from "../../app/models/User";
import { stringEmpty } from "../../constants/emptyValues";
import Popup from "../../reusable-components/popup/Popup";
import "../../style/datePickerCalendarStyleOverride.scss";
import userStatuses from "../../constants/userStatuses";
import useGetProjectsAssignedToCompany
  from "../../app/hooks/Companies/queries/useGetProjectsAssignedToCompany";

interface HeaderButtonData {
  icon: JSX.Element,
  name: string,
  onClick: () => void
}

interface IProps {
  getData: (data: UsersData | ReportData | ProjectsData) => void;
  dataToFetch: UsersData | ReportData | ProjectsData;
  buttonData: HeaderButtonData;
  inputPlaceholderText?: string;
  disableCompaniesFilter?: boolean;
}

interface DateRange {
  startTime: dayjs.Dayjs;
  endTime?: dayjs.Dayjs | null;
}

function FiltersHeader({
  getData,
  dataToFetch,
  buttonData,
  inputPlaceholderText,
  disableCompaniesFilter
}: IProps) {
  const [reportTimeRange, setReportTimeRange] = useState<DateRange>({
    startTime: dayjs().subtract(1, "months").startOf("month"),
    endTime: dayjs().subtract(1, "months").endOf("month")
  });
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState("");
  const [statusesAsIOptionArray, setStatusesAsIOptionArray] = useState<IOption[]>([]);
  const [company, setCompany] = useState("");
  const [companiesAsIOptionArray, setCompaniesAsIOptionArray] = useState<IOption[]>([]);
  const [project, setProject] = useState("");
  const [projectsAsIOptionArray, setProjectsAsIOptionArray] = useState<IOption[]>([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { data: companies = [], isLoading } = useGetCompanies(disableCompaniesFilter);
  const { data: projects = [] } = useGetProjectsAssignedToCompany(dataToFetch.companyId);
  const { t } = useTranslation("reports");
  const debouncedFetchReport = useDebouncedCallback(getData, 300);

  useEffect(() => {
    const data = userStatuses.map(status => {
      return {
        id: status,
        name: status,
      };
    });
    setStatusesAsIOptionArray(createIOptionArray(data, "id", "name"));
    setStatus(data[0].name);
  }, []);

  useEffect(() => {
    if (companies.length > 0) {
      const data = companies.map(company => {
        return {
          id: company.id,
          name: `${company.shortName}-${company.name}`
        };
      });

      data.unshift({ id: stringEmpty, name: t("all-companies") });
      setCompaniesAsIOptionArray(createIOptionArray(data, "id", "name"));
      setCompany(data[0].name);
    }
  }, [companies]);

  useEffect(() => {
    if (projects.length > 0) {
      const data = projects.map(project => {
        return {
          id: project.id,
          name: project.title
        };
      });

      data.unshift({ id: stringEmpty, name: t("all-projects") });
      setProjectsAsIOptionArray(createIOptionArray(data, "id", "name"));
      setProject(data[0].name);
    }
  }, [projects]);

  if (isLoading) {
    return <></>;
  }

  function filterByName(value: string) {
    setSearchValue(value);
    const data = { ...dataToFetch, pageNumber: 1, searchValue: value };
    debouncedFetchReport(data);
  }

  function filterByCompany(value: IOption) {
    setCompany(value.label);
    const data = { ...dataToFetch, pageNumber: 1, companyId: value.value, projectId: undefined };
    getData(data);
    if (!value.value) {
      setProjectsAsIOptionArray([] as IOption[]);
      setProject("");
    }
  }

  function changeReportDates(param: { startTime: dayjs.Dayjs; endTime?: dayjs.Dayjs | null }) {
    const data = {
      ...dataToFetch,
      startTime: param.startTime,
      endTime: param.endTime,
    };
    getData(data);
  }

  function checkType() {
    return "startTime" in dataToFetch && "endTime" in dataToFetch;
  }

  const setDate = (date: [(Date | null), (Date | null)]) => {
    setReportTimeRange({
      startTime: dayjs(date[0]),
      endTime: date[1] && dayjs(date[1]).endOf("days")
    });
    if (date[0] && date[1]) {
      setIsCalendarOpen(false);
      changeReportDates({ startTime: dayjs(date[0]), endTime: dayjs(date[1]).endOf("days") });
    }
  };

  function filterByStatus(status: IOption) {
    setStatus(status.value);
    let userStatus: boolean | undefined;
    switch (status.value) {
      case "active-employees": {
        userStatus = true;
        break;
      }
      case "not-active-employees": {
        userStatus = false;
        break;
      }
      default: {
        userStatus = undefined;
      }
    }
    const data = { ...dataToFetch, pageNumber: 1, status: userStatus };
    getData(data);
  }

  function filterByProject(project: IOption) {
    setProject(project.label);
    const data = { ...dataToFetch, pageNumber: 1, projectId: project.value };
    getData(data);
  }

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <UsersFilter name={searchValue}
                     filterByName={(name) => filterByName(name)}
                     filterByCompany={(value) => filterByCompany(value)}
                     filterByStatus={(status) => filterByStatus(status)}
                     filterByProject={(project) => filterByProject(project)}
                     company={company}
                     companies={companiesAsIOptionArray}
                     inputPlaceholderText={inputPlaceholderText}
                     disableCompaniesFilter={disableCompaniesFilter}
                     project={project}
                     projects={projectsAsIOptionArray}
                     status={status}
                     statuses={statusesAsIOptionArray}
                     displayStatusFilter={"status" in dataToFetch}
                     displayProjectsFilter={"status" in dataToFetch} />
        {checkType() &&
          <>
            <div className={style.date_picker} onClick={() => setIsCalendarOpen(true)}>
              <div className={style.date_range}>
                <span>{reportTimeRange.startTime.format("DD MMM YYYY").toLowerCase()}</span>
                <div />
                <span>{reportTimeRange.endTime?.format("DD MMM YYYY").toLowerCase()}</span>
              </div>
              <FontAwesomeIcon icon={faCalendar} className={style.calendar_icon} />
            </div>
            <Popup showPopup={isCalendarOpen}
                   closePopup={() => reportTimeRange.endTime && setIsCalendarOpen(false)}
                   containerClassName={style.popup}>
              <div className={style.calendar_container}>
                <DatePicker
                  locale={i18next.resolvedLanguage}
                  selectsRange={true}
                  startDate={reportTimeRange.startTime.toDate()}
                  endDate={reportTimeRange.endTime?.toDate()}
                  selected={reportTimeRange.startTime.toDate()}
                  dateFormat={"dd MMM yyyy"}
                  showYearDropdown={false}
                  showMonthDropdown={false}
                  wrapperClassName={style.date_wrapper}
                  nextMonthButtonLabel={<div className={style.calendar_button}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>}
                  previousMonthButtonLabel={<div className={style.calendar_button}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>}
                  calendarClassName={style.calendar}
                  popperClassName={style.popper}
                  onChange={(date) => setDate(date)}
                  open={isCalendarOpen}
                />
              </div>
            </Popup>
          </>}
      </div>
      <div>
        <Button style={style.button} icon={buttonData.icon}
                name={buttonData.name}
                onClick={buttonData.onClick} />
      </div>
    </div>
  );
}

export default FiltersHeader;
