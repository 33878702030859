import React from "react";
import style from "./radioButton.module.scss";

interface IProp {
  marked: boolean;
  locale: string;
  text?: string;
  onClick: (text: string) => void;
}

function RadioButton({ marked, locale, text, onClick }: IProp) {
  return (
    <div className={style.container} onClick={() => onClick(locale)}>
      <div className={style.button_container}>
        <div className={`${style.check} ${marked ? style.marked : ""}`} />
      </div>
      <p className={style.text}>{text}</p>
    </div>
  );
}

export default RadioButton;
