import React, { useRef, useState } from "react";
import style from "./projectEmployeesTable.module.scss";
import { UserDetailsDto } from "../../../../app/api/api-types";
import { useTranslation } from "react-i18next";
import ProjectEmployeesTableRowThreeDotsMenu from "./ProjectEmployeesTableRowThreeDotsMenu";
import Popup from "../../../../reusable-components/popup/Popup";
import useDeleteUser from "../../../../app/hooks/Users/commands/useDeleteUser";
import ThirdButton from "../../../../reusable-components/third-button/ThirdButton";

interface IProps {
  users: UserDetailsDto[];
  setUserToEdit: (user: UserDetailsDto) => void;
  isDefaultProject?: boolean;
}

function ProjectEmployeesTable({ users, setUserToEdit, isDefaultProject }: IProps) {
  const [showPopup, setShowPopup] = useState(false);
  const userToDeleteId = useRef<string>("");
  const { t } = useTranslation("users");
  const { t: tCom } = useTranslation("common");
  const { mutate: deleteUser } = useDeleteUser();

  function handleDeleteUser() {
    deleteUser(userToDeleteId.current, {
      onSuccess: () => {
        setShowPopup(false);
        userToDeleteId.current = "";
      }
    });
  }

  return users.length < 1
    ? <div className={style.empty_container}>
      <p className={style.description}>{t("there-are-no-employees-assigned-to-this-project")}</p>
    </div>
    : <div className={style.container}>
      <div className={style.header_container}>
        <div className={style.table_cell}>{t("first-name")}</div>
        <div className={style.table_cell}>{t("last-name")}</div>
        <div className={`${style.table_cell} ${style.social_security_number}`}>{t(
          "social-security-number")}</div>
        <div className={`${style.table_cell} ${style.passport}`}>{t("passport-number")}</div>
        <div className={style.table_cell}>{t("e-mail")}</div>
        <div className={style.table_cell}></div>
      </div>
      {users.map((user) => (
        <div key={user.id}
             className={`${style.user_container} ${user.isActive ? style.active : ""}`}>
          <div className={style.table_cell}>{user.firstName}</div>
          <div className={style.table_cell}>{user.lastName}</div>
          <div className={`${style.table_cell} ${style.social_security_number}`}>{user.socialSecurityNumber}</div>
          <div className={`${style.table_cell} ${style.passport}`}>{user.passport}</div>
          <div className={style.table_cell}>{user.email.toLowerCase()}</div>
          <div className={style.table_cell}><ProjectEmployeesTableRowThreeDotsMenu
            onUnlinkFromProjectClick={() => {
              setUserToEdit(user);
            }}
            disabled={isDefaultProject}
          /></div>
        </div>
      ))}
      <Popup showPopup={showPopup} closePopup={() => setShowPopup(false)}>
        <div className={style.delete_user_container}>
          <p className={style.title}>{t("delete-user")}</p>
          <p dangerouslySetInnerHTML={{ __html: t("delete-user-warning-message") }} />
          <div className={style.buttons_container}>
            <ThirdButton style={style.cancel_button} onClick={() => {
              userToDeleteId.current = "";
              setShowPopup(false);
            }} name={tCom("cancel")} />
            <ThirdButton style={style.delete_button}
                         onClick={handleDeleteUser}
                         name={tCom("delete")} />
          </div>
        </div>
      </Popup>
    </div>;
}

export default ProjectEmployeesTable;
