import jwtDecode from "jwt-decode";
import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { TokenDecodedDto } from "../models/Token";
import authService from "../services/authService";
import browserStorageService from "../services/browserStorageService";
import { useNavigate } from "react-router-dom";
import useRoles from "../hooks/Users/useRoles";
import { Roles } from "../models/Roles";
import { Permissions } from "../models/Permissions";
import { stringEmpty } from "../../constants/emptyValues";
import favicons from "../../constants/favicons";
import { useQueryClient } from "@tanstack/react-query";
import useSetGdprConsent from "../hooks/Users/commands/useSetGdprConsent";
import { useTranslation } from "react-i18next";
import useSetLanguage from "../hooks/Users/commands/useSetLanguage";
import useGetUserSettings from "../hooks/Users/queries/useGetUserSettings";

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  accessToken: string;
  id: string;
  isLoggedIn: boolean;
  roles: string[];
  hasPermissionTo: (
    permissionType: keyof Permissions,
    permissions?: Permissions
  ) => boolean;
  company?: string;
  hasGdprConsent?: boolean;
  locale?: string;
}

export interface IUserContext {
  user: User;
  roles?: Roles;
  login: (
    accessToken: string,
    refreshToken: string,
    shouldRememberUser: boolean
  ) => void;
  logout: () => void;
  isLoading: boolean;
  permissionsChanged: boolean;
  setConsent: (consent: boolean) => void;
  changeLanguage: (language: string) => void;
  hasUserEmployeeRole: () => boolean;
}

const initialUserData = {
  email: "",
  firstName: "",
  lastName: "",
  accessToken: "",
  id: "",
  isLoggedIn: false,
  roles: [],
  hasPermissionTo: () => false,
  company: undefined,
  hasGdprConsent: false,
};

const initialPermissions = {
  dashboard: true,
  projects: false,
  "time-tracker": false,
  reports: false,
  users: false,
  settings: false,
  companies: false,
};

export const UserContext = createContext<IUserContext>({
  user: { ...initialUserData, hasPermissionTo: () => false },
  roles: {} as Roles,
  login: () => console.error("UserProvider is not loaded"),
  logout: () => console.error("UserProvider is not loaded"),
  isLoading: false,
  permissionsChanged: false,
  setConsent: async () => {
    return;
  },
  changeLanguage: () => {
    return;
  },
  hasUserEmployeeRole: () => false,
});

interface IProps {
  children: ReactNode;
}

const UserProvider: FC<IProps> = ({ children }: IProps) => {
  const [user, setUser] = useState<User>(initialUserData);
  const [permissionsChanged, setPermissionsChanged] = useState(false);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);
  const permissions = useRef<Permissions>(initialPermissions);
  const { data: roles, isLoading: isLoadingRoles } = useRoles();
  const { data: userSettings, isLoading: isLoadingSettings } =
    useGetUserSettings(user.id);
  const { mutate: setGdprConsent } = useSetGdprConsent();
  const { mutate: postLanguage } = useSetLanguage();
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();
  const favicon = useRef(document.querySelector("link[rel~='icon']"));
  const navigate = useNavigate();

  const isLoading = isLoadingRoles || isLoadingSettings || isLoadingPermissions;

  useEffect(() => {
    setUserPermissions(user.roles);
  }, [roles]);

  useEffect(() => {
    setUser((prev) => {
      return {
        ...prev,
        hasGdprConsent: userSettings?.hasGdprConsent,
        locale: userSettings?.language || undefined,
      };
    });
    if (userSettings?.language) {
      setLanguage(userSettings.language);
    }
  }, [userSettings]);

  const hasPermissionTo = (
    permissionType: keyof Permissions,
    currentPermission = permissions.current
  ) => {
    return currentPermission[permissionType];
  };

  const hasUserEmployeeRole = () => {
    return user.roles.some((role) => role === roles?.employee);
  };

  const setUserPermissions = (userRoles: string[]) => {
    if (roles) {
      permissions.current = {
        ...permissions.current,
        projects: userRoles.some((role) => role === roles?.admin),
        "time-tracker": userRoles.some((role) => role === roles?.employee),
        reports: userRoles.some((role) => role === roles?.admin),
        users: userRoles.some((role) => role === roles?.admin),
        settings: userRoles.some((role) => role === roles?.admin),
        companies: userRoles.some((role) => role === roles?.admin),
      };
    }
    setPermissionsChanged((prev) => !prev);
    setIsLoadingPermissions(false);

    return permissions.current;
  };

  const login = (
    accessToken: string,
    refreshToken: string,
    shouldRememberUser: boolean
  ) => {
    const decoded = jwtDecode<TokenDecodedDto>(accessToken);
    if (authService.isTokenValid(accessToken)) {
      const loggedUser: User = {
        firstName: decoded.given_name || stringEmpty,
        lastName: decoded.family_name || stringEmpty,
        isLoggedIn: true,
        accessToken: accessToken,
        id: decoded.sub ?? "",
        email: decoded.email,
        roles: typeof decoded.role === "object" ? decoded.role : [decoded.role],
        hasPermissionTo: hasPermissionTo,
        company: decoded.Company,
        hasGdprConsent: decoded.HasGdprConsent,
      };

      setUser(loggedUser);
      setUserPermissions(loggedUser.roles);

      browserStorageService.configureAfterLogin(
        accessToken,
        refreshToken,
        shouldRememberUser
      );
    }
  };

  const setLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const changeLanguage = async (language: string) => {
    postLanguage({ userId: user.id, language: language });
  };

  const setConsent = async (consent: boolean) => {
    setGdprConsent({
      userId: user.id,
      isGranted: consent,
    });
  };

  const logout = () => {
    browserStorageService.clearStorages();
    browserStorageService.removeAuthTokens();
    setUser(initialUserData);
    permissions.current = initialPermissions;
    setPermissionsChanged(false);
    favicon.current instanceof HTMLLinkElement &&
      (favicon.current.href = favicons.faviconLight);
    queryClient.clear();
    navigate("/login");
  };

  useEffect(() => {
    const accessToken = browserStorageService.getAccessToken();
    const refreshToken = browserStorageService.getRefreshToken();
    if (accessToken && refreshToken) {
      login(
        accessToken,
        refreshToken,
        browserStorageService.getShouldRememberUser()
      );
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        isLoading,
        permissionsChanged,
        setConsent,
        changeLanguage,
        roles,
        hasUserEmployeeRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
