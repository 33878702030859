import React from "react";
import style from "./weeklyReport.module.scss";
import GroupReportHeader from "./GroupReportHeader";
import { DayDetails } from "../../app/api/api-types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { formatTimeToDurations, getWeekDateFormat } from "../../app/utilities/timeUtilites";
import { useNavigate } from "react-router-dom";
import { useSettingsContext } from "../../app/context/SettingsContext";
import { Absences, getAbsenceIcon } from "../../constants/absences";
import { toast } from "react-hot-toast";

interface IProps {
  entries: DayDetails[];
  days: dayjs.Dayjs[];
  dashboardTimeRange: string;
}

function WeeklyReport({ entries, days, dashboardTimeRange }: IProps) {
  const { t } = useTranslation("reports");
  const { t: tTracker } = useTranslation("time-tracker");
  const { t: tFormat } = useTranslation("time-formats");
  const { isTimeEntryLocked } = useSettingsContext();
  const navigate = useNavigate();

  const getDayData = (day: dayjs.Dayjs) => {
    const dayDetails = entries.find(data => dayjs(data.date).isSame(day, "day"));

    if (dayDetails) {
      if (dayDetails.absence) {
        return getAbsence(dayDetails.absence as keyof Absences);
      }
      return `${formatTimeToDurations(dayDetails.trackedTime)} h`;
    }
    return "-";
  };
  const getAbsence = (absence: keyof Absences) => {
    return <div className={style.absence}>
      {getAbsenceIcon(absence as keyof Absences)} {tTracker(`${absence}-short`).toUpperCase()}
    </div>;

  };

  const getWeeklySummary = (dayDetails: DayDetails[]) => {
    return dayDetails.reduce((prev, actual) => prev + actual.trackedTime, 0);
  };

  function addWorkTime(day: dayjs.Dayjs) {
    navigate("/add-work-time", { state: { day: day.format(), option: dashboardTimeRange } });
  }

  function isTimeEntryLockedByAdmin(day: dayjs.Dayjs, showToast?: boolean) {
    const isLocked = entries.find(data => dayjs(data.date).isSame(day, "day"))?.isLockedByAdmin;
    if (isLocked && showToast) {
      toast.error(tTracker("time-entry-locked-by-admin"));
    }
    return isLocked;
  }

  return (
    <div className={style.container}>
      <GroupReportHeader
        title={{
          title: `${t("week")}: ${getWeekDateFormat(dayjs(days[0]),
            dayjs(days[days.length - 1]),
            tFormat)}`,
          totalTitle: t("hours-amount")
        }}
        total={formatTimeToDurations(getWeeklySummary(entries.filter(entry => dayjs(entry.date)
          .isBetween(dayjs(days[0]), days[days.length - 1].endOf("days")))))} />

      <div className={style.entries_container}>
        {days.map((day, index) =>
          <div onClick={() => (!isTimeEntryLocked(day) && !isTimeEntryLockedByAdmin(day, true))
            && addWorkTime(day.startOf("day"))}
               key={index}
               className={`${style.entry} ${isTimeEntryLocked(day) || isTimeEntryLockedByAdmin(day) ? style.locked : ""}`}>
            <p className={style.date}>{day.format(" dddd, DD.MM")}</p>
            <div className={style.daily_time}> {getDayData(day)}</div>
          </div>)}
      </div>
    </div>
  );
}

export default WeeklyReport;
