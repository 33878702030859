import "./app/api/axiosConfig";
import App from "./App";
import "./i18n";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UserProvider from "./app/context/UserContext";
import ErrorBoundary from "./ErrorBoundary";
import { toast, Toaster } from "react-hot-toast";
import i18next from "i18next";
import { BrowserRouter as Router } from "react-router-dom";
import React, { Suspense } from "react";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import uk from "date-fns/locale/uk";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import weekOfYear from "dayjs/plugin/weekOfYear";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import LoadingPage from "./reusable-components/loading-page/LoadingPage";
import { createRoot } from "react-dom/client";

registerLocale("pl", pl);
registerLocale("uk", uk);
dayjs.extend(updateLocale);
dayjs.extend(weekOfYear);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.setDefault("London");

dayjs.updateLocale("en", {
  weekStart: 1
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // error could be any type
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    onError: (error: any) => {
      const t = i18next.getFixedT(null, "common");
      if (
        typeof error === "object" &&
        error.response &&
        error.response.status === 401
      ) {
        window.location.assign("/login");
      } else {
        toast.error(t("default-error-message"), { duration: 4000 });
      }
    },
  }),
});

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <Suspense fallback={<LoadingPage />}>
        <UserProvider>
          <ErrorBoundary>
            <App />
            <Toaster
              toastOptions={{ duration: 4000 }}
              position={"bottom-right"}
            />
          </ErrorBoundary>
        </UserProvider>
      </Suspense>
    </Router>
  </QueryClientProvider>
);
