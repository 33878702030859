/* eslint-disable @typescript-eslint/no-non-null-assertion */
import style from "./loadingPage.module.scss";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import { createPortal } from "react-dom";

interface IProps {
  isActive?: boolean;
}

function LoadingPage({ isActive = true }: IProps) {
  return isActive ?
    createPortal(
      <div className={style.container}>
        <LoadingSpinner />
      </div>, document.getElementById("root")!)
    : <></>;
}

export default LoadingPage;
