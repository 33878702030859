import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useEffect, useState } from "react";
import useCheckTokenValidation from "../../app/hooks/Users/commands/useCheckTokenValidation";
import useResetPassword from "../../app/hooks/Users/commands/useResetPassword";
import { IdentityResult } from "../../app/api/api-types";
import {useLocation, useParams} from "react-router-dom";
import Input from "../../reusable-components/input/Input";
import ResetPasswordPageContainer
  from "../../reusable-components/reset-password-page-container/ResetPasswordPageContainer";
import NotificationBox from "../../reusable-components/notification-box/NotificationBox";

const PasswordResetNewPasswordForm = () => {
  const [t] = useTranslation("password-reset");
  const [isValid, setIsValid] = useState<boolean>();
  const { mutateAsync: checkTokenValidation } = useCheckTokenValidation();
  const { mutateAsync: resetPassword } = useResetPassword();
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessageText, setShowErrorMessageText] = useState("");


  const { id } = useParams();
  const searchParams = new URLSearchParams(useLocation().search);
  const token = searchParams.get("token");

  const checkPassword = (): boolean => {
    const regexSymbols = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.{8,})/;

    // check if passwords are equal
    if(passwordValues.password != passwordValues.repeatPassword)
    {
      setShowErrorMessageText(t("password-equals-error"));
      setShowErrorMessage(true);
      return false;
    }
    else {
      if (!regexSymbols.test(passwordValues.password)) {
        setShowErrorMessageText(t("password-complexity-error"));
        setShowErrorMessage(true);
        return false;
      }
      else {
        setShowErrorMessage(false);
        return true;
      }
    }
  }

  const handleSubmit = async () => {
    setShowInfoMessage(false);
    if(checkPassword()) {
      await resetPassword({
        userId: id ?? "",
        token: token ?? "",
        password: passwordValues.password,
        repeatPassword: passwordValues.repeatPassword,
      }).then((identityResult: IdentityResult) => {
        if (identityResult.succeeded === true) {
          window.location.href = "/login";
          return;
        } else {
          // show errors, TODO: in all windows, where it is needed
        }
      })
        .catch(() => {
          setShowErrorMessageText(t("password-complexity-error"));
          setShowErrorMessage(true);
        });
    }
  };

  const [passwordValues, setPasswordValues] = useState({
    password: "",
    repeatPassword: "",
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordValues({ ...passwordValues, [e.target.name]: e.target.value });
  };

  const onCancelClick = () => {
    window.location.href = "/login";
  }

  useEffect(() => {
    checkTokenValidation({ userId: id ?? "", token: token ?? "" }).then(
      (x) => {
        setIsValid(x);
      }
    );
  }, []);

  if (!isValid) {
    return (
      <ResetPasswordPageContainer
        headerText={t("link-expired")}
        onButton1Click={() => onCancelClick()}
        onButton2Click={() => onCancelClick()}
        button1Text={t("close")}
        button2Text={""}
        isButton1Disabled={false}
        isButton2Disabled={false}
        showButton2={false}>
        <NotificationBox
          notificationType={"error"}
          text={t("link-expired-message")}
        />
      </ResetPasswordPageContainer>
    )
  }

  return (
    <ResetPasswordPageContainer
      headerText={t("new-password")}
      onButton1Click={() => onCancelClick()}
      onButton2Click={() => handleSubmit()}
      button1Text={t("cancel")}
      button2Text={t("save")}
      isButton1Disabled={false}
      isButton2Disabled={false}
      showButton2={true}>
      <Input
        type={"password"}
        name={"password"}
        content={passwordValues.password}
        label={t("new-password")}
        onChange={onChange}
      />
      <Input
        type={"password"}
        name={"repeatPassword"}
        content={passwordValues.repeatPassword}
        label={t("repeat-new-password")}
        onChange={onChange}
      />
      { showInfoMessage && (<NotificationBox
        notificationType={"info"}
        text={t("password-complexity-info")}
      />)}
      { showErrorMessage && (<NotificationBox
        notificationType={"error"}
        text={errorMessageText}
      />)}
    </ResetPasswordPageContainer>
  );
};

export default PasswordResetNewPasswordForm;
