import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import style from "./user.module.scss";
import {
  CompanyDto,
  CreateUserByAdminCommand,
  ProjectDto,
  UserDetailsDto,
} from "../../../app/api/api-types";
import { useTranslation } from "react-i18next";
import Input from "../../../reusable-components/input/Input";
import ThirdButton from "../../../reusable-components/third-button/ThirdButton";
import useCreateUserByAdmin from "../../../app/hooks/Users/commands/useCreateUserByAdmin";
import useUpdateUser from "../../../app/hooks/Users/commands/useUpdateUser";
import useCreateUsersFromBatchFile
  from "../../../app/hooks/Support/commands/useCreateUsersFromBatchFile";
import { RegisteredUser } from "../../../app/models/User";
import RegisteredUserData from "../registered-user-data/RegisteredUserData";
import { toast } from "react-hot-toast";
import Dropdown, { IOption } from "../../../reusable-components/dropdown/Dropdown";
import useGetCompanies from "../../../app/hooks/Companies/queries/useGetCompanies";
import { createIOptionArray } from "../../../app/utilities/IOptionArrayUtilities";
import {
  createUserByAdminSchema,
  createUserByAdminValidationErrorMessages,
  UserValidation
} from "../../../app/validation-schemas/userValidation";
import { ValidationError } from "yup";
import useResetPasswordByAdmin from "../../../app/hooks/Users/commands/useResetPasswordByAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "../../../reusable-components/switch/Switch";
import { faUserCheck, faUserXmark } from "@fortawesome/free-solid-svg-icons";
import useGetProjectsAssignedToCompany
  from "../../../app/hooks/Companies/queries/useGetProjectsAssignedToCompany";
import TwoLinesTitle from "../../../reusable-components/two-lines-title/TwoLinesTitle";

interface IProps {
  closePopup: () => void;
  userToEdit?: UserDetailsDto;
}

const emptyUser: UserDetailsDto = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  projectTitle: "",
  socialSecurityNumber: "",
  passport: "",
  isActive: true,
};

function User({ closePopup, userToEdit }: IProps) {
  const [user, setUser] = useState<UserDetailsDto>(userToEdit || emptyUser);
  const [registeredUser, setRegisteredUser] = useState<RegisteredUser>();
  const [company, setCompany] = useState("");
  const [defaultCompany, setDefaultCompany] = useState<IOption>();
  const [companiesAsIOptionArray, setCompaniesAsIOptionArray] = useState<IOption[]>([]);
  const [companyId, setCompanyId] = useState<string>();
  const [projectTitle, setProjectTitle] = useState("");
  const [defaultProject, setDefaultProject] = useState<IOption>();
  const [projectsAsIOptionArray, setProjectsAsIOptionArray] = useState<IOption[]>([]);
  const { t: tValid } = useTranslation("validation");
  const UserSchema = createUserByAdminSchema(tValid);
  const [errorMessages, setErrorMessages] = useState(createUserByAdminValidationErrorMessages);
  const prevCompanyId = useRef<string>();
  const { t } = useTranslation("users");
  const { t: tCom } = useTranslation("common");
  const { data: companies = [] } = useGetCompanies();
  const { data: projects = [] } = useGetProjectsAssignedToCompany(companyId);
  const { mutateAsync: createUser } = useCreateUserByAdmin();
  const { mutateAsync: resetPassword } = useResetPasswordByAdmin();
  const { mutate: updateUser } = useUpdateUser();
  const { mutate: createUsersFromBatchFile } = useCreateUsersFromBatchFile();

  useEffect(() => {
    if (companies.length > 0) {
      const data = companies.map(company => {
        return {
          id: company.shortName,
          name: `${company.shortName}-${company.name}`
        };
      });
      setCompaniesAsIOptionArray(createIOptionArray(data, "id", "name"));

      if (!userToEdit) {
        const defaultCompany = companies.find(company => company.shortName === "WL");
        setCompanyForDropdown(defaultCompany);
      } else {
        const company = companies.find(company => company.name === userToEdit.company);
        setCompanyForDropdown(company);
      }
    }
  }, [companies]);

  useEffect(() => {
    if (projects.length > 0) {
      setProjectsAsIOptionArray(createIOptionArray(projects, "id", "title"));
      if (!userToEdit) {
        const defaultProject = projects.find(project => project.isDefault);
        setProjectForDropdown(defaultProject);
      } else if (companyId !== prevCompanyId.current) {
        const project = projects.find(project => project.isDefault);
        setProjectForDropdown(project);
      } else {
        const project = projects.find(project => project.title === userToEdit.projectTitle);
        setProjectForDropdown(project);
      }
    }
  }, [projects]);

  function setProjectForDropdown(defaultProject?: ProjectDto) {
    if (defaultProject) {
      setUser(prev => {
        return { ...prev, projectTitle: defaultProject.title };
      });
      setDefaultProject({ value: defaultProject.id, label: defaultProject.title });
      setProjectTitle(defaultProject.title);
    }
  }

  function setCompanyForDropdown(defaultCompany?: CompanyDto) {
    if (defaultCompany) {
      setUser(prev => {
        return { ...prev, company: defaultCompany.shortName };
      });
      setDefaultCompany({
        value: defaultCompany.shortName,
        label: `${defaultCompany.shortName}-${defaultCompany.name}`
      });
      setCompany(`${defaultCompany?.shortName}-${defaultCompany.name}`);
      setCompanyId(defaultCompany.id);
      prevCompanyId.current = defaultCompany.id;
    }
  }

  const getUserFields = () => {
    return Object.keys(user).map((key, index) => {
      const userKey = key as keyof UserDetailsDto;
      if (userKey === "firstName" || userKey === "lastName" || userKey === "email" || userKey === "socialSecurityNumber" || userKey === "passport") {
        return (<Fragment key={index}>
          <Input inputClassName={style.input}
                 label={t(userKey)}
                 content={user[userKey]}
                 errorMessage={errorMessages[userKey]}
                 disabled={!!(user.id && userKey === "email")}
                 onChange={(e) => setUser(prev => {
                   return { ...prev, [userKey]: e.target.value };
                 })}
                 onFocus={() => setErrorMessages(prev => {
                   return { ...prev, [userKey]: "" };
                 })} />
        </Fragment>);
      }
    });
  };

  async function addUser() {
    const newUser = await createUser({ ...user } as CreateUserByAdminCommand);

    if (newUser) {
      setUser(prev => {
        return { ...prev, id: newUser.id };
      });
      setRegisteredUser({
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          email: newUser.userName,
          password: newUser.password,
          hasEmailSent: newUser.hasEmailSent
        }
      );
    }
  }

  async function generatePassword() {
    const updatedCredentials = await resetPassword(user.id);
    setRegisteredUser({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: updatedCredentials.password
    });
  }

  async function saveUser() {
    try {
      await UserSchema.validate(user, { abortEarly: false });

      if (!user.id) {
        await addUser();
      } else {
        updateUser(user, {
          onSuccess: () => {
            toast.success(t("updated-user"));
            closePopup();
          }
        });
      }
    } catch (errors) {
      if (errors instanceof ValidationError) {
        const newErrorMessagesKeys = Object.keys(createUserByAdminValidationErrorMessages);
        const newErrorMessages = {} as UserValidation;
        errors.inner.forEach((error) => {
          if (error.path && newErrorMessagesKeys.includes(error.path)) {
            const key = error.path as keyof typeof createUserByAdminValidationErrorMessages;
            newErrorMessages[key] = error.message;
          }
        });
        setErrorMessages(newErrorMessages);
      }
    }
  }

  function handleSetCompany(value: IOption) {
    setUser(prev => {
      return { ...prev, company: value.value };
    });
    setCompany(value.label);
    prevCompanyId.current = companyId;
    const newCompanyId = companies.find(company => company.shortName === value.value)?.id;
    setCompanyId(newCompanyId);
  }

  function clearErrorMessages() {
    const emptyMessages = errorMessages;
    for (const message in errorMessages) {
      emptyMessages[message as keyof UserValidation] = "";
    }
    setErrorMessages(emptyMessages);
  }

  function clearRegisteredUser() {
    const company = companies.find(company => company.shortName === user.company);
    company && setDefaultCompany({
      value: company.shortName,
      label: `${company.shortName}-${company.name}`
    });
    setRegisteredUser(undefined);
    clearErrorMessages();

  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      createUsersFromBatchFile(e.target.files[0], {
        onSuccess: (res) => {
          getFile(res);
          closePopup();
        },
        onError: () => {
          toast.error(tCom("users-registered-error"));
        }
      });
    }
  };

  function getFile(data: File) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download",
      tCom("created-users-filename") + ".csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    toast.success(tCom("registered-users-file-downloaded"));
  }

  function handleSetProject(value: IOption) {
    setUser(prev => {
      return { ...prev, projectTitle: value.label };
    });
    setProjectTitle(value.label);
  }

  return (
    registeredUser ? (
        <RegisteredUserData user={registeredUser}
                            clearRegisteredUser={clearRegisteredUser}
                            closePopup={closePopup} />) :
      (
        <>
          <div className={style.container}>
            <p className={style.title}>{user.id ? t("edit-employee") : t("add-employee")}</p>
            <TwoLinesTitle title={t("fill-data")} />
            <div className={style.form}>
              {getUserFields()}
              <div className={style.dropdown_container}>
                <p className={style.label}>{t("company")}</p>
                <Dropdown onClick={(value) => handleSetCompany(value)}
                          valueStyle={style.dropdown_value}
                          wrapperClassName={style.dropdown_wrapper}
                          dropdownClassName={style.dropdown}
                          dropdownOptionsClassName={style.dropdown_options}
                          value={company}
                          defaultValue={defaultCompany}
                          editMode={true}
                          options={companiesAsIOptionArray}
                />
              </div>
              <div className={style.dropdown_container}>
                <p className={style.label}>{t("project")}</p>
                <Dropdown onClick={(value) => handleSetProject(value)}
                          valueStyle={style.dropdown_value}
                          wrapperClassName={style.dropdown_wrapper}
                          dropdownClassName={style.dropdown}
                          dropdownOptionsClassName={style.dropdown_options}
                          value={projectTitle}
                          defaultValue={defaultProject}
                          editMode={true}
                          options={projectsAsIOptionArray}
                />
              </div>
            </div>
            {user.id ? <>
                <TwoLinesTitle title={t("additional-settings")} />
                <div className={style.user_state_container}>
                  <p className={style.label}>{t("status")}</p>
                  <div className={style.switch_container}>
                    <FontAwesomeIcon icon={faUserXmark}
                                     className={!user.isActive ? style.active : ""} />
                    <Switch className={style.switch}
                            state={user.isActive}
                            toggle={() => setUser(prev => {
                              return { ...prev, isActive: !prev.isActive };
                            })} />
                    <FontAwesomeIcon icon={faUserCheck}
                                     className={user.isActive ? style.active : ""} />
                  </div>
                </div>
                <p className={style.upload} onClick={() => generatePassword()}>{t(
                  "generate-password")}</p></>
              : <>
                <TwoLinesTitle title={t("or")} />
                <input accept=".csv"
                       className={style.hidden_input}
                       type="file"
                       id="selectFile"
                       onChange={handleFileChange} />
                <label htmlFor="selectFile" className={style.upload_file_label}><p>{t(
                  "upload-csv-file")}</p></label></>}

          </div>
          <div className={style.button_container}>
            <ThirdButton name={tCom("save")} onClick={() => saveUser()} style={style.button} />
          </div>
        </>
      )
  );
}

export default User;
