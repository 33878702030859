import React from "react";

interface IProps {
  logoClassName?: string;
}

function Logo({ logoClassName }: IProps) {
  return <>
    <svg viewBox="0 0 153 28"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         className={`worklife-logo ${logoClassName}`}>
      <g className="worklife-logo__text">
        <path d="M46.3271 10.2168H48.7425L51.5711 21.7434H51.9898L55.0291 10.2168H57.7531L60.7924 21.7434H61.2111L64.0397 10.2168H66.4551L62.7851 24.8873H59.4304L56.6018 13.8841H56.1831L53.3531 24.8873H49.9985L46.3271 10.2168Z"
              fill="#DCEBEB"></path>
        <path d="M68.0879 17.5488C68.0879 13.0442 71.2319 10.0049 75.1085 10.0049C78.9852 10.0049 82.1291 13.0442 82.1291 17.5488C82.1291 22.0534 78.9852 25.0941 75.1085 25.0941C71.2319 25.0941 68.0879 22.0548 68.0879 17.5488ZM79.9285 17.5488C79.9285 14.1941 77.8325 12.1008 75.1085 12.1008C72.3845 12.1008 70.2885 14.1968 70.2885 17.5488C70.2885 20.9008 72.3845 22.9982 75.1085 22.9982C77.8325 22.9982 79.9285 20.9021 79.9285 17.5488Z"
              fill="#DCEBEB"></path>
        <path d="M85.4043 10.2168H92.0063C94.4216 10.2168 96.3002 12.1034 96.3002 14.9321C96.3002 17.7607 94.4136 19.6474 92.0063 19.6474H91.4816L96.7149 24.8873H93.8916L88.6583 19.6474H87.6103V24.8873H85.4097L85.4043 10.2168ZM91.907 17.5514C93.0596 17.5514 94.1076 16.5047 94.1076 14.9321C94.1076 13.3594 93.0596 12.3127 91.907 12.3127H87.613V17.546L91.907 17.5514Z"
              fill="#DCEBEB"></path>
        <path d="M99.6875 10.2168H101.888V16.9261L107.504 10.2168H110.271L104.193 17.4467L110.691 24.8873H107.921L101.883 17.9714V24.8873H99.6875V10.2168Z"
              fill="#DCEBEB"></path>
        <path d="M112.988 10.2168H115.189V22.7914H122.314V24.8873H112.988V10.2168Z"
              fill="#DCEBEB"></path>
        <path d="M124.888 10.2168H127.087V24.8873H124.888V10.2168Z" fill="#DCEBEB"></path>
        <path d="M131.058 10.2168H140.488V12.3127H133.258V16.5048H140.07V18.5994H133.258V24.8873H131.058V10.2168Z"
              fill="#DCEBEB"></path>
        <path d="M143.36 10.2168H153V12.3127H145.561V16.5048H152.582V18.5994H145.561V22.7914H153V24.8873H143.36V10.2168Z"
              fill="#DCEBEB"></path>
      </g>
      <g className="worklife-logo__sygnet">
        <path d="M32.7466 22.584V22.9637H32.0099V24.884H31.5537V22.9637H30.8184V22.584H32.7466ZM35.2317 24.884L35.1445 23.53C35.1365 23.3529 35.1365 23.1275 35.1284 22.8873H35.1029C35.0439 23.0912 34.9755 23.3609 34.9084 23.573L34.4937 24.849H34.0227L33.6081 23.5394C33.5665 23.3609 33.4981 23.0912 33.4484 22.8873H33.4229C33.4229 23.0993 33.4135 23.3194 33.4055 23.53L33.3209 24.884H32.8808L33.0499 22.584H33.7356L34.1381 23.7085C34.1891 23.8856 34.2307 24.0547 34.2898 24.2922H34.2992C34.3582 24.0802 34.4092 23.8896 34.4602 23.7165L34.8627 22.584H35.5216L35.7001 24.884H35.2317Z"
              fill="#DCEBEB"></path>
        <g className="sygnet-anim">
          <path className="sygnet-anim__sizer"
                d="M1.04531 20.7289L6.34031 15.4339C6.64237 15.1322 6.81227 14.723 6.81264 14.296V5.54306C6.81294 5.42182 6.77722 5.30326 6.71 5.20236C6.64279 5.10145 6.54711 5.02281 6.43512 4.97638C6.32313 4.92994 6.19986 4.91778 6.08097 4.94152C5.96208 4.96525 5.85291 5.02377 5.76733 5.10964L0.470997 10.406C0.169754 10.7076 0.000377128 11.1163 1.84967e-06 11.5425V20.2955C-0.000296189 20.4167 0.0354293 20.5354 0.102644 20.6363C0.169859 20.7372 0.265534 20.8158 0.377528 20.8623C0.489522 20.9087 0.612786 20.9208 0.731679 20.897C0.850573 20.8733 0.959735 20.8148 1.04531 20.7289Z"></path>
          <path className="sygnet-anim__line"
                d="M1.04531 20.7289L6.34031 15.4339C6.64237 15.1322 6.81227 14.723 6.81264 14.296V5.54306C6.81294 5.42182 6.77722 5.30326 6.71 5.20236C6.64279 5.10145 6.54711 5.02281 6.43512 4.97638C6.32313 4.92994 6.19986 4.91778 6.08097 4.94152C5.96208 4.96525 5.85291 5.02377 5.76733 5.10964L0.470997 10.406C0.169754 10.7076 0.000377128 11.1163 1.84967e-06 11.5425V20.2955C-0.000296189 20.4167 0.0354293 20.5354 0.102644 20.6363C0.169859 20.7372 0.265534 20.8158 0.377528 20.8623C0.489522 20.9087 0.612786 20.9208 0.731679 20.897C0.850573 20.8733 0.959735 20.8148 1.04531 20.7289Z"
                fill="#DCEBEB"></path>
        </g>
        <g className="sygnet-anim">
          <path className="sygnet-anim__sizer"
                d="M9.5707 23.8891L18.9637 14.4961C19.2658 14.1944 19.4357 13.7851 19.4361 13.3582V4.60523C19.4361 4.48411 19.4002 4.36568 19.3329 4.26498C19.2656 4.16429 19.1699 4.08586 19.0579 4.03959C18.946 3.99332 18.8228 3.98128 18.7041 4.00506C18.5853 4.02884 18.4763 4.08733 18.3908 4.17312L8.99773 13.5662C8.69566 13.8679 8.52577 14.2771 8.52539 14.7041V23.457C8.52536 23.5782 8.56128 23.6966 8.62859 23.7973C8.69591 23.898 8.7916 23.9764 8.90354 24.0227C9.01548 24.0689 9.13863 24.081 9.2574 24.0572C9.37616 24.0334 9.4852 23.9749 9.5707 23.8891Z"
                fill="#DCEBEB"></path>
          <path className="sygnet-anim__line"
                d="M9.5707 23.8891L18.9637 14.4961C19.2658 14.1944 19.4357 13.7851 19.4361 13.3582V4.60523C19.4361 4.48411 19.4002 4.36568 19.3329 4.26498C19.2656 4.16429 19.1699 4.08586 19.0579 4.03959C18.946 3.99332 18.8228 3.98128 18.7041 4.00506C18.5853 4.02884 18.4763 4.08733 18.3908 4.17312L8.99773 13.5662C8.69566 13.8679 8.52577 14.2771 8.52539 14.7041V23.457C8.52536 23.5782 8.56128 23.6966 8.62859 23.7973C8.69591 23.898 8.7916 23.9764 8.90354 24.0227C9.01548 24.0689 9.13863 24.081 9.2574 24.0572C9.37616 24.0334 9.4852 23.9749 9.5707 23.8891Z"
                fill="#DCEBEB"></path>
        </g>
        <g className="sygnet-anim">
          <path className="sygnet-anim__sizer"
                d="M19.6748 27.5433L36.7165 10.5016C37.0181 10.1997 37.1875 9.79043 37.1875 9.36371V0.612048C37.1875 0.491085 37.1517 0.372871 37.0845 0.272258C37.0174 0.171645 36.9219 0.0931825 36.8102 0.0468011C36.6985 0.000419774 36.5755 -0.0118528 36.4569 0.0116165C36.3382 0.0350859 36.2292 0.093212 36.1435 0.178629L19.1019 17.2203C18.8003 17.5222 18.6309 17.9315 18.6309 18.3582V27.1112C18.6317 27.2317 18.6681 27.3494 18.7355 27.4494C18.8028 27.5494 18.8982 27.6273 19.0096 27.6734C19.121 27.7195 19.2435 27.7318 19.3618 27.7086C19.4801 27.6855 19.589 27.628 19.6748 27.5433Z"
                fill="#DCEBEB"></path>
          <path className="sygnet-anim__line"
                d="M19.6748 27.5433L36.7165 10.5016C37.0181 10.1997 37.1875 9.79043 37.1875 9.36371V0.612048C37.1875 0.491085 37.1517 0.372871 37.0845 0.272258C37.0174 0.171645 36.9219 0.0931825 36.8102 0.0468011C36.6985 0.000419774 36.5755 -0.0118528 36.4569 0.0116165C36.3382 0.0350859 36.2292 0.093212 36.1435 0.178629L19.1019 17.2203C18.8003 17.5222 18.6309 17.9315 18.6309 18.3582V27.1112C18.6317 27.2317 18.6681 27.3494 18.7355 27.4494C18.8028 27.5494 18.8982 27.6273 19.0096 27.6734C19.121 27.7195 19.2435 27.7318 19.3618 27.7086C19.4801 27.6855 19.589 27.628 19.6748 27.5433Z"
                fill="#DCEBEB"></path>
        </g>
      </g>
    </svg>

  </>;
}

export default Logo;
