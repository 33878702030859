/* eslint-disable camelcase */
import jwtDecode from "jwt-decode";
import { TokenDecodedDto, TokenErrorResponseDto, } from "../models/Token";
import browserStorageService from "./browserStorageService";
import { AuthRequests } from "../requests/authRequests";

export interface IUserTokens {
  accessToken: string;
  refreshToken: string;
}

const secondsThresholdToRefreshToken = () => {
  // amount of minutes under which token will always be refreshed, if expiration time is smaller
  const minMinutesValue = 60 * 8;
  // first threshold to refresh token
  const maxMinutesValue = 60 * 12;
  return (
    Math.floor(
      Math.random() * (maxMinutesValue - minMinutesValue) + minMinutesValue
    ) * 60
  );
};

const secondsToTokenExpiration = (accessToken: string) => {
  return Math.round(
    jwtDecode<TokenDecodedDto>(accessToken).exp ?? 0 - Date.now() / 1000
  );
};

const isTokenActive = (accessToken: string): boolean => {
  const expirationTime = jwtDecode<TokenDecodedDto>(accessToken).exp;
  return !!expirationTime && expirationTime > Date.now() / 1000;
};

const isTokenExpiringSoon = (accessToken: string) => {
  return (
    secondsToTokenExpiration(accessToken) < secondsThresholdToRefreshToken()
  );
};

const acquireAccessToken = async (
  email: string,
  password: string
): Promise<IUserTokens> => {
  const accessToken = browserStorageService.getAccessToken();
  const refreshToken = browserStorageService.getRefreshToken();
  if (accessToken && refreshToken && isTokenActive(accessToken)) {
    return {
      accessToken,
      refreshToken,
    } as IUserTokens;
  }
  try {
    const responseData = await AuthRequests.acquireAccessToken(email, password);

    return {
      accessToken: responseData.access_token,
      refreshToken: responseData.refresh_token,
    } as IUserTokens;
  } catch (error) {
    if (error.response) {
      const errorResponse: TokenErrorResponseDto = error.response.data;

      throw new Error(errorResponse.error_description);
    } else {
      throw error;
    }
  }
};

const isTokenValid = (accessToken?: string) => {
  if (!accessToken) {
    const storedAccessToken = browserStorageService.getAccessToken();
    if (!storedAccessToken) {
      return false;
    }
    accessToken = storedAccessToken;
  }

  const isValid = isTokenActive(accessToken);

  if (!isValid) {
    browserStorageService.removeAuthTokens();
    return false;
  }

  return true;
};

const getFreshToken = async (refreshToken: string) => {
  try {
    const response = await AuthRequests.refreshTokens(refreshToken);
    if (!response || !response.access_token || !response.refresh_token) {
      return;
    }

    browserStorageService.setAuthTokens(
      response.access_token,
      response.refresh_token
    );
  } catch (error) {
    if (error.response) {
      const errorResponse: TokenErrorResponseDto = error.response.data;
      throw new Error(errorResponse.error_description);
    } else {
      throw error;
    }
  }
};

const manageTokenLifetime = async () => {
  const accessToken = browserStorageService.getAccessToken();
  const refreshToken = browserStorageService.getRefreshToken();
  if (!accessToken || !refreshToken) {
    return;
  }

  if (isTokenExpiringSoon(accessToken)) {
    getFreshToken(refreshToken);
  }
};

const authService = {
  acquireAccessToken,
  isTokenValid,
  manageTokenLifetime,
  getFreshToken
};

export default authService;
