import style from "./groupReport.module.scss";
import { useTranslation } from "react-i18next";
import React from "react";
import { Group, TotalInColumns } from "../../app/models/Reports";
import dayjs from "dayjs";

interface IProp {
  groupingName?: string;
  columns?: string[];
  total?: TotalInColumns;
  data?: Group[];
  totalColumnName?: string;
  editTimeEntry: (employeeId: string, employeeName: string, day: string) => void;
}

const GroupReport = ({ groupingName, columns, data, totalColumnName, editTimeEntry }: IProp) => {
  const { t } = useTranslation("time-tracker");
  return (
    <div className={style.table}>
      <div className={style.header_container}>
        <div className={style.header_name}>
          {groupingName}
        </div>
        {columns?.map((column, index) => (
          <div key={index} className={style.header}>{column}</div>))}
        <div className={style.header_total}>{totalColumnName ? totalColumnName : t("total")}</div>
      </div>
      {data?.map((group, index) => (
        <div key={index} className={style.group}>
          <div className={style.group_name}>
            <p className={style.name}>{group.name}</p>
          </div>
          {
            group.data?.map((data, index) =>
              <div onClick={() => editTimeEntry(group.employeeId,
                group.name,
                dayjs(data.day).format("YYYY MM DD HH:mm"))} key={index}
                   className={`${style.group_item} ${data.isHoliday ? style.holiday : ""} ${data.isAbsence ? style.absence : ""} ${data.isModified ? style.modified : ""}`}>{data.workTime}</div>)
          }
          <div className={style.group_total}>{group.totalInGroup}</div>
        </div>
      ))}
    </div>
  );
};
export default GroupReport;
