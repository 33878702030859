import React, { Fragment } from "react";
import style from "./settings.module.scss";
import { useSettingsContext } from "../../app/context/SettingsContext";
import { useTranslation } from "react-i18next";
import Setting from "./setting/Setting";
import { settings } from "../../constants/Settings";
import dayjs from "dayjs";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function Settings() {
  const { t } = useTranslation("settings");
  const { t: tCom } = useTranslation("common");
  const {
    timeEntryLockTime,
    timeChangeValue,
    defaultWorkTimes,
    addTime,
    subtractTime
  } = useSettingsContext();

  const getHoursFromMilliseconds = (time: number) => {
    return time ? dayjs.duration(time, "milliseconds").asHours() : 0;
  };

  return (
    <div className={style.container}>
      <BreadcrumbsItem to={"/settings"}>{tCom("settings")}</BreadcrumbsItem>
      <p className={style.header}>{t("time-limit-settings")}</p>
      <div className={style.settings_container}>
        <Setting description={t("time-entry-lock-time")}
                 value={`${getHoursFromMilliseconds(timeEntryLockTime)} h`}
                 addTime={() => addTime("lockTimeEntrySetting",
                   settings.timeTrackerChangeValues.timeEntryLockTimeChangeHours)}
                 subtractTime={() => subtractTime("lockTimeEntrySetting",
                   settings.timeTrackerChangeValues.timeEntryLockTimeChangeHours)}
                 disabledSubtractButton={getHoursFromMilliseconds(timeEntryLockTime) <= settings.timeTrackerSettingsMinValues.timeEntryLockTimeHours} />
      </div>
      <p className={style.header}>{t("buttons-settings")}</p>
      <div className={style.settings_container}>
        {defaultWorkTimes.map((defaultWorkTime, index) =>
          <Fragment key={index}>
            <Setting description={t("hours-amount-on-button", { buttonNumber: index + 1 })}
                     value={`${defaultWorkTime} h`}
                     addTime={() => addTime("defaultWorkTime",
                       settings.timeTrackerChangeValues.defaultWorkTimeChangeHours, index)}
                     subtractTime={() => subtractTime("defaultWorkTime",
                       settings.timeTrackerChangeValues.defaultWorkTimeChangeHours, index)}
                     disabledSubtractButton={defaultWorkTime <= settings.timeTrackerSettingsMinValues.timeEntryDefaultWorkTimeHours} />
          </Fragment>)}
        <Setting description={t("+--button-minutes-amount")}
                 value={`${timeChangeValue} min`}
                 addTime={() => addTime("timeEntryChangeValue",
                   settings.timeTrackerChangeValues.timeChangeMinutes)}
                 subtractTime={() => subtractTime("timeEntryChangeValue",
                   settings.timeTrackerChangeValues.timeChangeMinutes)}
                 disabledSubtractButton={timeChangeValue <= settings.timeTrackerSettingsMinValues.timeEntryChangeValueMinutes} />
      </div>
    </div>
  );
}

export default Settings;
