import style from "./checkbox.module.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  label?: string;
  tooltip?: string;
  style?: string;
  handleChecked: (checkValue: boolean) => void;
  checked?: boolean;
}

function Checkbox(prop: IProps) {
  const [checked, setChecked] = useState<boolean>(prop.checked ?? false);

  useEffect(() => {
    if (prop.checked !== undefined) {
      setChecked(prop.checked);
    }
  }, [prop.checked]);

  return (
    <div
      className={`${style.container} ${prop.style ? prop.style : ""}`}
      onClick={() => {
        setChecked((prev) => !prev);
        prop.handleChecked(!checked);
      }}
    >
      <div className={`${style.checkbox} ${checked ? style.checked : ""}`}>
        {checked && (
          <FontAwesomeIcon icon={faCheck} />
        )}
      </div>
      <div className={style.label}>{prop.label}</div>
    </div>
  );
}

export default Checkbox;
