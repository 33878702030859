import { useQuery } from "@tanstack/react-query";
import { UsersData } from "../../../models/User";
import { queryKeys } from "../../../../constants/queryKeys";
import { UsersApiFactory } from "../../../api/api-types";

export const fetchFunction = (data: UsersData) => {
  return UsersApiFactory()
    .getPaginatedUsers(
      data.pageNumber,
      data.pageSize,
      data.companyId,
      data.projectId,
      data.status,
      data.searchValue
    )
    .then((res) => res.data);
};

export default function useGetPaginatedUsers(data: UsersData, enabled = true) {
  return useQuery({
    queryKey: [queryKeys.users.paginatedUsers, data],
    queryFn: () => fetchFunction(data),
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
}
