import React, { Fragment } from "react";
import style from "./absencesInfo.module.scss";
import AddTimeComponentTitle from "../add-time-component-title/AddTimeComponentTitle";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../constants/queryKeys";
import { AbsenceDto } from "../../../app/api/api-types";
import { Absences, getAbsenceIcon } from "../../../constants/absences";

function AbsencesInfo() {
  const { t } = useTranslation("time-tracker");
  const queryClient = useQueryClient();
  const absences = queryClient.getQueryData(
    [queryKeys.timeTracker.absences]
  ) as AbsenceDto[];
  return (
    <div className={style.container}>
      <AddTimeComponentTitle
        title={t("types-of-absences")}
        titleClassName={style.absences_title}
      />
      {absences.length > 0 && (
        <div className={style.absences_container}>
          {absences.map((absence) => (
            <Fragment key={absence.id}>
              <div className={style.absence_short}>
                {getAbsenceIcon(absence.absenceType as keyof Absences)}
                <p> {t(`${absence.absenceType}-short`).toUpperCase()}</p>
              </div>
              <div className={style.absence_full_name}>
                <p>-</p>
                {t(absence.absenceType)}
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

export default AbsencesInfo;
