import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  TimeTrackerApiFactory,
  UpdateTimeEntryCommand,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";

export default function useUpdateTimeEntry() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateTimeEntryCommand) =>
      TimeTrackerApiFactory()
        .updateTimeEntry(data.timeEntryId, data)
        .then((res) => res.data),
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.reports.timePeriodSummary, variables.userId],
      }),
    onError: (data: AxiosError) => {
      toast.error(data.message);
    },
  });
}
