import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useState } from "react";
import NotificationBox from "../../reusable-components/notification-box/NotificationBox";
import useSendMailWithLinkToResetPassword from "../../app/hooks/Users/commands/useSendMailWithLinkToResetPassword";
import ResetPasswordPageContainer
  from "../../reusable-components/reset-password-page-container/ResetPasswordPageContainer";
import Input from "../../reusable-components/input/Input";

const PasswordResetMailConfirmationForm = () => {
  const [emailValue, setEmailValue] = useState({
    email: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [t] = useTranslation("password-reset");
  const { mutateAsync: sendMailWithLinkToResetPassword } =
    useSendMailWithLinkToResetPassword();

  const handleSubmit = async () => {
    await sendMailWithLinkToResetPassword(emailValue.email)
      .then(() => {
        setShowSuccessMessage(true);
        setShowErrorMessage(false);
      })
      .catch(() => {
        setShowErrorMessage(true);
        setShowSuccessMessage(false);
      });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowSuccessMessage(false);
    setEmailValue({ ...emailValue, [e.target.name]: e.target.value });
  };

  const onCloseNotificationBox = () => {
    if (showSuccessMessage) {
      // temporary solution. Before decision about strategy how handle redirecting in UI
      window.location.href = "/login";
    } else {
      setShowErrorMessage(false);
    }
  };

  const onCancelClick = () => {
    window.location.href = "/login";
  }

  return (
    <ResetPasswordPageContainer
      headerText={t("password-reset")}
      onButton1Click={() => onCancelClick()}
      onButton2Click={() => handleSubmit()}
      button1Text={ showSuccessMessage ? t("close") : t("cancel")}
      button2Text={t("reset")}
      isButton1Disabled={false}
      isButton2Disabled={false}
      showButton2={!showSuccessMessage}>
      <Input
        name={"email"}
        content={emailValue.email}
        label={t("email")}
        onChange={onChange}
      />
      {showSuccessMessage && (
        <NotificationBox
          notificationType={"info"}
          text={t("mail-sent")}
          onCloseIconClick={onCloseNotificationBox}
        />
      )}
      {showErrorMessage && (
        <NotificationBox
          notificationType={"error"}
          text={t("account-not-found")}
          onCloseIconClick={onCloseNotificationBox}
        />
      )}
    </ResetPasswordPageContainer>
  );
};

export default PasswordResetMailConfirmationForm;
