import React from "react";
import style from "./absenceType.module.scss";
import { useTranslation } from "react-i18next";
import AddTimeComponentTitle from "../add-time-component-title/AddTimeComponentTitle";
import useGetAbsenceTypes from "../../../app/hooks/TimeTracker/queries/useGetAbsenceTypes";
import SecondaryButton from "../../../reusable-components/secondary-button/SecondaryButton";
import { Absences, getAbsenceIcon } from "../../../constants/absences";

interface IProps {
  setAbsence: (absenceId: number) => void;
  chosenAbsenceId?: number;
  isLockedByAdmin: boolean;
}

function AbsenceType({ setAbsence, chosenAbsenceId, isLockedByAdmin }: IProps) {
  const { t } = useTranslation("time-tracker");
  const { data: absences } = useGetAbsenceTypes();

  return (
    <div className={style.container}>
      <AddTimeComponentTitle title={t("absence")} showInfo={true} />
      <div className={style.buttons_container}>
        {absences?.map((absence) =>
          <SecondaryButton key={absence.id}
                           disabled={isLockedByAdmin}
                           style={style.button}
                           icon={getAbsenceIcon(absence.absenceType as keyof Absences)}
                           active={absence.id === chosenAbsenceId}
                           name={t(`${absence.absenceType}-short`)
                             .toUpperCase()}
                           onClick={() => setAbsence(absence.id)} />)}
      </div>
    </div>
  );
}

export default AbsenceType;
