import style from "./groupReportHeader.module.scss";

interface IProp {
  monthly?: boolean;
  title: GroupReportHeader;
  total: string;
}

interface GroupReportHeader {
  title: string,
  totalTitle: string,
}

function GroupReportHeader({ title, total, monthly }: IProp) {

  return (
    <div className={`${style.container} ${monthly ? style.month : ""}`}>
      <div className={style.header_container}>
        <p className={style.title}>{title.title}</p>
        <div className={style.total}>
          <p className={style.total_week}>{title.totalTitle}:</p>
          <p className={style.total_time}>{`${total} h`}</p>
        </div>
      </div>
      <div className={style.line} />
    </div>
  );
}

export default GroupReportHeader;
