import { useMutation } from "@tanstack/react-query";
import { UsersApiFactory } from "../../../api/api-types";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { t } from "i18next";

export default function useResetPasswordByAdmin() {
  return useMutation({
    mutationFn: (userId: string) => UsersApiFactory().resetPasswordByAdmin(userId).then((res) => res.data),
    onError: (data: AxiosError) => {
      const errorMessage = data.response?.data;
      if ((errorMessage as { message: string }).message) {
        toast.error(
          t(
            (errorMessage as { message: string }).message
              .replace(/\s+/g, "-")
              .toLowerCase()
          )
        );
      } else {
        toast.error(data.message);
      }
    },
  });
}