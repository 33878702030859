import React, { ReactNode } from "react";
import style from "./loginPageContainer.module.scss";
import Logo from "../logo/Logo";
import Button from "../button/Button";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

interface IProps {
  children: ReactNode;
  onButtonClick: () => void;
  buttonText: string;
  isButtonDisabled: boolean;
  isLoading?: boolean;
}

function LoginPageContainer({
  children,
  onButtonClick,
  buttonText,
  isButtonDisabled,
  isLoading
}: IProps) {
  return (
    <div className={style.wrapper}>
      <div className={style.login_container}>
        <div>
          <Logo logoClassName={style.logo} />
        </div>
        <div className={style.login_form_wrapper}>
          <div className={style.container_shadow} />
          {isLoading && <LoadingSpinner />}
          <div className={`${style.children_container} `}>
            <div className={isLoading ? style.hidden : ""}>
              {children}
            </div>
          </div>
        </div>
        <div className={style.button_container}>
          <Button
            name={buttonText}
            style={style.button}
            onClick={onButtonClick}
            disabled={isButtonDisabled || isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPageContainer;
