import React, { useState } from "react";
import style from "./timeComponent.module.scss";
import { useTranslation } from "react-i18next";
import CircleButton from "../../../reusable-components/circle-button/CircleButton";
import { getTimeDuration } from "../../../app/utilities/timeUtilites";
import dayjs from "dayjs";
import SecondaryButton from "../../../reusable-components/secondary-button/SecondaryButton";
import AddTimeComponentTitle from "../add-time-component-title/AddTimeComponentTitle";
import { useSettingsContext } from "../../../app/context/SettingsContext";
import { maxDailyWorkTimeHours } from "../../../constants/workTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Popup from "../../../reusable-components/popup/Popup";
import DatePickerInput from "../../../reusable-components/date-picker-input/DatePickerInput";

interface IProp {
  workDay?: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  setEndTime: (endTime: dayjs.Dayjs) => void;
  isLockedByAdmin?: boolean;
}

function TimeComponent({ workDay, endTime, setEndTime, isLockedByAdmin }: IProp) {
  const [showPopup, setShowPopup] = useState(false);
  const { defaultWorkTimes, timeChangeValue } = useSettingsContext();
  const { t } = useTranslation("time-tracker");

  const subtractFromTime = () => {
    const time = endTime.subtract(timeChangeValue,
      "minutes");
    if (endTime.diff(time) < 0) {
      setEndTime(endTime.startOf("days"));
    } else {
      setEndTime(time);
    }
  };

  function checkMaxDailyWorkTime() {
    return dayjs.duration(endTime.diff(workDay)).asHours() >= maxDailyWorkTimeHours;
  }

  const addToTime = () => {
    if (checkMaxDailyWorkTime()) {
      return;
    }
    setEndTime(endTime.add(timeChangeValue,
      "minutes"));

  };

  const isButtonActive = (hours: number) => {
    return endTime.subtract(hours, "hours").isSame(workDay);
  };

  function handleSetWorkTime(defaultWorkTime: number) {
    setEndTime((workDay ?? dayjs().startOf("day"))
      .add(defaultWorkTime, "hours"));
    setShowPopup(false);
  }

  return (
    <div className={style.container}>
      <AddTimeComponentTitle title={t("work-time")} />
      <div className={style.time_container}>
        <CircleButton disabled={endTime.isSame(endTime.startOf("day")) || isLockedByAdmin}
                      onClick={subtractFromTime}
                      icon={<FontAwesomeIcon icon={faMinus} className={style.icon} />} />
        <p className={`${style.time} ${isLockedByAdmin ? style.locked : ""}`}>{getTimeDuration(
          workDay ?? dayjs().startOf("day"),
          endTime)}</p>
        <CircleButton disabled={checkMaxDailyWorkTime() || isLockedByAdmin}
                      onClick={addToTime}
                      icon={<FontAwesomeIcon icon={faPlus} className={style.icon} />} />
      </div>
      <DatePickerInput setShowPopup={() => !isLockedByAdmin && setShowPopup(true)}
                       text={t("choose-work-time")}
                       icon={<FontAwesomeIcon icon={faClock} />}
                       disabled={isLockedByAdmin} />
      <Popup showPopup={showPopup}
             closePopup={() => setShowPopup(false)}
             containerClassName={style.popup}>
        <div className={style.buttons_container}>
          {defaultWorkTimes.map((defaultWorkTime, index) =>
            <SecondaryButton key={index}
                             active={isButtonActive(defaultWorkTime)}
                             name={`${defaultWorkTime} h`}
                             onClick={() => handleSetWorkTime(defaultWorkTime)} />)}
        </div>
      </Popup>
    </div>
  );
}

export default TimeComponent;
