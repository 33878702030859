const settings = {
  timeTrackerSettings: "timeTracker"
};

const users = {
  user: "user",
  users: "users",
  userAvatar: "user-avatar",
  userProjects: "user-projects",
  unreadMessages: "unread-messages",
  login: "submit-login",
  roles: "roles",
  register: "register",
  paginatedUsers: "paginated-users",
  employeeSettings: "employee-settings"
};

const projects = {
  project: "project",
  projectSummaries: "project-summaries",
  paginatedProjects: "paginated-projects",
  usersAssignedToDefaultProjectInCompany: "users-assigned-to-default-project-in-company"
};

const files = {
  file: "file",
};

const timeTracker = {
  ongoingTimeEntry: "ongoing-time-entry",
  timeEntries: "time-entries",
  timeEntryTags: "time-entry-tags",
  filteredTimeEntriesDescription: "filtered-time-entries-description",
  absences: "absences",
  timeEntryByDay: "time-entry-by-day"
};

const reports = {
  report: "report",
  reportCSVFile: "report-csv",
  timePeriodSummary: "time-period-summary",
};

const tags = {
  tags: "tags",
};

const companies = {
  companies: "companies",
  paginatedCompanies: "paginated-companies",
  projectsAssignedToCompany: "projects-assigned-to-company"
};

export const queryKeys = {
  users,
  projects,
  files,
  timeTracker,
  reports,
  tags,
  settings,
  companies
};
