import { useQuery } from "@tanstack/react-query";
import { TimeTrackerApiFactory } from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";

export const fetchFunction = (
  userId: string,
  startTime: string,
  abortSignal?: AbortSignal
) => {
  return TimeTrackerApiFactory()
    .getTimeEntryByDay(userId, startTime, { signal: abortSignal })
    .then((res) => res.data);
};

export default function useGetTimeEntryByDay(userId: string, startTime: string) {
  const isEnabled = !!userId && !!startTime;
  return useQuery({
    queryKey: [queryKeys.timeTracker.timeEntryByDay, userId, startTime],
    queryFn: ({ signal }) => fetchFunction(userId, startTime, signal),
    enabled: isEnabled,
    refetchOnWindowFocus: false,
  });
}
