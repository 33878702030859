import React, { useContext, useState } from "react";
import LoginPageContainer from "../../reusable-components/login-page-container/LoginPageContainer";
import { useTranslation } from "react-i18next";
import style from "./consents.module.scss";
import { UserContext } from "../../app/context/UserContext";
import RadioButton from "../../reusable-components/radio-button/RadioButton";
import i18n from "../../i18n";
import Checkbox from "../../reusable-components/checkbox/Checkbox";
import languages from "../../constants/languages";

function Consents() {
  const [consent, setConsent] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const { t } = useTranslation("login");
  const { user, setConsent: postConsent, changeLanguage } = useContext(UserContext);

  return (
    <LoginPageContainer onButtonClick={() => postConsent(consent)}
                        buttonText={t("next")}
                        isButtonDisabled={!consent}>
      <div className={style.container}>
        <div className={style.title}>
          {t("language-and-consents")}
          <div className={style.line}></div>
        </div>
        <div className={style.section_container}>
          <div className={style.title}>
            <p>{t("choose-language")}</p>
            <div className={style.dot} />
            <div className={style.line} />
          </div>
          <div className={style.radio_buttons}>
            <RadioButton onClick={(text) => {
              setLanguage(text);
              changeLanguage(text);
            }}
                         marked={language === languages.pl}
                         locale={"pl"}
                         text={t("polish")} />
            <RadioButton onClick={(text) => {
              setLanguage(text);
              changeLanguage(text);
            }}
                         marked={language === languages.uk}
                         locale={"uk"}
                         text={t("ukrainianUk")} />
          </div>
        </div>
        <div className={style.section_container}>
          <div className={style.title}>
            <p>{t("give-consent")}</p>
            <div className={style.dot} />
            <div className={style.line} />
          </div>
          <div className={style.consent_container}>
            <Checkbox
              style={style.checkbox_container}
              handleChecked={setConsent}
            />
            <p className={style.consent}>{t("consent-content", { company: user.company })}{" "}
              <a href={"https://worklife.pl/wp-content/uploads/2022/06/Polityka-Cookies.pdf"}
                 target={"_blank"} rel={"noreferrer"}>{t(
                "more-information-on-data-protection")}</a></p>
          </div>
        </div>
      </div>
    </LoginPageContainer>
  );
}

export default Consents;
