import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Roles } from "../../models/Roles";
import { queryKeys } from "../../../constants/queryKeys";

export function useRoles() {
  const query = useQuery({
    queryKey: [queryKeys.users.roles],
    queryFn: async () =>
      await axios.get<Roles>("/users/roles").then((res) => res.data),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
  return query;
}

export default useRoles;
