import React from "react";
import style from "./registeredUserData.module.scss";
import { RegisteredUser } from "../../../app/models/User";
import Input from "../../../reusable-components/input/Input";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleInfo, faCopy, faWarning } from "@fortawesome/free-solid-svg-icons";
import ThirdButton from "../../../reusable-components/third-button/ThirdButton";
import { toast } from "react-hot-toast";

interface IProp {
  user: RegisteredUser;
  clearRegisteredUser: () => void;
  closePopup: () => void;
}

function RegisteredUserData({ user, clearRegisteredUser, closePopup }: IProp) {
  const { t } = useTranslation("users");

  async function handleCopyData(data: string) {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(data);
      if (await navigator.clipboard.readText() === data) {
        toast.success(t("data-has-been-copied"));
        return;
      }
    }
    toast.error(t("data-copy-failed"));
  }

  return (
    <>
      <div className={style.container}>
        <p className={style.title}>{t("employee-data")}</p>
        <div className={style.inputs_container}>
          <Input disabled={true} content={user.firstName} label={t("firstName")} />
          <Input disabled={true} content={user.lastName} label={t("lastName")} />
          <div onClick={() => handleCopyData(user.email)}>
            <Input disabled={true}
                   content={user.email}
                   label={t("email")}
                   containerClassName={style.input_email}
            ><FontAwesomeIcon icon={faCopy} /></Input>
          </div>
          <div onClick={() => handleCopyData(user.password)}>
            <Input disabled={true}
                   content={user.password}
                   label={t("password")}
                   containerClassName={style.input_password}>
              <FontAwesomeIcon icon={faCopy} /></Input>
          </div>
        </div>
        <div className={style.warnings}>
          <div className={style.warning_container}>
            <FontAwesomeIcon icon={faWarning} className={style.warning_icon} />
            <p className={style.warning}><strong>{t("attention")}</strong> {t("warning-message")}
            </p>
          </div>

          {user.hasEmailSent === true && <div className={style.info_container}>
            <FontAwesomeIcon icon={faCircleCheck}
                             className={style.warning_icon} />
            <p className={style.warning}>{t("email-was-sent")}</p>
          </div>}
          {user.hasEmailSent === false && <div className={style.error_container}>
            <FontAwesomeIcon icon={faCircleInfo}
                             className={style.warning_icon} />
            <p className={style.warning}>{t("email-sending-error")}</p>
          </div>}
        </div>
      </div>
      <div className={style.buttons_container}>
        <ThirdButton style={style.button}
                     name={t("edit-data")}
                     onClick={clearRegisteredUser} />
        <ThirdButton name={t("close")} onClick={closePopup} />
      </div>
    </>
  );
}

export default RegisteredUserData;
