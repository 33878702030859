import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateTimeEntryCommand,
  TimeTrackerApiFactory,
} from "../../../api/api-types";
import { queryKeys } from "../../../../constants/queryKeys";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function useCreateTimeEntry() {
  const { t } = useTranslation("time-tracker");
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (createTimeEntryData: CreateTimeEntryCommand) =>
      TimeTrackerApiFactory()
        .createTimeEntry(createTimeEntryData)
        .then((res) => res.data),
    onSuccess: (data, variables) => {
      if (variables.endTime) {
        toast.success(t("created-time-entry"));
      } else {
        toast.success(t("started-tracking-time"));
      }
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.reports.timePeriodSummary, variables.userId],
      });
    },
    onError: (data: AxiosError) => {
      const errorMessage = data.response?.data;
      if ((errorMessage as { message: string }).message) {
        toast.error(
          t(
            (errorMessage as { message: string }).message
              .replace(/\s+/g, "-")
              .toLowerCase()
          )
        );
      } else {
        toast.error(data.message);
      }
    },
  });
}
