import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Input from "../input/Input";
import { TimeDuration } from "../../app/models/TimeTracker";
import { usePrevious } from "react-use";

interface IProps {
  startTime?: dayjs.Dayjs;
  timeDuration?: string;
  updateTime?: (time: dayjs.Dayjs) => string | void;
  updateTimeDuration?: (time: TimeDuration) => string;
  containerClassName?: string;
  textClassName?: string;
  editMode?: boolean;
  preventTab?: boolean;
  focusOnInput?: boolean;
  borderless?: boolean;
  isHovering?: boolean;
  inputClassName?: string;
}

function TimeInput({
  startTime,
  timeDuration,
  updateTime,
  updateTimeDuration,
  inputClassName,
  containerClassName,
  textClassName,
  editMode,
  preventTab,
  focusOnInput,
  borderless,
  isHovering,
}: IProps) {
  const [formattedTime, setFormattedTime] = useState(startTime ? startTime?.format("HH:mm") : "");
  const refDate = useRef<dayjs.Dayjs>(dayjs(startTime).startOf("D"));
  const date = refDate.current;
  const previousStartTime = usePrevious(startTime);
  const previousDuration = usePrevious(timeDuration);

  useEffect(() => {
    if (timeDuration && timeDuration != previousDuration) {
      setFormattedTime(timeDuration);
    }
    if (startTime && startTime.format() !== previousStartTime?.format() && !timeDuration) {
      setFormattedTime(startTime?.format("HH:mm"));
      refDate.current = dayjs(startTime).startOf("D");
    }
  }, [startTime, timeDuration]);

  useEffect(() => {
    if (focusOnInput) {
      setFormattedTime(prev => prev?.replaceAll(":", ""));
    }
  }, []);

  const changeTime = () => {
    let inputValue = formattedTime;
    if (/(\d+:.)*(\d+)$/.test(inputValue)) {
      if (timeDuration) {
        updateFromDuration(inputValue);
        return;
      }
      if (/:/.test(inputValue) || /./.test(inputValue)) {
        inputValue = inputValue.replace(":", "");
        inputValue = inputValue.replace(".", "");
      }
      if (inputValue.length > 5) {
        setFormattedTime(startTime?.format("HH:mm") || dayjs().format("HH:mm"));
        return;
      } else {
        if (/:/.test(inputValue)) {
          inputValue = inputValue.replace(":", "");
        }
        const timeToUpdate = parseToTimeString(inputValue);
        if (timeToUpdate && updateTime) {
          const time = updateTime(timeToUpdate);
          time && setFormattedTime(time);
        }
      }
    }
  };

  const updateFromDuration = (inputValue: string) => {
    if (inputValue.match(/:/g)?.length === 2) {
      const time = {
        seconds: parseInt(inputValue.slice(inputValue.lastIndexOf(":") + 1)),
        minutes: parseInt(inputValue.slice(inputValue.indexOf(":") + 1,
          inputValue.lastIndexOf(":"))),
        hours: parseInt(inputValue.slice(0, inputValue.indexOf(":"))),
      };
      updateTimeDuration && updateTimeDuration(time);
      return;
    }
    inputValue = inputValue.replace(":", "");
    inputValue = inputValue.replace(".", "");
    const timeToUpdate = parseDurationToTimeString(inputValue);
    if (timeToUpdate && updateTimeDuration) {
      const timeDuration = updateTimeDuration(timeToUpdate);
      setFormattedTime(timeDuration);
    } else {
      timeDuration && setFormattedTime(timeDuration);
    }
  };

  const parseDurationToTimeString = (timeDuration: string) => {
    switch (true) {
      case timeDuration.length < 3: {
        const hours = parseInt(timeDuration);
        return { hours: hours };
      }
      case timeDuration.length < 6: {
        const minutes = parseInt(timeDuration.slice(-2));
        const hours = parseInt(timeDuration.slice(0, -2));
        return { hours: hours, minutes: minutes };
      }
      default:
        return undefined;
    }
  };
  const parseToTimeString = (time: string) => {
    switch (true) {
      case time.length < 3: {
        const hours = parseInt(time);
        if (hours > 23) {
          const hours = parseInt(time.slice(0, 1));
          const minutes = parseInt(`${time.slice(-1)}0`);
          return getTime({ hours: hours, minutes: minutes });
        }
        return getTime({ hours: hours });
      }
      case time.length === 3: {
        const hours = parseInt(time.slice(0, 1));
        const minutes = parseInt(time.slice(-2));
        return getTime({ hours: hours, minutes: minutes });
      }
      case time.length === 4: {
        if (parseInt(time) > 2400) {
          return;
        }
        const hours = parseInt(time.slice(0, 2));
        const minutes = parseInt(time.slice(-2));
        return getTime({ hours: hours, minutes: minutes });
      }
      default:
        return undefined;
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter":
        e.stopPropagation();
        changeTime();
        break;
      case "Tab":
        if (preventTab) {
          e.stopPropagation();
          e.preventDefault();
        }
        break;
      default:
        return;
    }
  };

  const getTime = ({ hours = 0, minutes = 0 }) => {
    return date.add(hours, "hours").add(minutes, "minutes");
  };

  return (
    <Input
      content={formattedTime}
      containerClassName={containerClassName}
      textClassName={textClassName}
      editMode={editMode}
      inputClassName={inputClassName}
      onChange={(e) => setFormattedTime(e.target.value)}
      onFocus={async (e) => {
        await setFormattedTime(prev => prev?.replaceAll(":", ""));
        e.target.select();
      }}
      onBlur={changeTime}
      onKeyDown={handleKeyPress}
      borderless={borderless}
      isHovering={isHovering}
      focusOnInput={focusOnInput} />
  );
}

export default TimeInput;
