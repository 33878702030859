/* tslint:disable */
/* eslint-disable */
/**
 * TimeTracker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kontakt@worklifepolska.pl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbsenceDto
 */
export interface AbsenceDto {
    /**
     * 
     * @type {number}
     * @memberof AbsenceDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceDto
     */
    'absenceType': string;
}
/**
 * 
 * @export
 * @interface AdminDto
 */
export interface AdminDto {
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AssignUsersToProjectCommand
 */
export interface AssignUsersToProjectCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignUsersToProjectCommand
     */
    'userIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignUsersToProjectCommand
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof AssignUsersToProjectCommand
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface ChangeUserStateCommand
 */
export interface ChangeUserStateCommand {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserStateCommand
     */
    'userId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeUserStateCommand
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface CheckCompanyExistQuery
 */
export interface CheckCompanyExistQuery {
    /**
     * 
     * @type {string}
     * @memberof CheckCompanyExistQuery
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckCompanyExistQuery
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CheckCompanyExistQuery
     */
    'shortName': string;
}
/**
 * 
 * @export
 * @interface CheckProjectExistQuery
 */
export interface CheckProjectExistQuery {
    /**
     * 
     * @type {string}
     * @memberof CheckProjectExistQuery
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckProjectExistQuery
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CheckProjectExistQuery
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface CheckResetPasswordTokenValidationCommand
 */
export interface CheckResetPasswordTokenValidationCommand {
    /**
     * 
     * @type {string}
     * @memberof CheckResetPasswordTokenValidationCommand
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CheckResetPasswordTokenValidationCommand
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CheckUserExistQuery
 */
export interface CheckUserExistQuery {
    /**
     * 
     * @type {string}
     * @memberof CheckUserExistQuery
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CheckUserExistQuery
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckUserExistQuery
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckUserExistQuery
     */
    'passport'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDetailsDto
 */
export interface CompanyDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyDetailsDto
     */
    'projectsCount': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyDetailsDto
     */
    'usersCount': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    'shortName'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'shortName': string;
}
/**
 * 
 * @export
 * @interface CreateCompanyCommand
 */
export interface CreateCompanyCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyCommand
     */
    'shortName': string;
}
/**
 * 
 * @export
 * @interface CreateProjectCommand
 */
export interface CreateProjectCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectCommand
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectCommand
     */
    'companyId': string;
}
/**
 * 
 * @export
 * @interface CreateTimeEntryCommand
 */
export interface CreateTimeEntryCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateTimeEntryCommand
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTimeEntryCommand
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTimeEntryCommand
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTimeEntryCommand
     */
    'absenceId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTimeEntryCommand
     */
    'isLockedByAdmin'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CreateUserByAdminCommand
 */
export interface CreateUserByAdminCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminCommand
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminCommand
     */
    'projectTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminCommand
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminCommand
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserByAdminCommand
     */
    'passport'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUsersFromBatchFileRequest
 */
export interface CreateUsersFromBatchFileRequest {
    /**
     * 
     * @type {File}
     * @memberof CreateUsersFromBatchFileRequest
     */
    'file'?: File;
}
/**
 * 
 * @export
 * @interface DayDetails
 */
export interface DayDetails {
    /**
     * 
     * @type {string}
     * @memberof DayDetails
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof DayDetails
     */
    'trackedTime': number;
    /**
     * 
     * @type {string}
     * @memberof DayDetails
     */
    'absence'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DayDetails
     */
    'modifiedByAdminId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DayDetails
     */
    'isLockedByAdmin'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GenerateResetPasswordTokenCommand
 */
export interface GenerateResetPasswordTokenCommand {
    /**
     * 
     * @type {string}
     * @memberof GenerateResetPasswordTokenCommand
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface GetPaginatedCompaniesQuery
 */
export interface GetPaginatedCompaniesQuery {
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedCompaniesQuery
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedCompaniesQuery
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedCompaniesQuery
     */
    'searchValue'?: string | null;
}
/**
 * 
 * @export
 * @interface GetPaginatedProjectsQuery
 */
export interface GetPaginatedProjectsQuery {
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedProjectsQuery
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedProjectsQuery
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedProjectsQuery
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedProjectsQuery
     */
    'searchValue'?: string | null;
}
/**
 * 
 * @export
 * @interface GetPaginatedUsersQuery
 */
export interface GetPaginatedUsersQuery {
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedUsersQuery
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedUsersQuery
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedUsersQuery
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedUsersQuery
     */
    'projectId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaginatedUsersQuery
     */
    'status'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaginatedUsersQuery
     */
    'searchValue'?: string | null;
}
/**
 * 
 * @export
 * @interface GetReportCSVFileQuery
 */
export interface GetReportCSVFileQuery {
    /**
     * 
     * @type {string}
     * @memberof GetReportCSVFileQuery
     */
    'searchQuery'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetReportCSVFileQuery
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetReportCSVFileQuery
     */
    'projectId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetReportCSVFileQuery
     */
    'status'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetReportCSVFileQuery
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof GetReportCSVFileQuery
     */
    'endTime': string;
}
/**
 * 
 * @export
 * @interface GetReportQuery
 */
export interface GetReportQuery {
    /**
     * 
     * @type {string}
     * @memberof GetReportQuery
     */
    'searchQuery'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetReportQuery
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetReportQuery
     */
    'projectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetReportQuery
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof GetReportQuery
     */
    'endTime': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetReportQuery
     */
    'status'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetReportQuery
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof GetReportQuery
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface GetTimeEntrySummariesQuery
 */
export interface GetTimeEntrySummariesQuery {
    /**
     * 
     * @type {string}
     * @memberof GetTimeEntrySummariesQuery
     */
    'userId': string;
    /**
     * 
     * @type {number}
     * @memberof GetTimeEntrySummariesQuery
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof GetTimeEntrySummariesQuery
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface GetTimePeriodSummaryQuery
 */
export interface GetTimePeriodSummaryQuery {
    /**
     * 
     * @type {string}
     * @memberof GetTimePeriodSummaryQuery
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetTimePeriodSummaryQuery
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof GetTimePeriodSummaryQuery
     */
    'endTime': string;
}
/**
 * 
 * @export
 * @interface GetUserSettingsQuery
 */
export interface GetUserSettingsQuery {
    /**
     * 
     * @type {string}
     * @memberof GetUserSettingsQuery
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface GetUsersAssignedToDefaultProjectInCompanyQuery
 */
export interface GetUsersAssignedToDefaultProjectInCompanyQuery {
    /**
     * 
     * @type {string}
     * @memberof GetUsersAssignedToDefaultProjectInCompanyQuery
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof GetUsersAssignedToDefaultProjectInCompanyQuery
     */
    'searchValue'?: string | null;
}
/**
 * 
 * @export
 * @interface GrantGdprConsentCommand
 */
export interface GrantGdprConsentCommand {
    /**
     * 
     * @type {string}
     * @memberof GrantGdprConsentCommand
     */
    'userId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GrantGdprConsentCommand
     */
    'isGranted': boolean;
}
/**
 * 
 * @export
 * @interface IdentityError
 */
export interface IdentityError {
    /**
     * 
     * @type {string}
     * @memberof IdentityError
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityError
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityResult
 */
export interface IdentityResult {
    /**
     * 
     * @type {boolean}
     * @memberof IdentityResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<IdentityError>}
     * @memberof IdentityResult
     */
    'errors'?: Array<IdentityError> | null;
}
/**
 * 
 * @export
 * @interface PaginatedCompaniesDto
 */
export interface PaginatedCompaniesDto {
    /**
     * 
     * @type {Array<CompanyDetailsDto>}
     * @memberof PaginatedCompaniesDto
     */
    'companies': Array<CompanyDetailsDto>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedCompaniesDto
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedCompaniesDto
     */
    'totalItemsCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedCompaniesDto
     */
    'itemsFrom': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedCompaniesDto
     */
    'itemsTo': number;
}
/**
 * 
 * @export
 * @interface PaginatedProjectsDto
 */
export interface PaginatedProjectsDto {
    /**
     * 
     * @type {Array<ProjectDetailsDto>}
     * @memberof PaginatedProjectsDto
     */
    'projects': Array<ProjectDetailsDto>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectsDto
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectsDto
     */
    'totalItemsCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectsDto
     */
    'itemsFrom': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectsDto
     */
    'itemsTo': number;
}
/**
 * 
 * @export
 * @interface PaginatedReportByEmployeeDto
 */
export interface PaginatedReportByEmployeeDto {
    /**
     * 
     * @type {Array<ReportByEmployeeDto>}
     * @memberof PaginatedReportByEmployeeDto
     */
    'reportByEmployee': Array<ReportByEmployeeDto>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedReportByEmployeeDto
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedReportByEmployeeDto
     */
    'totalItemsCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedReportByEmployeeDto
     */
    'itemsFrom': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedReportByEmployeeDto
     */
    'itemsTo': number;
}
/**
 * 
 * @export
 * @interface PaginatedTimeEntriesDto
 */
export interface PaginatedTimeEntriesDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedTimeEntriesDto
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedTimeEntriesDto
     */
    'totalItemsCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedTimeEntriesDto
     */
    'itemsFrom': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedTimeEntriesDto
     */
    'itemsTo': number;
    /**
     * 
     * @type {Array<TimeEntrySummariesByWeekDto>}
     * @memberof PaginatedTimeEntriesDto
     */
    'timeEntriesByWeek': Array<TimeEntrySummariesByWeekDto>;
}
/**
 * 
 * @export
 * @interface PaginatedUsersDto
 */
export interface PaginatedUsersDto {
    /**
     * 
     * @type {Array<UserDetailsDto>}
     * @memberof PaginatedUsersDto
     */
    'users': Array<UserDetailsDto>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedUsersDto
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedUsersDto
     */
    'totalItemsCount': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedUsersDto
     */
    'itemsFrom': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedUsersDto
     */
    'itemsTo': number;
}
/**
 * 
 * @export
 * @interface PatchTimeEntryCommand
 */
export interface PatchTimeEntryCommand {
    /**
     * 
     * @type {string}
     * @memberof PatchTimeEntryCommand
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof PatchTimeEntryCommand
     */
    'timeEntryId': string;
    /**
     * 
     * @type {string}
     * @memberof PatchTimeEntryCommand
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchTimeEntryCommand
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchTimeEntryCommand
     */
    'projectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchTimeEntryCommand
     */
    'workTaskId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchTimeEntryCommand
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchTimeEntryCommand
     */
    'isLockedByAdmin'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ProjectDetailsDto
 */
export interface ProjectDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'companyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDetailsDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailsDto
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailsDto
     */
    'numberOfRelatedUsers'?: number;
}
/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'numberOfRelatedUsers'?: number;
}
/**
 * 
 * @export
 * @interface ReportByEmployeeDto
 */
export interface ReportByEmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof ReportByEmployeeDto
     */
    'employeeId': string;
    /**
     * 
     * @type {string}
     * @memberof ReportByEmployeeDto
     */
    'employeeName': string;
    /**
     * 
     * @type {string}
     * @memberof ReportByEmployeeDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportByEmployeeDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {Array<DayDetails>}
     * @memberof ReportByEmployeeDto
     */
    'dayDetails'?: Array<DayDetails> | null;
    /**
     * 
     * @type {number}
     * @memberof ReportByEmployeeDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ResetPasswordCommand
 */
export interface ResetPasswordCommand {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordCommand
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordCommand
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordCommand
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordCommand
     */
    'repeatPassword': string;
}
/**
 * 
 * @export
 * @interface SetLanguageCommand
 */
export interface SetLanguageCommand {
    /**
     * 
     * @type {string}
     * @memberof SetLanguageCommand
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof SetLanguageCommand
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface SetSettingCommand
 */
export interface SetSettingCommand {
    /**
     * 
     * @type {string}
     * @memberof SetSettingCommand
     */
    'settingTitleKey': string;
    /**
     * 
     * @type {string}
     * @memberof SetSettingCommand
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface SetTimeEntryLockTimeCommand
 */
export interface SetTimeEntryLockTimeCommand {
    /**
     * 
     * @type {number}
     * @memberof SetTimeEntryLockTimeCommand
     */
    'timeEntryLockTimeDays': number;
}
/**
 * 
 * @export
 * @interface SettingsByScopeDto
 */
export interface SettingsByScopeDto {
    /**
     * 
     * @type {TimeTrackerSettingsDto}
     * @memberof SettingsByScopeDto
     */
    'timeTrackerSettings'?: TimeTrackerSettingsDto;
}
/**
 * 
 * @export
 * @interface TimeEntriesGroupedByDay
 */
export interface TimeEntriesGroupedByDay {
    /**
     * 
     * @type {string}
     * @memberof TimeEntriesGroupedByDay
     */
    'day': string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntriesGroupedByDay
     */
    'rawTime': number;
    /**
     * 
     * @type {Array<TimeEntrySummaryDto>}
     * @memberof TimeEntriesGroupedByDay
     */
    'timeEntries': Array<TimeEntrySummaryDto>;
}
/**
 * 
 * @export
 * @interface TimeEntryChangeValueDto
 */
export interface TimeEntryChangeValueDto {
    /**
     * 
     * @type {string}
     * @memberof TimeEntryChangeValueDto
     */
    'scope'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeEntryChangeValueDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TimeEntryChangeValueDto
     */
    'timeChangeValue'?: number;
}
/**
 * 
 * @export
 * @interface TimeEntryDefaultWorkTimeDto
 */
export interface TimeEntryDefaultWorkTimeDto {
    /**
     * 
     * @type {string}
     * @memberof TimeEntryDefaultWorkTimeDto
     */
    'scope': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntryDefaultWorkTimeDto
     */
    'title': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TimeEntryDefaultWorkTimeDto
     */
    'defaultWorkTimes': Array<number>;
}
/**
 * 
 * @export
 * @interface TimeEntryDto
 */
export interface TimeEntryDto {
    /**
     * 
     * @type {string}
     * @memberof TimeEntryDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntryDto
     */
    'absenceId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TimeEntryDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntryDto
     */
    'endTime': string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeEntryDto
     */
    'isLockedByAdmin': boolean;
    /**
     * 
     * @type {AdminDto}
     * @memberof TimeEntryDto
     */
    'modifyingPerson'?: AdminDto;
}
/**
 * 
 * @export
 * @interface TimeEntryLockTimeDto
 */
export interface TimeEntryLockTimeDto {
    /**
     * 
     * @type {string}
     * @memberof TimeEntryLockTimeDto
     */
    'scope': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntryLockTimeDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntryLockTimeDto
     */
    'timeEntryLockTime'?: number;
}
/**
 * 
 * @export
 * @interface TimeEntrySummariesByWeekDto
 */
export interface TimeEntrySummariesByWeekDto {
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummariesByWeekDto
     */
    'weekStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummariesByWeekDto
     */
    'weekEndDate': string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntrySummariesByWeekDto
     */
    'rawTime': number;
    /**
     * 
     * @type {Array<TimeEntriesGroupedByDay>}
     * @memberof TimeEntrySummariesByWeekDto
     */
    'timeEntryGroupedByDay': Array<TimeEntriesGroupedByDay>;
}
/**
 * 
 * @export
 * @interface TimeEntrySummaryDto
 */
export interface TimeEntrySummaryDto {
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummaryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummaryDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummaryDto
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummaryDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummaryDto
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof TimeEntrySummaryDto
     */
    'rawTime': number;
    /**
     * 
     * @type {TimeEntrySummaryWorkTask}
     * @memberof TimeEntrySummaryDto
     */
    'workTask'?: TimeEntrySummaryWorkTask;
}
/**
 * 
 * @export
 * @interface TimeEntrySummaryWorkTask
 */
export interface TimeEntrySummaryWorkTask {
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummaryWorkTask
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummaryWorkTask
     */
    'shortTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeEntrySummaryWorkTask
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface TimeTrackerSettingsDto
 */
export interface TimeTrackerSettingsDto {
    /**
     * 
     * @type {TimeEntryLockTimeDto}
     * @memberof TimeTrackerSettingsDto
     */
    'lockTimeEntrySetting'?: TimeEntryLockTimeDto;
    /**
     * 
     * @type {TimeEntryChangeValueDto}
     * @memberof TimeTrackerSettingsDto
     */
    'timeEntryChangeValue'?: TimeEntryChangeValueDto;
    /**
     * 
     * @type {TimeEntryDefaultWorkTimeDto}
     * @memberof TimeTrackerSettingsDto
     */
    'defaultWorkTime'?: TimeEntryDefaultWorkTimeDto;
}
/**
 * 
 * @export
 * @interface UnassignUsersFromProjectCommand
 */
export interface UnassignUsersFromProjectCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof UnassignUsersFromProjectCommand
     */
    'userIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UnassignUsersFromProjectCommand
     */
    'projectId': string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyCommand
 */
export interface UpdateCompanyCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyCommand
     */
    'shortName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectCommand
 */
export interface UpdateProjectCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCommand
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCommand
     */
    'companyId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTimeEntryCommand
 */
export interface UpdateTimeEntryCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateTimeEntryCommand
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTimeEntryCommand
     */
    'timeEntryId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTimeEntryCommand
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTimeEntryCommand
     */
    'endTime': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTimeEntryCommand
     */
    'absenceId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTimeEntryCommand
     */
    'isLockedByAdmin'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateUserCommand
 */
export interface UpdateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'projectTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'passport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    'isActive'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdatedTimeEntryDto
 */
export interface UpdatedTimeEntryDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatedTimeEntryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedTimeEntryDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedTimeEntryDto
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedTimeEntryDto
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatedTimeEntryDto
     */
    'workTaskId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatedTimeEntryDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatedTimeEntryDto
     */
    'absenceId'?: number | null;
}
/**
 * 
 * @export
 * @interface UserDetailsDto
 */
export interface UserDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'projectTitle': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'selectedProjectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsDto
     */
    'passport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsDto
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserPasswordDto
 */
export interface UserPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDto
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDto
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDto
     */
    'passport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordDto
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPasswordDto
     */
    'hasEmailSent'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UserProjectDto
 */
export interface UserProjectDto {
    /**
     * 
     * @type {string}
     * @memberof UserProjectDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectDto
     */
    'title': string;
    /**
     * 
     * @type {Array<UserProjectWorkTask>}
     * @memberof UserProjectDto
     */
    'workTasks': Array<UserProjectWorkTask>;
}
/**
 * 
 * @export
 * @interface UserProjectWorkTask
 */
export interface UserProjectWorkTask {
    /**
     * 
     * @type {string}
     * @memberof UserProjectWorkTask
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserProjectWorkTask
     */
    'shortTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectWorkTask
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface UserSettingsDto
 */
export interface UserSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsDto
     */
    'hasGdprConsent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsDto
     */
    'language'?: string | null;
}

/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} shortName 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfCompanyExists: async (name: string, shortName: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('checkIfCompanyExists', 'name', name)
            // verify required parameter 'shortName' is not null or undefined
            assertParamExists('checkIfCompanyExists', 'shortName', shortName)
            const localVarPath = `/api/v1/companies/check-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (shortName !== undefined) {
                localVarQueryParameter['shortName'] = shortName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCompanyCommand} [createCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (createCompanyCommand?: CreateCompanyCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProjects: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyProjects', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}/projects`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompanies: async (pageNumber: number, pageSize: number, searchValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getPaginatedCompanies', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getPaginatedCompanies', 'pageSize', pageSize)
            const localVarPath = `/api/v1/companies/paginated-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchValue !== undefined) {
                localVarQueryParameter['searchValue'] = searchValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (updateCompanyCommand?: UpdateCompanyCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} shortName 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIfCompanyExists(name: string, shortName: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIfCompanyExists(name, shortName, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCompanyCommand} [createCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(createCompanyCommand?: CreateCompanyCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(createCompanyCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyProjects(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyProjects(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedCompanies(pageNumber: number, pageSize: number, searchValue?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCompaniesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedCompanies(pageNumber, pageSize, searchValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(updateCompanyCommand?: UpdateCompanyCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(updateCompanyCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} shortName 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfCompanyExists(name: string, shortName: string, id?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.checkIfCompanyExists(name, shortName, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCompanyCommand} [createCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(createCompanyCommand?: CreateCompanyCommand, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.createCompany(createCompanyCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies(options?: any): AxiosPromise<Array<CompanyDto>> {
            return localVarFp.getCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyProjects(companyId: string, options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.getCompanyProjects(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedCompanies(pageNumber: number, pageSize: number, searchValue?: string, options?: any): AxiosPromise<PaginatedCompaniesDto> {
            return localVarFp.getPaginatedCompanies(pageNumber, pageSize, searchValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCompanyCommand} [updateCompanyCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(updateCompanyCommand?: UpdateCompanyCommand, options?: any): AxiosPromise<void> {
            return localVarFp.updateCompany(updateCompanyCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - interface
 * @export
 * @interface CompaniesApi
 */
export interface CompaniesApiInterface {
    /**
     * 
     * @param {string} name 
     * @param {string} shortName 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    checkIfCompanyExists(name: string, shortName: string, id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {CreateCompanyCommand} [createCompanyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    createCompany(createCompanyCommand?: CreateCompanyCommand, options?: AxiosRequestConfig): AxiosPromise<CompanyDto>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getCompanies(options?: AxiosRequestConfig): AxiosPromise<Array<CompanyDto>>;

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getCompanyProjects(companyId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ProjectDto>>;

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getPaginatedCompanies(pageNumber: number, pageSize: number, searchValue?: string, options?: AxiosRequestConfig): AxiosPromise<PaginatedCompaniesDto>;

    /**
     * 
     * @param {UpdateCompanyCommand} [updateCompanyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    updateCompany(updateCompanyCommand?: UpdateCompanyCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI implements CompaniesApiInterface {
    /**
     * 
     * @param {string} name 
     * @param {string} shortName 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public checkIfCompanyExists(name: string, shortName: string, id?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).checkIfCompanyExists(name, shortName, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCompanyCommand} [createCompanyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public createCompany(createCompanyCommand?: CreateCompanyCommand, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).createCompany(createCompanyCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompanies(options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompanyProjects(companyId: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompanyProjects(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getPaginatedCompanies(pageNumber: number, pageSize: number, searchValue?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getPaginatedCompanies(pageNumber, pageSize, searchValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCompanyCommand} [updateCompanyCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompany(updateCompanyCommand?: UpdateCompanyCommand, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompany(updateCompanyCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {AssignUsersToProjectCommand} [assignUsersToProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignToProject: async (projectId: string, assignUsersToProjectCommand?: AssignUsersToProjectCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('assignToProject', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{projectId}/assignUsers`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignUsersToProjectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} title 
         * @param {string} companyId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfProjectExists: async (title: string, companyId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            assertParamExists('checkIfProjectExists', 'title', title)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('checkIfProjectExists', 'companyId', companyId)
            const localVarPath = `/api/v1/projects/ckeck-project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProjectCommand} [createProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (createProjectCommand?: CreateProjectCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedUsers: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAssignedUsers', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{projectId}/users`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [companyId] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedProjects: async (pageNumber: number, pageSize: number, companyId?: string, searchValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getPaginatedProjects', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getPaginatedProjects', 'pageSize', pageSize)
            const localVarPath = `/api/v1/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchValue !== undefined) {
                localVarQueryParameter['searchValue'] = searchValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersAssignedToDefaultProjectInCompany: async (companyId: string, searchValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUsersAssignedToDefaultProjectInCompany', 'companyId', companyId)
            const localVarPath = `/api/v1/projects/company/default-project/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchValue !== undefined) {
                localVarQueryParameter['searchValue'] = searchValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UnassignUsersFromProjectCommand} [unassignUsersFromProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignFromProject: async (projectId: string, unassignUsersFromProjectCommand?: UnassignUsersFromProjectCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('unassignFromProject', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{projectId}/unassignUsers`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unassignUsersFromProjectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateProjectCommand} [updateProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: string, updateProjectCommand?: UpdateProjectCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {AssignUsersToProjectCommand} [assignUsersToProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignToProject(projectId: string, assignUsersToProjectCommand?: AssignUsersToProjectCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignToProject(projectId, assignUsersToProjectCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} title 
         * @param {string} companyId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIfProjectExists(title: string, companyId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIfProjectExists(title, companyId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProjectCommand} [createProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(createProjectCommand?: CreateProjectCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(createProjectCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignedUsers(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignedUsers(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [companyId] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedProjects(pageNumber: number, pageSize: number, companyId?: string, searchValue?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProjectsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedProjects(pageNumber, pageSize, companyId, searchValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersAssignedToDefaultProjectInCompany(companyId: string, searchValue?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersAssignedToDefaultProjectInCompany(companyId, searchValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UnassignUsersFromProjectCommand} [unassignUsersFromProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignFromProject(projectId: string, unassignUsersFromProjectCommand?: UnassignUsersFromProjectCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignFromProject(projectId, unassignUsersFromProjectCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateProjectCommand} [updateProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: string, updateProjectCommand?: UpdateProjectCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectId, updateProjectCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId 
         * @param {AssignUsersToProjectCommand} [assignUsersToProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignToProject(projectId: string, assignUsersToProjectCommand?: AssignUsersToProjectCommand, options?: any): AxiosPromise<string> {
            return localVarFp.assignToProject(projectId, assignUsersToProjectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} title 
         * @param {string} companyId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfProjectExists(title: string, companyId: string, id?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.checkIfProjectExists(title, companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProjectCommand} [createProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(createProjectCommand?: CreateProjectCommand, options?: any): AxiosPromise<string> {
            return localVarFp.createProject(createProjectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedUsers(projectId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getAssignedUsers(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [companyId] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedProjects(pageNumber: number, pageSize: number, companyId?: string, searchValue?: string, options?: any): AxiosPromise<PaginatedProjectsDto> {
            return localVarFp.getPaginatedProjects(pageNumber, pageSize, companyId, searchValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId: string, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.getProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersAssignedToDefaultProjectInCompany(companyId: string, searchValue?: string, options?: any): AxiosPromise<Array<UserDetailsDto>> {
            return localVarFp.getUsersAssignedToDefaultProjectInCompany(companyId, searchValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UnassignUsersFromProjectCommand} [unassignUsersFromProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignFromProject(projectId: string, unassignUsersFromProjectCommand?: UnassignUsersFromProjectCommand, options?: any): AxiosPromise<string> {
            return localVarFp.unassignFromProject(projectId, unassignUsersFromProjectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {UpdateProjectCommand} [updateProjectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: string, updateProjectCommand?: UpdateProjectCommand, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.updateProject(projectId, updateProjectCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - interface
 * @export
 * @interface ProjectsApi
 */
export interface ProjectsApiInterface {
    /**
     * 
     * @param {string} projectId 
     * @param {AssignUsersToProjectCommand} [assignUsersToProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    assignToProject(projectId: string, assignUsersToProjectCommand?: AssignUsersToProjectCommand, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} title 
     * @param {string} companyId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    checkIfProjectExists(title: string, companyId: string, id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {CreateProjectCommand} [createProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    createProject(createProjectCommand?: CreateProjectCommand, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    deleteProject(projectId: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    getAssignedUsers(projectId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [companyId] 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    getPaginatedProjects(pageNumber: number, pageSize: number, companyId?: string, searchValue?: string, options?: AxiosRequestConfig): AxiosPromise<PaginatedProjectsDto>;

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    getProject(projectId: string, options?: AxiosRequestConfig): AxiosPromise<ProjectDto>;

    /**
     * 
     * @param {string} companyId 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    getUsersAssignedToDefaultProjectInCompany(companyId: string, searchValue?: string, options?: AxiosRequestConfig): AxiosPromise<Array<UserDetailsDto>>;

    /**
     * 
     * @param {string} projectId 
     * @param {UnassignUsersFromProjectCommand} [unassignUsersFromProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    unassignFromProject(projectId: string, unassignUsersFromProjectCommand?: UnassignUsersFromProjectCommand, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} projectId 
     * @param {UpdateProjectCommand} [updateProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApiInterface
     */
    updateProject(projectId: string, updateProjectCommand?: UpdateProjectCommand, options?: AxiosRequestConfig): AxiosPromise<ProjectDto>;

}

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI implements ProjectsApiInterface {
    /**
     * 
     * @param {string} projectId 
     * @param {AssignUsersToProjectCommand} [assignUsersToProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public assignToProject(projectId: string, assignUsersToProjectCommand?: AssignUsersToProjectCommand, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).assignToProject(projectId, assignUsersToProjectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} title 
     * @param {string} companyId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public checkIfProjectExists(title: string, companyId: string, id?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).checkIfProjectExists(title, companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProjectCommand} [createProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(createProjectCommand?: CreateProjectCommand, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(createProjectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(projectId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getAssignedUsers(projectId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getAssignedUsers(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [companyId] 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getPaginatedProjects(pageNumber: number, pageSize: number, companyId?: string, searchValue?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getPaginatedProjects(pageNumber, pageSize, companyId, searchValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(projectId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUsersAssignedToDefaultProjectInCompany(companyId: string, searchValue?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUsersAssignedToDefaultProjectInCompany(companyId, searchValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {UnassignUsersFromProjectCommand} [unassignUsersFromProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public unassignFromProject(projectId: string, unassignUsersFromProjectCommand?: UnassignUsersFromProjectCommand, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).unassignFromProject(projectId, unassignUsersFromProjectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {UpdateProjectCommand} [updateProjectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(projectId: string, updateProjectCommand?: UpdateProjectCommand, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(projectId, updateProjectCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [searchQuery] 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCSVReport: async (startTime: string, endTime: string, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getCSVReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getCSVReport', 'endTime', endTime)
            const localVarPath = `/api/v1/reports/CSVReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchQuery] 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports: async (startTime: string, endTime: string, pageNumber: number, pageSize: number, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getReports', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getReports', 'endTime', endTime)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getReports', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getReports', 'pageSize', pageSize)
            const localVarPath = `/api/v1/reports/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetTimePeriodSummaryQuery} [getTimePeriodSummaryQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimePeriodSummary: async (getTimePeriodSummaryQuery?: GetTimePeriodSummaryQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reports/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTimePeriodSummaryQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [searchQuery] 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCSVReport(startTime: string, endTime: string, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCSVReport(startTime, endTime, searchQuery, companyId, projectId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchQuery] 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReports(startTime: string, endTime: string, pageNumber: number, pageSize: number, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedReportByEmployeeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReports(startTime, endTime, pageNumber, pageSize, searchQuery, companyId, projectId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetTimePeriodSummaryQuery} [getTimePeriodSummaryQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimePeriodSummary(getTimePeriodSummaryQuery?: GetTimePeriodSummaryQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DayDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimePeriodSummary(getTimePeriodSummaryQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [searchQuery] 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCSVReport(startTime: string, endTime: string, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options?: any): AxiosPromise<File> {
            return localVarFp.getCSVReport(startTime, endTime, searchQuery, companyId, projectId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchQuery] 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReports(startTime: string, endTime: string, pageNumber: number, pageSize: number, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options?: any): AxiosPromise<PaginatedReportByEmployeeDto> {
            return localVarFp.getReports(startTime, endTime, pageNumber, pageSize, searchQuery, companyId, projectId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetTimePeriodSummaryQuery} [getTimePeriodSummaryQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimePeriodSummary(getTimePeriodSummaryQuery?: GetTimePeriodSummaryQuery, options?: any): AxiosPromise<Array<DayDetails>> {
            return localVarFp.getTimePeriodSummary(getTimePeriodSummaryQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - interface
 * @export
 * @interface ReportsApi
 */
export interface ReportsApiInterface {
    /**
     * 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [searchQuery] 
     * @param {string} [companyId] 
     * @param {string} [projectId] 
     * @param {boolean} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    getCSVReport(startTime: string, endTime: string, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [searchQuery] 
     * @param {string} [companyId] 
     * @param {string} [projectId] 
     * @param {boolean} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    getReports(startTime: string, endTime: string, pageNumber: number, pageSize: number, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options?: AxiosRequestConfig): AxiosPromise<PaginatedReportByEmployeeDto>;

    /**
     * 
     * @param {GetTimePeriodSummaryQuery} [getTimePeriodSummaryQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    getTimePeriodSummary(getTimePeriodSummaryQuery?: GetTimePeriodSummaryQuery, options?: AxiosRequestConfig): AxiosPromise<Array<DayDetails>>;

}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI implements ReportsApiInterface {
    /**
     * 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [searchQuery] 
     * @param {string} [companyId] 
     * @param {string} [projectId] 
     * @param {boolean} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getCSVReport(startTime: string, endTime: string, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getCSVReport(startTime, endTime, searchQuery, companyId, projectId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [searchQuery] 
     * @param {string} [companyId] 
     * @param {string} [projectId] 
     * @param {boolean} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReports(startTime: string, endTime: string, pageNumber: number, pageSize: number, searchQuery?: string, companyId?: string, projectId?: string, status?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReports(startTime, endTime, pageNumber, pageSize, searchQuery, companyId, projectId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetTimePeriodSummaryQuery} [getTimePeriodSummaryQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getTimePeriodSummary(getTimePeriodSummaryQuery?: GetTimePeriodSummaryQuery, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getTimePeriodSummary(getTimePeriodSummaryQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsByScope: async (scope: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('getSettingsByScope', 'scope', scope)
            const localVarPath = `/api/v1/settings/scope/{scope}`
                .replace(`{${"scope"}}`, encodeURIComponent(String(scope)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} title 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntrySetting: async (title: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            assertParamExists('getTimeEntrySetting', 'title', title)
            const localVarPath = `/api/v1/settings/{title}`
                .replace(`{${"title"}}`, encodeURIComponent(String(title)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetSettingCommand} [setSettingCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSetting: async (setSettingCommand?: SetSettingCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSettingCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetTimeEntryLockTimeCommand} [setTimeEntryLockTimeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTimeEntryLockTime: async (setTimeEntryLockTimeCommand?: SetTimeEntryLockTimeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/time-entry-lock-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setTimeEntryLockTimeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettingsByScope(scope: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsByScopeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettingsByScope(scope, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} title 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeEntrySetting(title: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeEntryLockTimeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeEntrySetting(title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetSettingCommand} [setSettingCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSetting(setSettingCommand?: SetSettingCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSetting(setSettingCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetTimeEntryLockTimeCommand} [setTimeEntryLockTimeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTimeEntryLockTime(setTimeEntryLockTimeCommand?: SetTimeEntryLockTimeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTimeEntryLockTime(setTimeEntryLockTimeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} scope 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsByScope(scope: string, options?: any): AxiosPromise<SettingsByScopeDto> {
            return localVarFp.getSettingsByScope(scope, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} title 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntrySetting(title: string, options?: any): AxiosPromise<TimeEntryLockTimeDto> {
            return localVarFp.getTimeEntrySetting(title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetSettingCommand} [setSettingCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSetting(setSettingCommand?: SetSettingCommand, options?: any): AxiosPromise<void> {
            return localVarFp.setSetting(setSettingCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetTimeEntryLockTimeCommand} [setTimeEntryLockTimeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTimeEntryLockTime(setTimeEntryLockTimeCommand?: SetTimeEntryLockTimeCommand, options?: any): AxiosPromise<void> {
            return localVarFp.setTimeEntryLockTime(setTimeEntryLockTimeCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - interface
 * @export
 * @interface SettingsApi
 */
export interface SettingsApiInterface {
    /**
     * 
     * @param {string} scope 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    getSettingsByScope(scope: string, options?: AxiosRequestConfig): AxiosPromise<SettingsByScopeDto>;

    /**
     * 
     * @param {string} title 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    getTimeEntrySetting(title: string, options?: AxiosRequestConfig): AxiosPromise<TimeEntryLockTimeDto>;

    /**
     * 
     * @param {SetSettingCommand} [setSettingCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    setSetting(setSettingCommand?: SetSettingCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {SetTimeEntryLockTimeCommand} [setTimeEntryLockTimeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApiInterface
     */
    setTimeEntryLockTime(setTimeEntryLockTimeCommand?: SetTimeEntryLockTimeCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI implements SettingsApiInterface {
    /**
     * 
     * @param {string} scope 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettingsByScope(scope: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSettingsByScope(scope, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} title 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getTimeEntrySetting(title: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getTimeEntrySetting(title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetSettingCommand} [setSettingCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public setSetting(setSettingCommand?: SetSettingCommand, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).setSetting(setSettingCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetTimeEntryLockTimeCommand} [setTimeEntryLockTimeCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public setTimeEntryLockTime(setTimeEntryLockTimeCommand?: SetTimeEntryLockTimeCommand, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).setTimeEntryLockTime(setTimeEntryLockTimeCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupportApi - axios parameter creator
 * @export
 */
export const SupportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsersFromBatchFile: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/support/create-users-from-batch-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportApi - functional programming interface
 * @export
 */
export const SupportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsersFromBatchFile(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsersFromBatchFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportApi - factory interface
 * @export
 */
export const SupportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportApiFp(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsersFromBatchFile(file?: File, options?: any): AxiosPromise<File> {
            return localVarFp.createUsersFromBatchFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportApi - interface
 * @export
 * @interface SupportApi
 */
export interface SupportApiInterface {
    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApiInterface
     */
    createUsersFromBatchFile(file?: File, options?: AxiosRequestConfig): AxiosPromise<File>;

}

/**
 * SupportApi - object-oriented interface
 * @export
 * @class SupportApi
 * @extends {BaseAPI}
 */
export class SupportApi extends BaseAPI implements SupportApiInterface {
    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApi
     */
    public createUsersFromBatchFile(file?: File, options?: AxiosRequestConfig) {
        return SupportApiFp(this.configuration).createUsersFromBatchFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimeTrackerApi - axios parameter creator
 * @export
 */
export const TimeTrackerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTimeEntryCommand} [createTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeEntry: async (createTimeEntryCommand?: CreateTimeEntryCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/time-tracker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTimeEntryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeEntry: async (timeEntryId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeEntryId' is not null or undefined
            assertParamExists('deleteTimeEntry', 'timeEntryId', timeEntryId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTimeEntry', 'userId', userId)
            const localVarPath = `/api/v1/time-tracker/{timeEntryId}/user/{userId}`
                .replace(`{${"timeEntryId"}}`, encodeURIComponent(String(timeEntryId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsences: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/time-tracker/absences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTimeEntriesForCurrentUser: async (userId: string, pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllTimeEntriesForCurrentUser', 'userId', userId)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllTimeEntriesForCurrentUser', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllTimeEntriesForCurrentUser', 'pageSize', pageSize)
            const localVarPath = `/api/v1/time-tracker/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} startTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntryByDay: async (userId: string, startTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTimeEntryByDay', 'userId', userId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTimeEntryByDay', 'startTime', startTime)
            const localVarPath = `/api/v1/time-tracker/timeEntryByDay/{userId}/{startTime}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"startTime"}}`, encodeURIComponent(String(startTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {PatchTimeEntryCommand} [patchTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTimeEntry: async (timeEntryId: string, patchTimeEntryCommand?: PatchTimeEntryCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeEntryId' is not null or undefined
            assertParamExists('patchTimeEntry', 'timeEntryId', timeEntryId)
            const localVarPath = `/api/v1/time-tracker/{timeEntryId}`
                .replace(`{${"timeEntryId"}}`, encodeURIComponent(String(timeEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTimeEntryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {UpdateTimeEntryCommand} [updateTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntry: async (timeEntryId: string, updateTimeEntryCommand?: UpdateTimeEntryCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeEntryId' is not null or undefined
            assertParamExists('updateTimeEntry', 'timeEntryId', timeEntryId)
            const localVarPath = `/api/v1/time-tracker/{timeEntryId}`
                .replace(`{${"timeEntryId"}}`, encodeURIComponent(String(timeEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTimeEntryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeTrackerApi - functional programming interface
 * @export
 */
export const TimeTrackerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeTrackerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTimeEntryCommand} [createTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTimeEntry(createTimeEntryCommand?: CreateTimeEntryCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTimeEntry(createTimeEntryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimeEntry(timeEntryId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimeEntry(timeEntryId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAbsences(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AbsenceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAbsences(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTimeEntriesForCurrentUser(userId: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTimeEntriesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTimeEntriesForCurrentUser(userId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} startTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeEntryByDay(userId: string, startTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeEntryByDay(userId, startTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {PatchTimeEntryCommand} [patchTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTimeEntry(timeEntryId: string, patchTimeEntryCommand?: PatchTimeEntryCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTimeEntry(timeEntryId, patchTimeEntryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {UpdateTimeEntryCommand} [updateTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeEntry(timeEntryId: string, updateTimeEntryCommand?: UpdateTimeEntryCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatedTimeEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeEntry(timeEntryId, updateTimeEntryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimeTrackerApi - factory interface
 * @export
 */
export const TimeTrackerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeTrackerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTimeEntryCommand} [createTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeEntry(createTimeEntryCommand?: CreateTimeEntryCommand, options?: any): AxiosPromise<string> {
            return localVarFp.createTimeEntry(createTimeEntryCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeEntry(timeEntryId: string, userId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteTimeEntry(timeEntryId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbsences(options?: any): AxiosPromise<Array<AbsenceDto>> {
            return localVarFp.getAbsences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTimeEntriesForCurrentUser(userId: string, pageNumber: number, pageSize: number, options?: any): AxiosPromise<PaginatedTimeEntriesDto> {
            return localVarFp.getAllTimeEntriesForCurrentUser(userId, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} startTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeEntryByDay(userId: string, startTime: string, options?: any): AxiosPromise<TimeEntryDto> {
            return localVarFp.getTimeEntryByDay(userId, startTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {PatchTimeEntryCommand} [patchTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTimeEntry(timeEntryId: string, patchTimeEntryCommand?: PatchTimeEntryCommand, options?: any): AxiosPromise<object> {
            return localVarFp.patchTimeEntry(timeEntryId, patchTimeEntryCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timeEntryId 
         * @param {UpdateTimeEntryCommand} [updateTimeEntryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeEntry(timeEntryId: string, updateTimeEntryCommand?: UpdateTimeEntryCommand, options?: any): AxiosPromise<UpdatedTimeEntryDto> {
            return localVarFp.updateTimeEntry(timeEntryId, updateTimeEntryCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimeTrackerApi - interface
 * @export
 * @interface TimeTrackerApi
 */
export interface TimeTrackerApiInterface {
    /**
     * 
     * @param {CreateTimeEntryCommand} [createTimeEntryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApiInterface
     */
    createTimeEntry(createTimeEntryCommand?: CreateTimeEntryCommand, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} timeEntryId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApiInterface
     */
    deleteTimeEntry(timeEntryId: string, userId: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApiInterface
     */
    getAbsences(options?: AxiosRequestConfig): AxiosPromise<Array<AbsenceDto>>;

    /**
     * 
     * @param {string} userId 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApiInterface
     */
    getAllTimeEntriesForCurrentUser(userId: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig): AxiosPromise<PaginatedTimeEntriesDto>;

    /**
     * 
     * @param {string} userId 
     * @param {string} startTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApiInterface
     */
    getTimeEntryByDay(userId: string, startTime: string, options?: AxiosRequestConfig): AxiosPromise<TimeEntryDto>;

    /**
     * 
     * @param {string} timeEntryId 
     * @param {PatchTimeEntryCommand} [patchTimeEntryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApiInterface
     */
    patchTimeEntry(timeEntryId: string, patchTimeEntryCommand?: PatchTimeEntryCommand, options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @param {string} timeEntryId 
     * @param {UpdateTimeEntryCommand} [updateTimeEntryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApiInterface
     */
    updateTimeEntry(timeEntryId: string, updateTimeEntryCommand?: UpdateTimeEntryCommand, options?: AxiosRequestConfig): AxiosPromise<UpdatedTimeEntryDto>;

}

/**
 * TimeTrackerApi - object-oriented interface
 * @export
 * @class TimeTrackerApi
 * @extends {BaseAPI}
 */
export class TimeTrackerApi extends BaseAPI implements TimeTrackerApiInterface {
    /**
     * 
     * @param {CreateTimeEntryCommand} [createTimeEntryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApi
     */
    public createTimeEntry(createTimeEntryCommand?: CreateTimeEntryCommand, options?: AxiosRequestConfig) {
        return TimeTrackerApiFp(this.configuration).createTimeEntry(createTimeEntryCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} timeEntryId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApi
     */
    public deleteTimeEntry(timeEntryId: string, userId: string, options?: AxiosRequestConfig) {
        return TimeTrackerApiFp(this.configuration).deleteTimeEntry(timeEntryId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApi
     */
    public getAbsences(options?: AxiosRequestConfig) {
        return TimeTrackerApiFp(this.configuration).getAbsences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApi
     */
    public getAllTimeEntriesForCurrentUser(userId: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return TimeTrackerApiFp(this.configuration).getAllTimeEntriesForCurrentUser(userId, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} startTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApi
     */
    public getTimeEntryByDay(userId: string, startTime: string, options?: AxiosRequestConfig) {
        return TimeTrackerApiFp(this.configuration).getTimeEntryByDay(userId, startTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} timeEntryId 
     * @param {PatchTimeEntryCommand} [patchTimeEntryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApi
     */
    public patchTimeEntry(timeEntryId: string, patchTimeEntryCommand?: PatchTimeEntryCommand, options?: AxiosRequestConfig) {
        return TimeTrackerApiFp(this.configuration).patchTimeEntry(timeEntryId, patchTimeEntryCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} timeEntryId 
     * @param {UpdateTimeEntryCommand} [updateTimeEntryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeTrackerApi
     */
    public updateTimeEntry(timeEntryId: string, updateTimeEntryCommand?: UpdateTimeEntryCommand, options?: AxiosRequestConfig) {
        return TimeTrackerApiFp(this.configuration).updateTimeEntry(timeEntryId, updateTimeEntryCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {ChangeUserStateCommand} [changeUserStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserState: async (userId: string, changeUserStateCommand?: ChangeUserStateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeUserState', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/status`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserStateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [userId] 
         * @param {string} [socialSecurityNumber] 
         * @param {string} [passport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfUserExists: async (email: string, userId?: string, socialSecurityNumber?: string, passport?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('checkIfUserExists', 'email', email)
            const localVarPath = `/api/v1/users/ckeck-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (socialSecurityNumber !== undefined) {
                localVarQueryParameter['socialSecurityNumber'] = socialSecurityNumber;
            }

            if (passport !== undefined) {
                localVarQueryParameter['passport'] = passport;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {CheckResetPasswordTokenValidationCommand} [checkResetPasswordTokenValidationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTokenValidation: async (userId: string, checkResetPasswordTokenValidationCommand?: CheckResetPasswordTokenValidationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('checkTokenValidation', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/password/validate-token`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkResetPasswordTokenValidationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserByAdminCommand} [createUserByAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByAdmin: async (createUserByAdminCommand?: CreateUserByAdminCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/create-employee-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserByAdminCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateResetPasswordTokenCommand} [generateResetPasswordTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateResetToken: async (generateResetPasswordTokenCommand?: GenerateResetPasswordTokenCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/password/generate-reset-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateResetPasswordTokenCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedUsers: async (pageNumber: number, pageSize: number, companyId?: string, projectId?: string, status?: boolean, searchValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getPaginatedUsers', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getPaginatedUsers', 'pageSize', pageSize)
            const localVarPath = `/api/v1/users/paginated-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (searchValue !== undefined) {
                localVarQueryParameter['searchValue'] = searchValue;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjects: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserProjects', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/projects`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserSettings', 'userId', userId)
            const localVarPath = `/api/v1/users/user-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ResetPasswordCommand} [resetPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (userId: string, resetPasswordCommand?: ResetPasswordCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetPassword', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/password/change`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordByAdmin: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetPasswordByAdmin', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/password/resetByAdmin`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GrantGdprConsentCommand} [grantGdprConsentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGdprConsent: async (grantGdprConsentCommand?: GrantGdprConsentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/gdpr-consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grantGdprConsentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetLanguageCommand} [setLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLanguage: async (setLanguageCommand?: SetLanguageCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLanguageCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, updateUserCommand?: UpdateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {ChangeUserStateCommand} [changeUserStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserState(userId: string, changeUserStateCommand?: ChangeUserStateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserState(userId, changeUserStateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [userId] 
         * @param {string} [socialSecurityNumber] 
         * @param {string} [passport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIfUserExists(email: string, userId?: string, socialSecurityNumber?: string, passport?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIfUserExists(email, userId, socialSecurityNumber, passport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {CheckResetPasswordTokenValidationCommand} [checkResetPasswordTokenValidationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkTokenValidation(userId: string, checkResetPasswordTokenValidationCommand?: CheckResetPasswordTokenValidationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkTokenValidation(userId, checkResetPasswordTokenValidationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserByAdminCommand} [createUserByAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserByAdmin(createUserByAdminCommand?: CreateUserByAdminCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPasswordDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserByAdmin(createUserByAdminCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenerateResetPasswordTokenCommand} [generateResetPasswordTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateResetToken(generateResetPasswordTokenCommand?: GenerateResetPasswordTokenCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateResetToken(generateResetPasswordTokenCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedUsers(pageNumber: number, pageSize: number, companyId?: string, projectId?: string, status?: boolean, searchValue?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUsersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginatedUsers(pageNumber, pageSize, companyId, projectId, status, searchValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProjects(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProjects(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSettings(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSettings(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {ResetPasswordCommand} [resetPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(userId: string, resetPasswordCommand?: ResetPasswordCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(userId, resetPasswordCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordByAdmin(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPasswordDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordByAdmin(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GrantGdprConsentCommand} [grantGdprConsentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGdprConsent(grantGdprConsentCommand?: GrantGdprConsentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGdprConsent(grantGdprConsentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetLanguageCommand} [setLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLanguage(setLanguageCommand?: SetLanguageCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLanguage(setLanguageCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, updateUserCommand?: UpdateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, updateUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {ChangeUserStateCommand} [changeUserStateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserState(userId: string, changeUserStateCommand?: ChangeUserStateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserState(userId, changeUserStateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} [userId] 
         * @param {string} [socialSecurityNumber] 
         * @param {string} [passport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfUserExists(email: string, userId?: string, socialSecurityNumber?: string, passport?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.checkIfUserExists(email, userId, socialSecurityNumber, passport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {CheckResetPasswordTokenValidationCommand} [checkResetPasswordTokenValidationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTokenValidation(userId: string, checkResetPasswordTokenValidationCommand?: CheckResetPasswordTokenValidationCommand, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkTokenValidation(userId, checkResetPasswordTokenValidationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserByAdminCommand} [createUserByAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByAdmin(createUserByAdminCommand?: CreateUserByAdminCommand, options?: any): AxiosPromise<UserPasswordDto> {
            return localVarFp.createUserByAdmin(createUserByAdminCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateResetPasswordTokenCommand} [generateResetPasswordTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateResetToken(generateResetPasswordTokenCommand?: GenerateResetPasswordTokenCommand, options?: any): AxiosPromise<boolean> {
            return localVarFp.generateResetToken(generateResetPasswordTokenCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [companyId] 
         * @param {string} [projectId] 
         * @param {boolean} [status] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedUsers(pageNumber: number, pageSize: number, companyId?: string, projectId?: string, status?: boolean, searchValue?: string, options?: any): AxiosPromise<PaginatedUsersDto> {
            return localVarFp.getPaginatedUsers(pageNumber, pageSize, companyId, projectId, status, searchValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProjects(userId: string, options?: any): AxiosPromise<Array<UserProjectDto>> {
            return localVarFp.getUserProjects(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings(userId: string, options?: any): AxiosPromise<UserSettingsDto> {
            return localVarFp.getUserSettings(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ResetPasswordCommand} [resetPasswordCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(userId: string, resetPasswordCommand?: ResetPasswordCommand, options?: any): AxiosPromise<IdentityResult> {
            return localVarFp.resetPassword(userId, resetPasswordCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordByAdmin(userId: string, options?: any): AxiosPromise<UserPasswordDto> {
            return localVarFp.resetPasswordByAdmin(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GrantGdprConsentCommand} [grantGdprConsentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGdprConsent(grantGdprConsentCommand?: GrantGdprConsentCommand, options?: any): AxiosPromise<boolean> {
            return localVarFp.setGdprConsent(grantGdprConsentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetLanguageCommand} [setLanguageCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLanguage(setLanguageCommand?: SetLanguageCommand, options?: any): AxiosPromise<string> {
            return localVarFp.setLanguage(setLanguageCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, updateUserCommand?: UpdateUserCommand, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(userId, updateUserCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {ChangeUserStateCommand} [changeUserStateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    changeUserState(userId: string, changeUserStateCommand?: ChangeUserStateCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} email 
     * @param {string} [userId] 
     * @param {string} [socialSecurityNumber] 
     * @param {string} [passport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    checkIfUserExists(email: string, userId?: string, socialSecurityNumber?: string, passport?: string, options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {string} userId 
     * @param {CheckResetPasswordTokenValidationCommand} [checkResetPasswordTokenValidationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    checkTokenValidation(userId: string, checkResetPasswordTokenValidationCommand?: CheckResetPasswordTokenValidationCommand, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {CreateUserByAdminCommand} [createUserByAdminCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    createUserByAdmin(createUserByAdminCommand?: CreateUserByAdminCommand, options?: AxiosRequestConfig): AxiosPromise<UserPasswordDto>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    deleteUser(userId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {GenerateResetPasswordTokenCommand} [generateResetPasswordTokenCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    generateResetToken(generateResetPasswordTokenCommand?: GenerateResetPasswordTokenCommand, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [companyId] 
     * @param {string} [projectId] 
     * @param {boolean} [status] 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getPaginatedUsers(pageNumber: number, pageSize: number, companyId?: string, projectId?: string, status?: boolean, searchValue?: string, options?: AxiosRequestConfig): AxiosPromise<PaginatedUsersDto>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getRoles(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: string; }>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUser(userId: string, options?: AxiosRequestConfig): AxiosPromise<UserDto>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUserProjects(userId: string, options?: AxiosRequestConfig): AxiosPromise<Array<UserProjectDto>>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUserSettings(userId: string, options?: AxiosRequestConfig): AxiosPromise<UserSettingsDto>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUsers(options?: AxiosRequestConfig): AxiosPromise<Array<UserDto>>;

    /**
     * 
     * @param {string} userId 
     * @param {ResetPasswordCommand} [resetPasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    resetPassword(userId: string, resetPasswordCommand?: ResetPasswordCommand, options?: AxiosRequestConfig): AxiosPromise<IdentityResult>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    resetPasswordByAdmin(userId: string, options?: AxiosRequestConfig): AxiosPromise<UserPasswordDto>;

    /**
     * 
     * @param {GrantGdprConsentCommand} [grantGdprConsentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    setGdprConsent(grantGdprConsentCommand?: GrantGdprConsentCommand, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {SetLanguageCommand} [setLanguageCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    setLanguage(setLanguageCommand?: SetLanguageCommand, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUserCommand} [updateUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateUser(userId: string, updateUserCommand?: UpdateUserCommand, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {ChangeUserStateCommand} [changeUserStateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeUserState(userId: string, changeUserStateCommand?: ChangeUserStateCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeUserState(userId, changeUserStateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} [userId] 
     * @param {string} [socialSecurityNumber] 
     * @param {string} [passport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public checkIfUserExists(email: string, userId?: string, socialSecurityNumber?: string, passport?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).checkIfUserExists(email, userId, socialSecurityNumber, passport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {CheckResetPasswordTokenValidationCommand} [checkResetPasswordTokenValidationCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public checkTokenValidation(userId: string, checkResetPasswordTokenValidationCommand?: CheckResetPasswordTokenValidationCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).checkTokenValidation(userId, checkResetPasswordTokenValidationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserByAdminCommand} [createUserByAdminCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserByAdmin(createUserByAdminCommand?: CreateUserByAdminCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserByAdmin(createUserByAdminCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateResetPasswordTokenCommand} [generateResetPasswordTokenCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public generateResetToken(generateResetPasswordTokenCommand?: GenerateResetPasswordTokenCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).generateResetToken(generateResetPasswordTokenCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [companyId] 
     * @param {string} [projectId] 
     * @param {boolean} [status] 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPaginatedUsers(pageNumber: number, pageSize: number, companyId?: string, projectId?: string, status?: boolean, searchValue?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPaginatedUsers(pageNumber, pageSize, companyId, projectId, status, searchValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserProjects(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserProjects(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserSettings(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserSettings(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {ResetPasswordCommand} [resetPasswordCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPassword(userId: string, resetPasswordCommand?: ResetPasswordCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetPassword(userId, resetPasswordCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPasswordByAdmin(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetPasswordByAdmin(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GrantGdprConsentCommand} [grantGdprConsentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setGdprConsent(grantGdprConsentCommand?: GrantGdprConsentCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).setGdprConsent(grantGdprConsentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetLanguageCommand} [setLanguageCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setLanguage(setLanguageCommand?: SetLanguageCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).setLanguage(setLanguageCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUserCommand} [updateUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userId: string, updateUserCommand?: UpdateUserCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(userId, updateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


