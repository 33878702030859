import style from "./button.module.scss";
import { ReactNode, useEffect, useRef, useState } from "react";

interface IProps {
  name?: string;
  style?: string;
  onClick?: () => void;
  icon?: ReactNode;
  disabled?: boolean;
  ariaDisabled?: boolean;
}

const Button = (prop: IProps) => {
  const [borderRadius, setBorderRadius] = useState<number>();
  const button = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!button.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() =>
      setBorderRadius(button.current?.offsetHeight));

    setTimeout(() => button.current && resizeObserver.observe(button.current), 0);

    return () => resizeObserver.disconnect();
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (prop.ariaDisabled || !prop.onClick) {
      return;
    }
    e.preventDefault();
    prop.onClick();
  };

  return (
    <button
      ref={button}
      style={{ borderRadius: borderRadius }}
      className={`${style.button} ${prop.style}`}
      onKeyPress={handleKeyPress}
      onClick={
        prop.ariaDisabled
          ? () => {
            return;
          }
          : prop.onClick
      }
      disabled={prop.disabled}
      aria-disabled={prop.ariaDisabled}
    >
      {prop.icon}
      {prop.name}
    </button>

  );
};

export default Button;
